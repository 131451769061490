import React, { ReactNode } from 'react';
import { cx } from '@emotion/css';
import { PublicComponentProps } from './types';

export interface BreadcrumbItemProps extends PublicComponentProps {
  /**
   * Whether or not the breadcrumb is active.
   */
  active?: boolean;

  /**
   * The inner contents of the component.
   */
  children?: ReactNode;
}

export const BreadcrumbItem = ({ active, children, className, ...rest }: BreadcrumbItemProps) => (
  <li className={cx('crc-breadcrumb-item', className, { active })} {...rest}>
    {children}
  </li>
);
