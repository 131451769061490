import React from 'react';
import { Spinner } from '../Spinner';

const defaultNoDataText = 'Loading...';

export interface TableSpinnerProps {
  loading: boolean;
  loadingText: React.ReactNode;
  entireHeaderHeight: number;
}

export const TableSpinner = ({ loading, loadingText, entireHeaderHeight = 0 }: TableSpinnerProps) => {
  if (!loading) {
    return null;
  }

  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        top: entireHeaderHeight,
        bottom: 0,
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.8)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spinner />
      {loadingText || defaultNoDataText}
    </div>
  );
};
