import React, { ReactNode } from 'react';
import { css, cx } from '@emotion/css';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';
import { useFeatureFlags } from './FeatureFlags';

export interface BreadcrumbsProps extends PublicComponentProps {
  /**
   * The inner contents of the component.
   */
  children?: ReactNode;
}

const breadcrumb = css`
  font: ${cvar('text-xsmall')};
  padding: ${cvar('spacing-8')} ${cvar('spacing-16')};
  list-style: none;
  li {
    display: inline-block;

    button,
    a {
      font: ${cvar('text-xsmall')};
      font-weight: 400;
      vertical-align: middle;
    }

    &.active {
      color: ${cvar('color-text-default')};
      vertical-align: middle;
    }

    & + li:before {
      padding: 0 ${cvar('spacing-4')};
      color: ${cvar('color-icon-secondary')};
      content: '/ ';
      vertical-align: middle;
    }

    &.active:before {
      vertical-align: baseline;
    }
  }
`;

const noOuterSpacingCss = css`
  padding: 0;
`;

export const Breadcrumbs = ({ children, className, ...rest }: BreadcrumbsProps) => {
  const { v17_noOuterSpacing } = useFeatureFlags();

  return (
    <ul className={cx('crc-breadcrumbs', breadcrumb, v17_noOuterSpacing && noOuterSpacingCss, className)} {...rest}>
      {children}
    </ul>
  );
};
