import React from 'react';
import { cx, css } from '@emotion/css';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';
import { useFeatureFlags } from './FeatureFlags';

export interface ProgressBarProps extends PublicComponentProps {
  /**
   * The type determining the validation color to apply to the progress bar.
   */
  status?: 'danger' | 'info' | 'success' | 'warning';

  /**
   * The size of the progress bar.
   */
  size?: 's' | 'm' | 'l' | 'xl';

  /**
   * The color of the progress bar. If type is provided, then color will be ignored.
   */
  color?: string;

  /**
   * The current value of the progress bar. Must be a number from 0 to 100.
   */
  value: number;
}

const sizes: { [key: string]: string } = {
  s: '2px',
  m: '4px',
  l: '6px',
  xl: '8px',
};

const statusColorMap: { [key: string]: string[] } = {
  success: [cvar('color-background-success'), cvar('color-progressbar-gradient-success')],
  info: [cvar('color-background-info'), cvar('color-progressbar-gradient-info')],
  warning: [cvar('color-background-warning'), cvar('dark-color-progressbar-warning')],
  danger: [cvar('color-background-error'), cvar('dark-color-progressbar-error')],
  default: [cvar('color-interactive'), cvar('color-progressbar-gradient-primary')],
  base: [cvar('color-progressbar-background'), cvar('color-background-light')],
};

const progressCss = (size: string) => css`
  box-shadow: none;
  background-image: linear-gradient(to bottom, ${statusColorMap.base[0]} 0, ${statusColorMap.base[1]} 100%);
  background-repeat: repeat-x;
  margin-bottom: ${cvar('spacing-16')};
  overflow: hidden;
  background-color: ${statusColorMap.base[1]};
  border-radius: 0;
  height: ${sizes[size]};
`;

const progressBarCss = (status: string = 'default') => {
  const colors = statusColorMap[status] || statusColorMap.default;
  return css`
    box-shadow: none;
    background-image: linear-gradient(to bottom, ${colors[0]} 0, ${colors[1]} 100%);
    background-repeat: repeat-x;
    float: left;
    height: 100%;
    background-color: ${statusColorMap.default[0]};
    transition: width 0.6s ease;
  `;
};

const noOuterMarginCss = css`
  margin-bottom: 0;
`;

export const ProgressBar = ({ className, size = 's', status, color, value, style, ...rest }: ProgressBarProps) => {
  const { v17_noOuterSpacing } = useFeatureFlags();

  const overrideCss = css`
    ${color ? `background: ${color}` : ''};
    width: ${value}%;
  `;

  return (
    <div
      className={cx('crc-progressbar', progressCss(size), v17_noOuterSpacing && noOuterMarginCss, className)}
      style={style}
      {...rest}
    >
      <div className={cx(progressBarCss(status), overrideCss)} />
    </div>
  );
};
