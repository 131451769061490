import React from 'react';
import { SelectAllInputComponentProps, SelectInputComponentProps } from 'react-table/lib/hoc/selectTable';
import { Checkbox } from '../Checkbox';

export const TableSelect: React.FC<SelectInputComponentProps> = ({ checked, id, row, selectType, onClick }) => {
  const input = (
    <div style={{ textAlign: 'left' }}>
      <Checkbox
        checked={checked || false}
        label=""
        type={selectType}
        onClick={e => {
          const { shiftKey } = e;
          e.stopPropagation();
          onClick(id, shiftKey, row);
        }}
        onChange={() => {}}
      />
    </div>
  );

  return input;
};

export const TableSelectAll: React.FC<SelectAllInputComponentProps> = ({ checked, selectType, onClick }) => {
  const input = (
    <div style={{ textAlign: 'left' }}>
      <Checkbox
        checked={checked || false}
        label=""
        type={selectType}
        onClick={e => {
          e.stopPropagation();
          onClick();
        }}
        onChange={() => {}}
      />
    </div>
  );

  return input;
};
