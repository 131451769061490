import * as React from 'react';
import { SVGProps } from 'react';

export function IndeterminateSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 10" width="1em" height="1em" {...props}>
      <rect x="0" y="4" width="10" height="1.75" rx="0.875" fill="currentColor" />
    </svg>
  );
}
