import React, { ReactNode } from 'react';
import { Droppable as BeautifulDroppable } from 'react-beautiful-dnd';
import { css, cx } from '@emotion/css';
import { FlexBox } from '../FlexBox';
import cvar from '../theme/cvar';

export interface DroppableProps {
  /**
   * Provide content for the Droppable as children.
   */
  children?: ReactNode;

  /**
   * The unique id for the droppable area
   */
  droppableId: string;

  /**
   * Indicates that the droppable does not have any draggable elements inside. If provided, this will render the 'emptyPlaceholder' when the droppable is empty
   */
  isEmpty?: boolean;

  /**
   * The React element rendered when the droppable is empty
   */
  emptyPlaceholder?: ReactNode;
}

const droppableStyle = css`
  border-radius: 3px;
  padding: ${cvar('spacing-8')};
  min-height: 64px;
`;

const draggingStyle = css`
  background-color: ${cvar('color-drag-background-dragging')};
`;

const emptyStyle = css`
  border: ${cvar('spacing-2')} dashed ${cvar('color-border-interactive')};
`;

export const Droppable = ({ children, droppableId, isEmpty = false, emptyPlaceholder }: DroppableProps) => (
  <BeautifulDroppable droppableId={droppableId}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        className={cx(droppableStyle, { [draggingStyle]: snapshot.isDraggingOver, [emptyStyle]: isEmpty })}
      >
        <FlexBox
          style={{ padding: `${cvar('spacing-8')} 0px` }}
          flexFlow="column nowrap"
          alignItems={isEmpty ? 'center' : 'initial'}
        >
          {!isEmpty ? (
            <>
              {children}
              {/* Only render the placeholder when the workspace isn't empty to keep the empty workspace from growing vertically */}
              {provided.placeholder}
            </>
          ) : (
            emptyPlaceholder
          )}
        </FlexBox>
      </div>
    )}
  </BeautifulDroppable>
);
