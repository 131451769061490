import React, { ReactNode } from 'react';
import { css, cx } from '@emotion/css';
import { SVG404 } from './SVG404';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';

export interface FourOhFourProps extends PublicComponentProps {
  /**
   * Text to give a user suggestions for next steps when the get a 404 error.
   */
  message?: ReactNode;
}

export const FourOhFour = ({
  message = 'Oops! Something has gone wrong and the page you were looking for could not be displayed. Try the homepage.',
  className,
  ...rest
}: FourOhFourProps) => (
  <div
    className={cx(
      'crc-fourohfour',
      css`
        text-align: center;
      `,
      className,
    )}
    {...rest}
  >
    <div
      className={css`
        max-width: 550px;
        margin: 0 auto;
      `}
    >
      <SVG404 />
    </div>
    <div
      className={css`
        max-width: 500px;
        margin: 0 auto;
      `}
    >
      <h1
        className={css`
          color: ${cvar('color-404-heading')};
        `}
      >
        404 Page Not Found
      </h1>
      {message}
    </div>
  </div>
);
