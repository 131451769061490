import { McpProduct } from '../clients/productIntroduction';

export const determineProductAttributes = (rawAttributes: McpProduct) => {
  if (rawAttributes.name && rawAttributes.description && rawAttributes.name !== rawAttributes.description) {
    return `${rawAttributes.name}: ${rawAttributes.description}`;
  } else if (rawAttributes.name) {
    return rawAttributes.name;
  }

  return;
};
