import { RoutingRequestResponse } from '../types';

export const isPermissionError = (statusCode: number) => {
  if (statusCode === 401 || statusCode === 403) {
    return true;
  }

  return false;
};

export const getErrorMessage = ({ erroredItem, statusCode }: { erroredItem: string; statusCode: number }) => {
  if (isPermissionError(statusCode)) {
    return `You do not have permission to view the ${erroredItem}. Received status code ${statusCode}.`;
  }

  return `There was an error retrieving the ${erroredItem}. Received status code ${statusCode}.`;
};

export const getLastRetryDate = (routingRequest: RoutingRequestResponse | undefined) => {
  if (!routingRequest || !routingRequest.statusChanges) {
    return undefined;
  }

  const suspendedStatuses = routingRequest.statusChanges.filter((status) => status.status === 'suspended');
  return suspendedStatuses.pop()?.date;
};
