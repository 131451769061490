import React, {
  useState,
  useCallback,
  useEffect,
  ChangeEvent,
  ReactNode,
  createContext,
  useContext,
  useMemo,
} from 'react';
import { css, cx } from '@emotion/css';
import cvar from '../theme/cvar';
import { PublicComponentProps } from '../types';

export interface RadioGroupProps extends PublicComponentProps {
  /** Pass <Radio /> elements as children to the RadioGroup */
  children?: ReactNode;
  /** The default radio group selection. If no default is defined, no radio button will be initially selected. */
  defaultSelected?: any;
  /** If true, radio options will display in a row instead of stacked. If there is not enough width for all options, radios will wrap. */
  inline?: boolean;
  /** Name to apply to the child radio buttons. */
  name: string;
  /** Callback function that is fired when the selected radio button changes. */
  onChange?: (e: ChangeEvent<HTMLInputElement>, value: any) => void;
  /** The current selected value of the radio group. */
  valueSelected?: any;
}

interface RadioGroupContextProps {
  name: string;
  selected: any;
  onChange: (event: ChangeEvent<HTMLInputElement>, value: any) => void;
}

const RadioGroupContext = createContext<RadioGroupContextProps | undefined>(undefined);

export const RadioGroup = (props: RadioGroupProps) => {
  const {
    children,
    className = '',
    style = {},
    defaultSelected,
    inline = false,
    name,
    onChange,
    valueSelected,
    ...rest
  } = props;

  const [selected, setSelected] = useState(valueSelected || defaultSelected || '');

  useEffect(() => {
    if (valueSelected !== undefined) {
      setSelected(valueSelected);
    }
  }, [valueSelected]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, selectedItem: any) => {
      setSelected(selectedItem);
      onChange?.(event, selectedItem);
    },
    [onChange],
  );

  const contextValue = useMemo(() => ({ name, selected, onChange: handleChange }), [name, selected, handleChange]);

  const radioGroup = css`
    display: flex;
    flex-flow: ${inline ? 'row wrap' : 'column nowrap'};
    align-items: ${inline ? 'center' : 'flex-start'};
  `;

  return (
    <RadioGroupContext.Provider value={contextValue}>
      <div className={cx('crc-radio-group', radioGroup, className)} style={style} {...rest}>
        {React.Children.map(children, child => (
          <div style={{ marginRight: cvar('spacing-16') }}>{child}</div>
        ))}
      </div>
    </RadioGroupContext.Provider>
  );
};

export const useRadioGroupContext = () => useContext(RadioGroupContext);
