import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import Tether from 'react-tether';
import debounce from 'lodash.debounce';
import { css, cx } from '@emotion/css';
import cvar from './theme/cvar';
import { Direction } from './common';
import { PublicComponentProps } from './types';

type ContraintsTo = string | 'window' | 'scrollParent' | HTMLElement;

type Constraints = {
  to?: ContraintsTo;
  attachment?: string | 'together';
  outOfBoundsClass?: string;
  pinnedClass?: string;
  pin?: boolean | string[];
};

const attachments = {
  top: 'bottom center',
  bottom: 'top center',
  left: 'middle right',
  right: 'middle left',
};

const targetAttachments = {
  top: 'top center',
  bottom: 'bottom center',
  left: 'middle left',
  right: 'middle right',
};

export interface TooltipProps extends PublicComponentProps {
  /**
   * DOM node for the tooltip to appear anchored to.
   */
  children: ReactNode;

  /**
   * Allows the user to completely override the Tether constraints array for uniquely positioned tooltips. See the Tether.io documenation here.
   */
  constraints?: Constraints[];

  /**
   * Can be used to add class names to the tooltip target container.
   */
  containerClassName?: string;

  /**
   * Contents for the tooltip.
   */
  contents?: ReactNode;

  /**
   * One of top, right, bottom, or left, tells the tooltip which direction to default to.
   */
  direction?: Direction;

  /**
   * Delay (in milliseconds) after which the tooltip will be shown.
   */
  delay?: number;

  /**
   * If provided, a function to be called when a click occurs outside of this component.
   */
  onClickOutside?: () => void;

  /**
   * Can be used to customize when the tooltip displays. If this prop is used, the default mouseover behavior will be disabled.
   */
  show?: boolean;

  /**
   * Can be used to add styles to the tooltip component.
   */
  tooltipStyle?: CSSProperties;

  /**
   * Can be used to add styles to the tooltip component.
   */
  tooltipInnerStyle?: CSSProperties;

  /**
   * Controls the appearance. One of tooltip or popover. (Deprecated. Use `variant` instead.)
   */
  variety?: string;

  variant?: 'tooltip' | 'popover';
}

// The tooltip arrows sit inside their container's padding space, so they must use the same value
const tooltipArrowSize = cvar('spacing-8');
const tooltipColor = cvar('color-tooltip-default');

const tetherStyle = css`
  z-index: 2000;

  pointer-events: none;
  * {
    pointer-events: auto;
  }
`;

const baseTooltipStyle = (direction: Direction) => css`
  display: block;
  ${`margin-${direction}`}: 3px;
  padding: ${direction === 'top' || direction === 'bottom' ? `${tooltipArrowSize} 0` : `0 ${tooltipArrowSize}`};
`;

const tooltipContentStyle = css`
  background-color: ${tooltipColor};
  border-radius: 2px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  color: #fff;
  font: ${cvar('text-label-default')};
  max-width: 200px;
  padding: ${cvar('spacing-12')} ${cvar('spacing-16')};
  text-align: center;
`;

const popoverContentStyle = css`
  background-color: ${cvar('color-background')};
  border-radius: 2px;
  border: 1px solid ${cvar('color-border-default')};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  font: ${cvar('text-paragraph-default')};
  padding: ${cvar('spacing-8')} ${cvar('spacing-16')};
`;

const directedArrowStyle = (direction: Direction, isPopover: boolean) => {
  const opposite = {
    left: 'right',
    right: 'left',
    top: 'bottom',
    bottom: 'top',
  }[direction];

  const centerDirection = direction === 'top' || direction === 'bottom' ? 'left' : 'top';

  const borderColor = isPopover ? cvar('color-border-default') : tooltipColor;

  return css`
    border-width: ${tooltipArrowSize};
    border-color: transparent;
    border-style: solid;
    position: absolute;
    ${opposite}: 1px;
    ${`border-${opposite}-width: 0px;`}
    ${`border-${direction}-color: ${borderColor};`}
    ${centerDirection}: 50%;
    ${`margin-${centerDirection}: calc(-1 * ${tooltipArrowSize})`};

    ${isPopover
      ? `
      &::after {
      content: ' ';
      border: 7px solid transparent;
      position: absolute;
      bottom: 1px;
        ${`border-${opposite}-width: 0px;`}
        ${`border-${direction}-color: ${cvar('color-background')};`}
        ${`margin-${centerDirection}: calc(-1 * calc(${tooltipArrowSize} - 1px))`};
      }`
      : null}
  `;
};

export const Tooltip = ({
  children,
  contents = '',
  direction = 'top',
  style = {},
  className = '',
  containerClassName = '',
  variety,
  variant = 'tooltip',
  tooltipStyle = {},
  tooltipInnerStyle = {},
  show,
  delay = 0,
  constraints = [
    {
      to: 'window',
      attachment: 'together',
      pin: ['left', 'right'],
    },
  ],
  onClickOutside,
  ...rest
}: TooltipProps) => {
  const targetRef = useRef<HTMLDivElement>();
  const elementRef = useRef<HTMLDivElement>();
  const tether = useRef<Tether>(null);

  const [showState, setShowState] = useState(show || false);

  // only used to prevent Tether from rendering on the first render due to perf issues.
  const [showInitialState, setShowInitialState] = useState(show || false);

  const toggleTether = (enable: boolean) => {
    if (tether.current && tether.current.getTetherInstance()) {
      if (enable) {
        tether.current.enable();
        tether.current.position();
      } else {
        tether.current.disable();
      }
    }
  };

  const setShowFlag = (value: boolean) => {
    const newShowState = show !== undefined ? show : value;
    toggleTether(newShowState);
    setShowState(newShowState);
  };

  const debouncedClose = debounce(close, 50);

  const debouncedOpen = debounce(open, delay);

  function open() {
    debouncedClose.cancel();
    setShowFlag(true);
  }

  function close() {
    debouncedOpen.cancel();
    setShowFlag(false);
  }

  useEffect(() => {
    if (show !== undefined) {
      setShowState(show);
    }
  }, [show, setShowState]);

  useEffect(() => {
    if (!showState) {
      toggleTether(false);
    }
  }, [showState, toggleTether]);

  const setShowInitial = () => {
    setShowInitialState(true);
  };

  function attachTarget(ref: React.RefObject<HTMLDivElement>) {
    if (ref.current) {
      targetRef.current = ref.current;
    }

    return ref;
  }

  function attachElement(ref: React.RefObject<HTMLDivElement>) {
    if (ref.current) {
      elementRef.current = ref.current;
    }

    return ref;
  }

  const onClickOutsideRef = useRef(onClickOutside);
  useEffect(() => {
    onClickOutsideRef.current = onClickOutside;
  }, [onClickOutside]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    if (onClickOutsideRef.current && elementRef.current) {
      document.addEventListener(
        'click',
        e => {
          if (
            elementRef.current &&
            !elementRef.current.contains(e.target as Node) &&
            !targetRef.current?.contains(e.target as Node)
          ) {
            onClickOutsideRef.current?.();
          }
        },
        { signal },
      );
    }

    return () => {
      controller.abort();
    };
  }, []);

  const isPopover = (variant || variety) === 'popover';
  const popover = isPopover ? { display: 'block' } : {};

  if (showInitialState || showState) {
    return (
      <Tether
        style={style as React.ComponentProps<typeof Tether>['style']}
        ref={tether}
        classPrefix="tether-tooltip"
        attachment={attachments[direction]}
        targetAttachment={targetAttachments[direction]}
        constraints={constraints}
        className={cx('crc-tooltip-tether', tetherStyle)}
        renderTarget={ref => (
          <div
            ref={attachTarget(ref as React.RefObject<HTMLDivElement>)}
            style={{ display: 'inline-block', ...style }}
            onMouseEnter={debouncedOpen}
            onMouseOver={debouncedOpen}
            onFocus={debouncedOpen}
            onMouseOut={debouncedClose}
            onMouseLeave={debouncedClose}
            onBlur={debouncedClose}
            className={cx(
              isPopover ? 'crc-tooltip__container crc-tooltip__container--popover' : 'crc-tooltip__container',
              containerClassName,
            )}
          >
            {children}
          </div>
        )}
        renderElement={ref => (
          <div
            ref={attachElement(ref as React.RefObject<HTMLDivElement>)}
            className={cx(
              isPopover ? 'crc-tooltip crc-tooltip--popover' : 'crc-tooltip',
              baseTooltipStyle(direction),
              className,
            )}
            role="tooltip"
            style={{
              visibility: showState ? 'visible' : 'hidden',
              ...popover,
              ...tooltipStyle,
            }}
            onMouseEnter={debouncedOpen}
            onMouseOver={debouncedOpen}
            onFocus={debouncedOpen}
            onMouseOut={debouncedClose}
            onMouseLeave={debouncedClose}
            onBlur={debouncedClose}
          >
            <div
              className={cx(
                isPopover ? 'crc-tooltip__arrow crc-tooltip__arrow--popover' : 'crc-tooltip__arrow',
                directedArrowStyle(direction, isPopover),
              )}
            />
            <div
              className={
                isPopover
                  ? cx('crc-tooltip__content crc-tooltip__content--popover', popoverContentStyle)
                  : cx('crc-tooltip__content', tooltipContentStyle)
              }
              style={{ ...tooltipInnerStyle }}
            >
              {contents}
            </div>
          </div>
        )}
      />
    );
  }

  const propsWithoutTooltipSpecificOnes = Object.fromEntries(
    Object.entries(rest).filter(
      k =>
        ![
          'disableOnClickOutside',
          'stopPropagation',
          'enableOnClickOutside',
          'preventDefault',
          'outsideClickIgnoreClass',
          'eventTypes',
          'onClickOutside',
        ].includes(k[0]),
    ),
  );

  return (
    <div
      style={{ display: 'inline-block', ...style }}
      onMouseEnter={setShowInitial}
      onMouseOver={setShowInitial}
      onFocus={debouncedOpen}
      className={containerClassName}
      {...propsWithoutTooltipSpecificOnes}
    >
      {children}
    </div>
  );
};
