import React, { ReactNode } from 'react';
import { cx } from '@emotion/css';
import { useMemoizedId } from '../utils';
import createStyle from './styles';
import { CheckSvg } from '../icons/CheckSvg';
import { IndeterminateSvg } from '../icons/IndeterminateSvg';
import { PublicComponentProps } from '../types';

const checkboxStyle = createStyle({ checkbox: '.focusable-checkbox', label: 'label' });

type InputBaseProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

export interface CheckboxProps<Payload = any> extends InputBaseProps, PublicComponentProps {
  /** Determines whether or not the checkbox is checked. */
  checked?: boolean;
  /** Marks the checkbox as disabled. */
  disabled?: boolean;
  /** ID prop for the input field. */
  id?: string;
  /** Determines whether or not the checkbox is in an indeterminate state. If checked is true, it will override indeterminate. */
  indeterminate?: boolean;
  /** Label for the checkbox. */
  label: ReactNode;
  /** Callback function that is fired when the checked value changes. */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, payload: Payload) => void;
  /** Anything you want passed in to the onChange handler as the second argument. */
  payload?: Payload;
  /** If set to true, the checkbox icon is rendered with white background, otherwise - transparent. */
  whiteBackground?: boolean;
  /** If set to true, checkbox will display in a row instead of being stacked */
  inline?: boolean;
}

export const Checkbox = ({
  id,
  indeterminate,
  onChange,
  className,
  style = {},
  label,
  payload,
  whiteBackground,
  onBlur,
  onFocus,
  inline,
  ...rest
}: CheckboxProps) => {
  const inputId = useMemoizedId({ id });
  const backgroundColor = whiteBackground ? 'white' : undefined;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange && onChange(e, payload);

  return (
    <div
      className={cx('crc-checkbox', checkboxStyle, className)}
      style={{ ...style, backgroundColor, display: inline ? 'inline' : 'block' }}
    >
      <input
        {...rest}
        className={cx('focusable-checkbox', indeterminate && 'focusable-checkbox-indeterminate')}
        onChange={handleChange}
        onBlur={e => onBlur && onBlur(e)}
        onFocus={e => onFocus && onFocus(e)}
        type="checkbox"
        id={inputId}
      />
      <label htmlFor={inputId}>
        <div aria-hidden="true">
          <CheckSvg className="focusable-checkbox__check-icon" />
          <IndeterminateSvg className="focusable-checkbox__indeterminate-icon" />
        </div>
        {label}
      </label>
    </div>
  );
};
