import { buildUrl } from 'build-url-ts';
import { fetchWithAuth } from './fetchWithAuth';

export type GetPreviewParams = {
  accessToken: string;
  documentReferenceUri?: string;
  width?: number;
  height?: number;
  sku?: string;
  skuVariables?: string;
};

export const getPreview = async ({
  accessToken,
  documentReferenceUri,
  width,
  height,
  sku,
  skuVariables,
}: GetPreviewParams) => {
  if (!documentReferenceUri) {
    return { previewUrl: undefined };
  }

  const url = buildUrl('https://previews.cimpress.io', {
    path: 'v2/preview',
    queryParams: {
      documentReferenceUri,
      width,
      height,
      sku,
      skuVariables,
    },
  });

  if (!url) {
    return { previewUrl: undefined };
  }

  const response = await fetchWithAuth({ path: url, accessToken });

  if (response.ok) {
    const url = response.url;
    return { previewUrl: url };
  }

  return { previewUrl: undefined };
};
