import React, { Component } from 'react';
import { shapes } from '@cimpress/react-components';
import PropTypes from 'prop-types';

const { Robot } = shapes;

const ctSupportLink = 'mailto:CtProductCatalogSupport@cimpress.com';

export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    if (window.newrelic) window.newrelic.noticeError(error);

    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <div className="product-load-error">
            <div>
              <div className="text-danger">
                {' '}
                Unexpected error encountered.
                <div>
                  {' '}
                  Please Contact <a href={ctSupportLink}>CT Product Support</a>
                </div>
                <br />
                <details style={{ whiteSpace: 'pre-wrap' }}>
                  {this.state.error && this.state.error.toString()}
                  <br />
                  {this.state.errorInfo.componentStack}
                </details>
              </div>
            </div>
            <Robot bsStyle="danger" size="lg" />
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
