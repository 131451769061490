import { css, cx } from '@emotion/css';
import React, { Children, ReactNode, ReactElement } from 'react';
import { Step, StepProps } from './Step';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';

export interface StepGroupProps extends StepProps, PublicComponentProps {
  /**
   * If provided, this sets a SubStep as the active Step within the Stepper
   */
  activeSubStep?: number;

  /**
   * Additional information about the step to render below the step indicator
   */
  contents?: ReactNode;

  index?: number;

  /**
   * If provided, this sets the top parent-step's color regardless of whether it's completed, active, etc.
   *
   * This prop is not passed to sub-steps. If any sub-steps have an overrideBsStyle set, the most severe style
   * will take precedence over this value.
   */
  overrideStatus?: 'success' | 'info' | 'warning' | 'error';

  children: ReactElement<StepProps>[];
}

// listed from most severe to least
const stylePriority: Array<'success' | 'info' | 'warning' | 'error'> = ['error', 'warning', 'info', 'success'];

// map of style to its priority, 0 is highest priority
const priorityMap = stylePriority.reduce<Record<string, number>>((agg, curr, index) => {
  // eslint-disable-next-line no-param-reassign
  agg[curr] = index;
  return agg;
}, {});

export const StepGroup = (props: StepGroupProps): JSX.Element => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    typeName = 'StepGroup',
    children,
    active,
    partial,
    completed,
    connectorColor,
    index = 0,
    activeSubStep,
    className = '',
    style = {},
    overrideStatus: statusOverride,
    onClick,
    ...rest
  } = props;

  // get all sub-step (Children.map can return null or undefined)
  const overrides: string[] = Children.map(children, child => child.props.overrideStatus || 'success');

  // find the most severe style that is being passed to the sub-steps by sorting based on style priority
  const mostSevereOverride =
    overrides &&
    overrides
      .filter(o => o)
      .map(o => priorityMap[o])
      .sort()
      .map(p => stylePriority[p])[0];
  const overrideStatus = mostSevereOverride || statusOverride;

  let renderedConnectorColor = connectorColor;
  if (completed && overrideStatus) {
    renderedConnectorColor = cvar(`color-background-${overrideStatus}`) || cvar('color-background');
  }

  const stepGroupStyle = css`
    border-left: 2px dotted ${renderedConnectorColor};
    margin: 4px 0 4px 9px;
    padding-left: 9px;
    flex-direction: column;
  `;

  const { activeColor, small, vertical, ...restProps } = rest;

  return (
    <>
      <Step {...props} overrideStatus={overrideStatus}>
        {rest.contents}
      </Step>
      <div className={cx('crc-stepper-group', stepGroupStyle, className)} style={{ ...style }} {...restProps}>
        {Children.map(children, (step, stepIndex) => {
          const controlProps: StepProps = {
            activeColor,
            small: true,
            vertical: true,
            index: `${index}.${stepIndex}`,
          };
          if (active && activeSubStep === stepIndex) {
            controlProps.active = true;
            controlProps.partial = partial;
          } else if (completed || (active && activeSubStep && activeSubStep > stepIndex)) {
            controlProps.completed = true;
          }
          return React.cloneElement(step, { ...controlProps, ...step.props });
        })}
      </div>
    </>
  );
};
