import { useContext } from 'react';
import { RetryContext } from './RetryContext';
import { Alert } from '@cimpress/react-components';

export const RetryAlert = () => {
  const { retryError, setRetryError, retryState, retryRoutingRequest } = useContext(RetryContext);

  const clearRetryError = () => {
    retryRoutingRequest?.reset();
    setRetryError(undefined);
  };

  if (retryError) {
    return (
      <Alert
        dismissible={true}
        message={`There was an error retrying the routing request: ${retryError.message}.`}
        onDismiss={() => clearRetryError()}
      />
    );
  }

  if (retryState === 'DONE') {
    return (
      <Alert
        dismissible={true}
        message="The routing request was retried successfully."
        status="success"
        onDismiss={() => retryRoutingRequest?.reset()}
      />
    );
  }

  return null;
};
