import React, { ReactNode, DetailedHTMLProps, HTMLAttributes } from 'react';
import { css, cx } from '@emotion/css';
import { Tooltip } from './Tooltip';
import { BlockButton } from './internal';
import { Direction } from './common';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';

export interface StepProps extends PublicComponentProps {
  typeName?: string;
  active?: boolean;

  activeColor?: string;

  /**
   * Additional information about the step to render below the step indicator.
   */
  children?: ReactNode;

  completed?: boolean;

  index?: string | number;

  /**
   * Name for the step. Provided to the onClick function when it is called.
   */
  name?: string;

  /**
   * Function to call when a step is clicked on. If no function is provided, steps will not appear clickable.
   */
  onClick?: (index?: string | number, name?: string) => void;

  /**
   * If provided, this sets the step's color regardless of whether it's completed, active, etc.
   */
  overrideStatus?: 'success' | 'warning' | 'error' | 'info';

  partial?: boolean;

  small?: boolean;

  /**
   * If provided, content for a tooltip to appear when a user hovers over the step.
   */
  tip?: ReactNode;

  /**
   * Direction for the tooltip (see tooltip documentation).
   */
  tipDirection?: Direction;

  /**
   * Direction of Stepper Component wrapping this step
   */
  vertical?: boolean;

  /**
   * An element that will replace the step's indicator.
   */
  icon?: ReactNode;

  activeSubStep?: string | number;
  connectorColor?: string;
}

const pseudobold = `0 0 .65px ${cvar('dark-color-code-background')}, 0 0 .65px ${cvar('dark-color-code-background')}`;

export interface StepCircleProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  size: number;
  borderWidth: number;
  color: string;
  filled?: boolean;
}

const StepCircle = ({ size, borderWidth, color, filled = false }: StepCircleProps) => {
  const stepCircleStyle = css`
    flex-shrink: 0;
    width: ${size}px;
    height: ${size}px;
    border: ${borderWidth}px solid ${color};
    border-radius: 100%;
    background-color: ${filled ? color : cvar('color-background')};
  `;

  return <div data-value="step-circle" className={cx(stepCircleStyle)} />;
};

export interface StepHalfCircleProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  size: number;
  color: string;
}

const StepHalfCircle = ({ size, color }: StepHalfCircleProps) => {
  const stepHalfCircleBackground = css`
    flex-shrink: 0;
    width: ${size}px;
    height: ${size}px;
    border-radius: 50%;
    border: 2px solid ${color};
    overflow: hidden;
  `;
  const stepHalfCircleFilled = css`
    display: inline-block;
    background-color: ${color};
    height: ${size}px;
    width: ${size}px;
  `;
  const stepHalfCircleEmpty = css`
    display: inline-block;
    background-color: white;
    height: ${size}px;
    width: ${size}px;
  `;

  return (
    <div className={stepHalfCircleBackground}>
      <div className={stepHalfCircleFilled} />
      <div className={stepHalfCircleEmpty} />
    </div>
  );
};

export const Step = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  typeName = 'Step',
  active,
  activeColor = cvar('color-background-success'),
  completed,
  onClick,
  tip,
  tipDirection,
  small,
  partial,
  icon,
  children,
  vertical,
  className,
  style,
  overrideStatus,
  ...rest
}: StepProps): JSX.Element => {
  const handleClick = () => {
    if (!onClick) {
      return;
    }
    onClick(rest.index, rest.name);
  };

  let color = cvar('dark-color-code-base');
  const filled = completed;

  const size = small ? 14 : 20;

  if (overrideStatus) {
    color = cvar(`color-background-${overrideStatus}`) || cvar('dark-color-code-base');
  } else if (completed) {
    color = cvar('color-background-success');
  } else if (active) {
    color = activeColor;
  } else {
    color = cvar('dark-color-code-base');
  }

  let indicatorIcon = (
    <StepCircle color={color} borderWidth={active ? (small ? 4 : 6) : 2} size={size} filled={filled} />
  );

  if (icon && !small) {
    const indicatorIconStyle = css`
      display: flex;
      align-items: center;
      justify-content: center;
      height: ${size}px;
      width: ${size}px;
      background: ${color};
      color: white;
      border-radius: 100%;
    `;
    indicatorIcon = <div className={cx(indicatorIconStyle)}>{icon}</div>;
  } else if (partial) {
    indicatorIcon = <StepHalfCircle color={color} size={size} />;
  }

  let stepperStyle;
  let indicatorStyle;
  let childrenStyle;
  const tooltipStyle = css`
    padding: inherit;
  `;

  if (vertical) {
    stepperStyle = css`
      text-align: center;
      background-color: inherit;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: ${onClick ? 'pointer' : 'default'};
    `;
    indicatorStyle = css`
      display: flex;
      justify-content: center;
    `;
    childrenStyle = css`
      padding: ${cvar('spacing-8')} 0px;
      margin-left: 10px;
      text-shadow: ${active ? pseudobold : 'none'};
    `;
  } else {
    stepperStyle = css`
      padding: ${small ? '0 4px' : '0 8px'};
      textalign: center;
      width: inherit;
      cursor: ${onClick ? 'pointer' : 'default'};
    `;
    indicatorStyle = css`
      display: flex;
      justify-content: center;
      padding-bottom: ${children ? '0' : '8px'};
    `;
    childrenStyle = css`
      padding: ${cvar('spacing-8')} 0px;
      text-shadow: ${active ? pseudobold : 'none'};
    `;
  }

  const { activeSubStep, connectorColor, ...restProps } = rest;
  const step = (
    <BlockButton
      className={cx('crc-stepper-step', stepperStyle, { clickable: !!onClick }, className)}
      style={{ ...style }}
      onClick={handleClick}
      {...restProps}
    >
      <div className={cx(indicatorStyle)}>{indicatorIcon}</div>
      {children && (!small || vertical) ? <div className={cx(childrenStyle)}>{children}</div> : null}
    </BlockButton>
  );

  return tip ? (
    <Tooltip direction={tipDirection} contents={tip} className={tooltipStyle}>
      {step}
    </Tooltip>
  ) : (
    step
  );
};
