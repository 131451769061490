import i18n from 'i18next';

let languages = require('./locales/all.json');

let i18nInstance = null;

function getI18nInstance() {
  if (!i18nInstance) {
    i18nInstance = i18n.createInstance();

    i18nInstance.init({
      fallbackLng: 'en',
      resources: languages,
      ns: ['react-platform-settings'],
      defaultNS: 'react-platform-settings',
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      react: {
        wait: true,
      },
    });
  }

  return i18nInstance;
}

export { getI18nInstance };
