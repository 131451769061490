import React, { ReactNode, useEffect, useState } from 'react';
import { cx, css } from '@emotion/css';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';

export interface Tab {
  /**
   * Contents of the card that should be shown for that tab.
   */
  block: ReactNode;

  /**
   * Footer is the (optional) card footer.
   */
  footer?: ReactNode;

  /**
   * href can be optionally supplied for each tab link.
   */
  href?: string;

  /**
   * Name of the tab.
   */
  name: string;
}

export interface TabCardProps extends PublicComponentProps {
  /**
   * If provided, a callback function to be called when a tab has been selected.
   */
  onSelect?: (e: any, selectedKey: number) => void;

  /**
   * If provided the internal state is overriden and your selected tab is based on this property.
   *
   * It is likely that if you are providing this value, you should pass in changes by providing `onSelect` and properly updating this property on changes of
   * the selected tab otherwise your clicking tabs will not properly change.
   */
  selectedIndex?: number;

  /**
   * Array of tabs to display in the card.
   */
  tabs: Tab[];
}

const cardTabNavCss = css`
  // override for docusaurus css
  li + li {
    margin: 0;
  }

  border-radius: inherit;
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
`;

const navLinkCss = css`
  color: ${cvar('color-tabcard-text-selected')};
  &:hover {
    background: ${cvar('color-hover')};
  }
`;

const navItemActiveCss = css`
  background: ${cvar('color-background')};
  color: ${cvar('color-tabcard-text-selected')};
  > a {
    ${navLinkCss}
  }
`;

const navItemCss = css`
  background-color: ${cvar('color-nav-item-background')};
  border-right: 1px solid ${cvar('color-background')};
  display: inline-block;
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  > a {
    color: ${cvar('color-nav-text')};
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: ${cvar('spacing-8')} ${cvar('spacing-16')};

    &:hover {
      text-decoration: none;
    }
  }
  &.active {
    ${navItemActiveCss}
  }
  &:hover {
    background-color: ${cvar('color-hover')};
    color: ${cvar('color-text-interactive')};
  }
  &:first-of-type {
    border-top-left-radius: inherit;
  }
  &:last-of-type {
    border-top-right-radius: inherit;
    border-right: none;
  }
`;

const cardFooterCss = css`
  padding: ${cvar('spacing-8')} ${cvar('spacing-16')};
  background-color: ${cvar('color-background-light')};
  border-top: 1px solid ${cvar('color-border-light')};
  text-align: left;
  cursor: default;
`;

const cardBlockCss = css`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${cvar('color-background')};
  flex: 1 1 auto;
  padding: ${cvar('spacing-16')};
  text-align: left;
  cursor: default;
`;

const tabCardCss = css`
  border: 1px solid ${cvar('color-border-default')};
  border-radius: ${cvar('spacing-4')};
  width: 100%;
`;

/**
 * A Component implementing a card with internal tabs in the header
 */
export const TabCard = ({ selectedIndex, onSelect = () => {}, tabs = [], ...rest }: TabCardProps) => {
  const initialSelectedIndex = selectedIndex != null && selectedIndex >= 0 ? selectedIndex : 0;
  const [localSelectedIndex, setLocalSelectedIndex] = useState(initialSelectedIndex);

  const onTabSelect = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, selectedKey: number) => {
    e.preventDefault();
    setLocalSelectedIndex(selectedKey);

    if (onSelect) {
      onSelect(e, selectedKey);
    }
  };

  useEffect(() => {
    if (selectedIndex != null && selectedIndex >= 0) {
      setLocalSelectedIndex(selectedIndex);
    }
  }, [setLocalSelectedIndex, selectedIndex]);

  const renderTab = (tabInfo: Tab, tabIndex: number) => (
    <li key={tabIndex} className={cx(navItemCss, { active: tabIndex === localSelectedIndex })}>
      <a href={tabInfo.href ?? undefined} className={navLinkCss} onClick={e => onTabSelect(e, tabIndex)}>
        <strong>{tabInfo.name}</strong>
      </a>
    </li>
  );

  const renderTabNav = () => <ul className={cardTabNavCss}>{tabs.map(renderTab)}</ul>;

  const renderFooter = () => {
    if (tabs[localSelectedIndex].footer) {
      return <div className={cardFooterCss}>{tabs[localSelectedIndex].footer}</div>;
    }
    return null;
  };

  return (
    <div className={cx('crc-tab-card', tabCardCss)} {...rest}>
      <div>{renderTabNav()}</div>
      <div className={cardBlockCss}>{tabs[localSelectedIndex].block}</div>
      {renderFooter()}
    </div>
  );
};
