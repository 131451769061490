import { css, cx } from '@emotion/css';
import * as React from 'react';

type GapSize = 0 | 8 | 16 | 24 | 32 | 40 | 48 | 56 | 64 | 80 | 96;
type ColumnCount = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface GridLayoutProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  gap?: GapSize;
}

const gridLayoutStyles = css`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: var(--crc-grid-gap, 0);
`;

export function GridLayout({ children, className, style, gap = 16, ...props }: GridLayoutProps) {
  return (
    <div
      className={cx(gridLayoutStyles, className)}
      style={{ ...style, '--crc-grid-gap': `${gap}px` } as React.CSSProperties}
      {...props}
    >
      {children}
    </div>
  );
}

interface GridItemProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;

  /** Number of columns to span */
  span?: ColumnCount;
  /** Number of columns to span at `>640px` */
  smSpan?: ColumnCount;
  /** Number of columns to span at `>768px` */
  mdSpan?: ColumnCount;
  /** Number of columns to span at `>1024px` */
  lgSpan?: ColumnCount;
  /** Number of columns to span at `>1280px` */
  xlSpan?: ColumnCount;

  /** Starting point of column */
  start?: ColumnCount;
  /** Starting point of column at `>640px` */
  smStart?: ColumnCount;
  /** Starting point of column at `>768px` */
  mdStart?: ColumnCount;
  /** Starting point of column at `>1024px` */
  lgStart?: ColumnCount;
  /** Starting point of column at `>1280px` */
  xlStart?: ColumnCount;
}

const gridLayoutItemStyles = css`
  --crc-grid-item-span: var(--crc-grid-item-span-xs);
  --crc-grid-item-start: var(--crc-grid-item-start-xs);

  grid-column: var(--crc-grid-item-start, auto) / span var(--crc-grid-item-span, 12);

  @media screen and (min-width: 640px) {
    --crc-grid-item-span: var(--crc-grid-item-span-sm);
    --crc-grid-item-start: var(--crc-grid-item-start-sm);
  }

  @media screen and (min-width: 768px) {
    --crc-grid-item-span: var(--crc-grid-item-span-md);
    --crc-grid-item-start: var(--crc-grid-item-start-md);
  }

  @media screen and (min-width: 1024px) {
    --crc-grid-item-span: var(--crc-grid-item-span-lg);
    --crc-grid-item-start: var(--crc-grid-item-start-lg);
  }

  @media screen and (min-width: 1280px) {
    --crc-grid-item-span: var(--crc-grid-item-span-xl);
    --crc-grid-item-start: var(--crc-grid-item-start-xl);
  }
`;

export function GridLayoutItem({
  children,
  className,
  style,
  span,
  smSpan,
  mdSpan,
  lgSpan,
  xlSpan,
  start,
  smStart,
  mdStart,
  lgStart,
  xlStart,
  ...props
}: GridItemProps) {
  return (
    <div
      className={cx(gridLayoutItemStyles, className)}
      style={
        {
          ...style,
          '--crc-grid-item-span-xs': span ?? 12,
          '--crc-grid-item-span-sm': smSpan ?? span,
          '--crc-grid-item-span-md': mdSpan ?? smSpan ?? span,
          '--crc-grid-item-span-lg': lgSpan ?? mdSpan ?? smSpan ?? span,
          '--crc-grid-item-span-xl': xlSpan ?? lgSpan ?? mdSpan ?? smSpan ?? span,
          '--crc-grid-item-start-xs': start ?? 'auto',
          '--crc-grid-item-start-sm': smStart ?? start,
          '--crc-grid-item-start-md': mdStart ?? smStart ?? start,
          '--crc-grid-item-start-lg': lgStart ?? mdStart ?? smStart ?? start,
          '--crc-grid-item-start-xl': xlStart ?? lgStart ?? mdStart ?? smStart ?? start,
        } as React.CSSProperties
      }
      {...props}
    >
      {children}
    </div>
  );
}

GridLayout.Item = GridLayoutItem;
