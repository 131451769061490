/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { css, cx } from '@emotion/css';
import { Pagination } from '../Pagination';
import { SortIndicator } from './elements/SortIndicator';
import { TableBody } from './elements/TableBody';
import { EmptyTableRow } from './elements/EmptyTableRow';
import { SortKey, TableColumnProps } from './types';
import cvar from '../theme/cvar';
import { PublicComponentProps } from '../types';
import { Spinner } from '../Spinner';
import { useFeatureFlags } from '../FeatureFlags';

export interface TableProps extends PublicComponentProps {
  data: any[];
  columns: TableColumnProps[];
  sortedBy?: SortKey;
  isCondensed?: boolean;
  hasHover?: boolean;
  isBordered?: boolean;
  noDataText?: string;
  onSortingRequested?: (sortKey: SortKey) => void;
  pageSize?: number;
  paginationPosition?: 'topRight' | 'bottomRight';
  loading?: boolean;
  loadingText?: string;
}

const DEFAULT_LOADING_TEXT = 'Loading...';

const commonCellBorders = `
  border-color: ${cvar('color-border-default')};
  border-style: solid;
  border-width: 0;
  border-top-width: 1px;
`;

const tableStyle = css`
  background-color: ${cvar('color-background')};
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid ${cvar('color-border-default')};
  color: ${cvar('color-text-table-header')};
  margin-bottom: 18px;
  max-width: 100%;
  width: 100%;

  thead {
    background-color: ${cvar('color-table-header')};
    text-transform: uppercase;

    tr {
      th,
      td {
        ${commonCellBorders}
        border-bottom-width: 1px;
        font: ${cvar('text-table-header')};
        padding: ${cvar('spacing-16')} ${cvar('spacing-12')};
        text-align: left;
        vertical-align: top;
      }
    }
  }

  tbody {
    tr {
      td,
      th {
        ${commonCellBorders}
        padding: ${cvar('spacing-12')};
      }
    }
  }
`;

const condensedStyle = css`
  thead {
    tr {
      th,
      td {
        padding: ${cvar('spacing-8')};
      }
    }
  }
  tbody {
    font-size: 10px;
    line-height: 12px;
  }
`;

const hoverStyle = css`
  tbody {
    tr:hover {
      background-color: ${cvar('color-hover')};
    }
  }
`;

const borderedStyle = css`
  thead,
  tbody {
    tr {
      td,
      th {
        border-width: 1px;
      }
    }
  }
`;

const loadingOverlayStyle = css`
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1;
  display: grid;
  place-items: center;

  && td {
    border: none;
  }
`;

const tableBodyStyle = css`
  position: relative;
`;

const noOuterMarginCss = css`
  margin-bottom: 0;
`;

function LoadingOverlay({ loadingText, columnCount }: { loadingText?: string; columnCount: number }) {
  return (
    <tr className={loadingOverlayStyle}>
      <td colSpan={columnCount} align="center">
        <Spinner />
        <p>{loadingText}</p>
      </td>
    </tr>
  );
}

export const Table = ({
  data,
  columns,
  sortedBy,
  noDataText,
  onSortingRequested,
  isCondensed,
  hasHover,
  isBordered,
  pageSize,
  paginationPosition,
  loading,
  loadingText = DEFAULT_LOADING_TEXT,
  className,
  ...rest
}: TableProps) => {
  const { v17_noOuterSpacing } = useFeatureFlags();

  const [page, setPage] = useState<number>(0);

  const onHeaderSortClick = (c: TableColumnProps) => {
    if (!c.sortable) {
      return;
    }

    if (onSortingRequested) {
      onSortingRequested({
        key: c.accessor,
        desc: sortedBy && sortedBy.key === c.accessor ? !sortedBy.desc : sortedBy ? sortedBy.desc : false,
      });
    }
  };

  const total = (data || []).length;
  const hasPages = pageSize && total > pageSize;

  let pagination: JSX.Element | null = null;
  let tableData = data;

  if (hasPages && pageSize) {
    const start = page * pageSize;
    tableData = (data || []).slice(start, start + pageSize);
    pagination = hasPages ? (
      <Pagination
        initialPage={page}
        pageCount={Math.ceil(total / pageSize)}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        onPageChange={({ selected }: { selected: number }) => {
          setPage(selected);
        }}
      />
    ) : null;
  }

  // Apply min height to `tbody` when loading and no data, otherwise the table will collapse
  const tbodyHeightStyle = loading && tableData.length === 0 ? { height: '200px' } : undefined;

  return (
    <>
      {paginationPosition === 'topRight' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: v17_noOuterSpacing ? cvar('spacing-24') : 0,
          }}
        >
          {pagination}
        </div>
      )}
      <table
        className={cx(
          'crc-table',
          tableStyle,
          isCondensed && condensedStyle,
          hasHover && hoverStyle,
          isBordered && borderedStyle,
          v17_noOuterSpacing && noOuterMarginCss,
          className,
        )}
        aria-busy={loading}
        {...rest}
      >
        <thead>
          <tr>
            {columns.map((c, i) => (
              <th key={`th-${i}-${c.accessor}`}>
                {c.sortable ? (
                  <div
                    tabIndex={0}
                    role="button"
                    style={{ textTransform: 'uppercase', cursor: c.sortable ? 'pointer' : undefined }}
                    onClick={() => onHeaderSortClick(c)}
                    onKeyDown={event => {
                      if (event.keyCode === 13 || event.keyCode === 32) {
                        event.preventDefault();
                        onHeaderSortClick(c);
                      }
                    }}
                  >
                    {c.Header}
                    {c.sortable ? <SortIndicator currentSort={sortedBy} accessor={c.accessor} /> : null}
                  </div>
                ) : (
                  c.Header
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={tableBodyStyle} style={tbodyHeightStyle}>
          {loading && <LoadingOverlay loadingText={loadingText} columnCount={columns.length} />}
          {tableData.length > 0 ? (
            <TableBody data={tableData} columns={columns} />
          ) : !loading ? (
            <EmptyTableRow columns={columns} message={noDataText} />
          ) : null}
        </tbody>
      </table>
      {paginationPosition === 'bottomRight' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: v17_noOuterSpacing ? cvar('spacing-24') : 0,
          }}
        >
          {pagination}
        </div>
      )}
    </>
  );
};
