import React, { type ReactNode, createContext, useMemo, useContext } from 'react';

export interface FeatureFlagsConfig {
  v17_noOuterSpacing?: boolean;
}

const contextDefaultValue: Required<FeatureFlagsConfig> = {
  v17_noOuterSpacing: false,
};

export const FeatureFlagsContext = createContext<Required<FeatureFlagsConfig>>(contextDefaultValue);

export function FeatureFlags({ children, flags }: { children: ReactNode; flags: FeatureFlagsConfig }) {
  const featureFlags = useMemo(
    () => ({
      ...contextDefaultValue,
      ...flags,
    }),
    [flags],
  );

  return <FeatureFlagsContext.Provider value={featureFlags}>{children}</FeatureFlagsContext.Provider>;
}

/** @internal */
export function useFeatureFlags() {
  return useContext(FeatureFlagsContext);
}
