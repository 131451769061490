import * as React from 'react';
import { SortIndicatorSvgProps } from './SortIndicatorSvg';

const SortIndicatorUpSvg = ({ size, color, style }: SortIndicatorSvgProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    color={color}
    style={style}
  >
    <path d="M23.437 19L13.711 2.93a2 2 0 0 0-3.422 0L.563 19a2 2 0 0 0 1.711 3.035h19.452A2 2 0 0 0 23.437 19z" />
  </svg>
);

export default SortIndicatorUpSvg;
