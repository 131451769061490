import React, { ReactNode } from 'react';
import { Robot } from '../Robot';

const defaultNoDataText = 'There are no data to display.';

const TableNoData: React.FC<{ children?: ReactNode; loading?: boolean; entireHeaderHeight?: number }> = ({
  children,
  loading,
  entireHeaderHeight = 0,
}) => {
  if (loading) {
    return null;
  }

  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        top: entireHeaderHeight,
        bottom: 0,
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Robot status="warning" />
      <h4>{children || defaultNoDataText}</h4>
    </div>
  );
};

export default TableNoData;
