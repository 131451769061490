import {
  alloy,
  danger,
  disabled,
  horizon,
  info,
  ocean,
  platinum,
  sky,
  slate,
  success,
  warning,
  white,
  silver,
  coal,
  teal,
  persimmon,
  cobalt,
} from '../colors';

export default {
  'color-background': white,
  'color-button-default': white,
  'color-hover': horizon,
  'color-pagination-selected': horizon,
  'color-drag-background-dragging': horizon,
  'color-background-light': silver,
  'color-textfield-disabled': silver,
  'color-border-light': platinum,
  'color-progressbar-background': platinum,
  'color-nav-item-background': platinum,
  'color-tab-default': platinum,
  'color-table-header': platinum,
  'color-border-default': alloy,
  'color-spinner-ring': alloy,
  'color-toggle-background-off': slate,
  'color-tabcard-text-selected': coal,
  'color-dropdown-label-active': coal,
  'color-text-label': slate,
  'color-inline-edit-border': teal.base,
  'color-tooltip-default': cobalt.base,

  'color-404-heading': persimmon.base,
  'color-required-asterisk': persimmon.base,

  'color-button-primary': ocean.base,
  'color-interactive': ocean.base,
  'color-border-interactive': ocean.base,
  'color-button-primary-hover': ocean.darker,
  'color-dropdown-active': ocean.darker,
  'color-interactive-hover': ocean.darker,
  'color-nav-text': ocean.darker,
  'color-progressbar-gradient-primary': ocean.darker,
  'color-button-primary-active': ocean.darkest,

  'color-button-secondary-hover': sky.base,
  'color-tabmenu-background': sky.base,
  'color-button-secondary-active': sky.darker,
  'color-border-dropdown-active': sky.darkest,

  'color-background-info': info.base,
  'color-border-info': info.base,
  'color-progressbar-gradient-info': info.darker,

  'color-background-success': success.base,
  'color-border-success': success.base,
  'color-progressbar-gradient-success': success.darker,

  'color-background-warning': warning.base,
  'color-border-warning': warning.base,
  'dark-color-progressbar-warning': warning.darker,

  'color-background-error': danger.base,
  'color-border-error': danger.base,
  'dark-color-progressbar-error': danger.darker,

  'color-slider-empty': disabled.base,
  'color-background-disabled': disabled.base,
  'color-button-disabled': disabled.darker,
};
