import React, { ReactNode } from 'react';
import { cx } from '@emotion/css';
import { PublicComponentProps } from './types';

export interface NavTabItemProps extends PublicComponentProps {
  /**
   * Determines whether this NavTabItem is active.
   */
  active?: boolean;

  /**
   * Provide content for the NavTabItem as children.
   */
  children?: ReactNode;

  /**
   * Determines whether this NavTabItem is disabled.
   */
  disabled?: boolean;
}

export const NavTabItem = ({ children, className, active, disabled, ...rest }: NavTabItemProps) => (
  <li className={cx('crc-navtab-item', className, { active, disabled })} {...rest}>
    {children}
  </li>
);
