import React from 'react';
import { teal } from '../colors';

export interface CloseProps {
  /** Width of the Close shape, defaults to '32px'. */
  width?: string;
  /** Color of the Close shape, defaults to 'teal.base'. */
  color?: string;
  /** If true, the Close shape will be cropped to 16x16px. Defaults to false. */
  cropped?: boolean;
}

export const Close = ({ width = '32px', color = teal.base, cropped = false, ...rest }: CloseProps) =>
  cropped ? (
    <svg width={width} height={width} viewBox="0 0 16 16" {...rest}>
      <path
        fillRule="evenodd"
        fill={color}
        d={`M7.29289322,8 L2.14644661,2.85355339 C1.95118446,2.65829124 1.95118446,2.34170876 2.14644661,2.14644661 C2.34170876,
            1.95118446 2.65829124,1.95118446 2.85355339,2.14644661 L8,7.29289322 L13.1464466,2.14644661 C13.3417088,1.95118446 13.6582912,
            1.95118446 13.8535534,2.14644661 C14.0488155,2.34170876 14.0488155,2.65829124 13.8535534,2.85355339 L8.70710678,8 L13.8535534,
            13.1464466 C14.0488155,13.3417088 14.0488155,13.6582912 13.8535534,13.8535534 C13.6582912,14.0488155 13.3417088,14.0488155 13.1464466,
            13.8535534 L8,8.70710678 L2.85355339,13.8535534 C2.65829124,14.0488155 2.34170876,14.0488155 2.14644661,13.8535534 C1.95118446,
            13.6582912 1.95118446,13.3417088 2.14644661,13.1464466 L7.29289322,8 Z`}
      />
    </svg>
  ) : (
    <svg width={width} height={width} viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        fill={color}
        d={`M15.012471,16.4266845 L8.29712776,23.1420278 C7.89821149,23.540944 7.90443226,24.1678882 8.29495655,24.5584125 C8.68820351,
            24.9516595 9.31961791,24.9479647 9.71134132,24.5562413 L16.4266845,17.8408981 L23.1420278,24.5562413 C23.540944,24.9551576 24.1678882,
            24.9489368 24.5584125,24.5584125 C24.9516595,24.1651656 24.9479647,23.5337512 24.5562413,23.1420278 L17.8408981,16.4266845 L24.5562413,
            9.71134132 C24.9551576,9.31242506 24.9489368,8.68548085 24.5584125,8.29495655 C24.1651656,7.9017096 23.5337512,7.90540434 23.1420278,
            8.29712776 L16.4266845,15.012471 L9.71134132,8.29712776 C9.31242506,7.89821149 8.68548085,7.90443226 8.29495655,8.29495655 C7.9017096,
            8.68820351 7.90540434,9.31961791 8.29712776,9.71134132 L15.012471,16.4266845 Z`}
      />
    </svg>
  );
