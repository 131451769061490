import React, { useState, useEffect, ReactNode } from 'react';
import { css, cx } from '@emotion/css';
import { isBrowser, useMemoizedId } from './utils';
import cvar from './theme/cvar';
import defaultTheme from './theme/theme';
import { controlLabelCss, formGroupActiveCss, formGroupCss } from './TextInput';
import { PublicComponentProps } from './types';
import { useFeatureFlags } from './FeatureFlags';

const isSelectionTruthy = (value: any) =>
  !((Array.isArray(value) && !value.length) || value === null || value === undefined || value === '');

const noop = () => {};

type Status = 'success' | 'warning' | 'error';

export interface SelectWrapperProps extends PublicComponentProps {
  /**
   * allowed validation states
   */
  status?: Status;
  /**
   * A class name to append to the div that wraps the select component.
   */
  containerClassName?: string;

  /**
   * Additional help text to display under the select field.
   */
  helpText?: ReactNode;

  label?: ReactNode;

  placeholder?: ReactNode;

  onFocus?: (e: any) => void;

  onBlur?: (e: any) => void;

  required?: boolean;

  /**
   * The Select you wish to use to render the component.
   */
  selectedSelect?: any;

  value?: any;

  [key: string]: any;
}

const selectLabel = css`
  pointer-events: none;
  word-wrap: break-word;
  width: 80%;
  line-height: 20px;
`;

const textColorMap: Record<Status, keyof typeof defaultTheme> = {
  success: 'color-text-success',
  warning: 'color-text-warning',
  error: 'color-text-error',
};

const helpTextClass = (status: Status) => css`
  ${textColorMap[status] ? `color: ${cvar(textColorMap[status])}` : ''}
`;

const noOuterMarginCss = css`
  margin-bottom: 0;
`;

export const SelectWrapper = (props: SelectWrapperProps) => {
  const {
    value,
    containerClassName,
    placeholder,
    label,
    selectedSelect,
    required,
    helpText,
    onBlurResetsInput,
    status,
    onBlur = noop,
    onFocus = noop,
    id,
    onInputChange,
    'data-testid': dataTestid,
  } = props;

  const { v17_noOuterSpacing } = useFeatureFlags();

  const [shouldFloat, setShouldFloat] = useState(value);
  const labelId = useMemoizedId({ label: 'react-select', id });
  const handleFocus = (event: any) => {
    setShouldFloat(true);
    onFocus(event);
  };

  const handleBlur = (event: any) => {
    const target = event && event.target;
    if ((onBlurResetsInput === false && target && target.value) || isSelectionTruthy(value)) {
      setShouldFloat(true);
    } else {
      setShouldFloat(false);
    }
    onBlur(event);
  };

  function handleInputKeyDown(searchString: string, ...args: any[]) {
    onInputChange?.(searchString, ...args);

    if (searchString !== '') {
      setShouldFloat(true);
    }
  }

  useEffect(() => {
    setShouldFloat(isSelectionTruthy(value));
  }, [value]);

  const className = cx('crc-selectwrapper', formGroupCss, v17_noOuterSpacing && noOuterMarginCss, containerClassName, {
    [formGroupActiveCss]: shouldFloat,
  });

  return (
    <div className={className} data-testid={dataTestid}>
      <label htmlFor={labelId} className={cx(controlLabelCss, selectLabel)} title={`${placeholder || label}`}>
        {placeholder || label}
        {required ? <span className={css({ color: cvar('color-required-asterisk') })}> *</span> : ''}
      </label>
      {React.createElement(selectedSelect, {
        menuPortalTarget: isBrowser() ? document.body : undefined,
        ...props,
        inputId: labelId,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onInputChange: handleInputKeyDown,
        placeholder: '',
      })}
      {helpText ? <small className={helpTextClass(status as Status)}>{helpText}</small> : null}
    </div>
  );
};
