import React from 'react';
import { css } from '@emotion/css';
import { ArrowAlt } from '../accordion/ArrowAlt';
import { ArrowDirection } from '../common';
import { BlockButton } from '../internal';
import cvar from '../theme/cvar';

export interface CarouselControlProps {
  disabled: boolean;
  onClick: () => void;
  direction: ArrowDirection;
  large: boolean;
  hintText: string;
}

const carouselControlCss = css`
  background: 0 0;
  position: relative;
  width: 20px;
  color: ${cvar('color-interactive')};
  text-shadow: none;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  top: 0;
  bottom: 0;
  left: 0;
  font-size: 20px;
  text-align: center;
  opacity: 0.5;
`;

const srOnlyCss = css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

const CarouselControl = ({ disabled, onClick, direction, large, hintText }: CarouselControlProps) => (
  <BlockButton
    className={carouselControlCss}
    style={disabled ? { visibility: 'hidden' } : {}}
    onClick={!disabled ? onClick : undefined}
  >
    <ArrowAlt
      direction={direction}
      size={large ? 16 : 14}
      aria-hidden="true"
      color={cvar('color-icon-interactive')}
      outlineWidth="0"
    />
    <span className={srOnlyCss}>{hintText}</span>
  </BlockButton>
);

export default CarouselControl;
