import { Environment, Item } from '../../types';
import { ItemRoutingDecisionViewerBody } from './ItemRoutingDecisionViewerBody';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ItemRoutingDecisionViewerModal } from './ItemRoutingDecisionViewerModal';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const queryClient = new QueryClient();

export type ItemRoutingDecisionViewerProps = {
  environment?: Environment;
  accessToken: string;
  item: Item;
  showDecisionLinks?: boolean;
  isModal?: boolean;
  showModal?: boolean;
  closeModal?: () => void;
};

const ItemRoutingDecisionViewer = (props: ItemRoutingDecisionViewerProps) => {
  const { isModal = false } = props;
  if (isModal) {
    return <ItemRoutingDecisionViewerModal queryClient={queryClient} {...props} />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <ItemRoutingDecisionViewerBody {...props} />
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

export default ItemRoutingDecisionViewer;
