export default {
  'spacing-2': '2px',
  'spacing-4': '4px',
  'spacing-8': '8px',
  'spacing-12': '12px',
  'spacing-16': '16px',
  'spacing-24': '24px',
  'spacing-32': '32px',
  'spacing-40': '40px',
  'spacing-48': '48px',
};
