/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext } from 'react';
import { RoutingDecisionContext } from '../DecisionViewerBody';
import { NoOptionsNetworkError } from './NoOptionsNetworkError';
import { UseQueryResult } from 'react-query';
import { InvalidConsideredOption, Option } from '../../types';
import { wrapper } from '../../sharedStyles';
import { GenericNoOptions } from './GenericNoOptions';

const noFulfillmentOptionWrapper = css`
  padding: 1.6rem 0;

  > * {
    padding: 0 1.6rem; // apply left/right padding to the direct children so the scrollbar doesn't overlap them on short height screens
  }
`;

export const NoOptions = ({ options }: { options: UseQueryResult<Option | InvalidConsideredOption, Error>[] }) => {
  const { routingProblem } = useContext(RoutingDecisionContext);

  if (routingProblem && options.length === 0) {
    return (
      <div css={[wrapper, noFulfillmentOptionWrapper]} data-testid="network-error-no-fulfillment-options">
        <h3>Fulfillment Options</h3>
        <NoOptionsNetworkError />
      </div>
    );
  }

  return (
    <div css={[wrapper, noFulfillmentOptionWrapper]} data-testid="generic-no-fulfillment-options">
      <h3>Fulfillment Options</h3>
      <GenericNoOptions />
    </div>
  );
};
