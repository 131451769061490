import twemoji from '@twemoji/api';
import { allCountries, Country } from 'country-telephone-data';
import mapValues from 'lodash.mapvalues';

const codePoints: { [key: string]: string } = {
  A: '\u{1F1E6}',
  B: '\u{1F1E7}',
  C: '\u{1F1E8}',
  D: '\u{1F1E9}',
  E: '\u{1F1EA}',
  F: '\u{1F1EB}',
  G: '\u{1F1EC}',
  H: '\u{1F1ED}',
  I: '\u{1F1EE}',
  J: '\u{1F1EF}',
  K: '\u{1F1F0}',
  L: '\u{1F1F1}',
  M: '\u{1F1F2}',
  N: '\u{1F1F3}',
  O: '\u{1F1F4}',
  P: '\u{1F1F5}',
  Q: '\u{1F1F6}',
  R: '\u{1F1F7}',
  S: '\u{1F1F8}',
  T: '\u{1F1F9}',
  U: '\u{1F1FA}',
  V: '\u{1F1FB}',
  W: '\u{1F1FC}',
  X: '\u{1F1FD}',
  Y: '\u{1F1FE}',
  Z: '\u{1F1FF}',
};

const isoUnicode = allCountries.reduce((isoMap: { [key: string]: string }, country: Country) => {
  const { iso2 } = country;
  const key = iso2.toUpperCase();
  const utfCode = `${codePoints[key[0]]}${codePoints[key[1]]}`;
  /* eslint-disable no-param-reassign */
  isoMap[key] = utfCode;
  /* eslint-enable no-param-reassign */
  return isoMap;
}, {});

// Explicitly add non-countries which have currencies to the list of flags.
isoUnicode.EU = `${codePoints.E}${codePoints.U}`;

const emojiFlags = mapValues(isoUnicode, code => twemoji.parse(code).replace('class="emoji"', 'class="select-emoji'));

export default emojiFlags;
