import * as React from 'react';
import { white } from '../colors';

const InfoSvg = (props: { [otherOptions: string]: unknown }) => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    color={white}
    {...props}
  >
    <path
      d="M721 278h16a4 4 0 014 4v16a4 4 0 01-4 4h-16a4 4 0 01-4-4v-16a4 4 0 014-4zm7.4 6a1.8 1.8 0 110 3.6 1.8 1.8 0 010-3.6zm3 11.46c0 .298-.23.54-.514.54h-3.772a.528.528 0 01-.514-.54v-1.08c0-.298.23-.54.514-.54h.515v-2.88h-.515a.528.528 0 01-.514-.54v-1.08c0-.298.23-.54.514-.54h2.743c.284 0 .514.242.514.54v4.5h.515c.284 0 .514.242.514.54v1.08z"
      transform="translate(-717 -278)"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);

export default InfoSvg;
