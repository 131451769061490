/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment */
import { FailedDecision, Item, LinkWithName, SuccessfulDecision } from '../../types';

export type RoutingUiErrorName = 'ROUTING_DECISION_ERROR' | 'ROUTING_REQUEST_ERROR' | 'INFORMATION' | 'ERROR';

export type ErrorContext = {
  item?: Item;
  routingDecision?: SuccessfulDecision | FailedDecision;
  decisionLink?: string;
  showDecisionLinks?: boolean;
};
export class RoutingUIError extends Error {
  name: RoutingUiErrorName;
  message: string;
  cause?: any;
  link?: LinkWithName;
  context?: ErrorContext;

  constructor({
    name,
    message,
    cause,
    link,
    context,
  }: {
    name: RoutingUiErrorName;
    message: string;
    cause?: any;
    link?: LinkWithName;
    context?: ErrorContext;
  }) {
    super();
    this.name = name;
    this.message = message;
    this.cause = cause;
    this.link = link;
    this.context = context;
  }
}
