/** @jsxImportSource @emotion/react */
import { Global, css } from '@emotion/react';
import { DecisionViewerBody } from './DecisionViewerBody';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Environment, Item } from '../types';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import { createContext, useRef } from 'react';
import { RetryContextProvider } from './Retry/RetryContext';

const wrapperStyles = css`
  width: 100%;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  height: 100%;

  h2 {
    margin: 0 0 1.6rem 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.9rem;
    font-weight: 400;
  }
`;

type AccessContextValues = {
  environment: Environment;
  accessToken: string;
};

export const AccessContext = createContext<AccessContextValues>({ environment: 'prd', accessToken: '' });

export type DecisionViewerProps = {
  environment?: Environment;
  decisionLink?: string;
  showDecisionLinks?: boolean;
  accessToken: string;
  queryClient?: QueryClient;
  item?: Item;
  decisionJson?: string;
  halfWeight?: boolean;
};

const DecisionViewer = (props: DecisionViewerProps) => {
  const { environment = 'prd', accessToken, queryClient: queryClientProp } = props;
  const queryClient = useRef(queryClientProp ?? new QueryClient());

  return (
    <>
      <Global
        styles={css`
          @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
        `}
      />
      <AccessContext.Provider value={{ environment, accessToken }}>
        <div css={wrapperStyles} data-testid={queryClientProp ? 'decision-viewer' : 'decision-viewer-with-provider'}>
          {/* If we get the query client as a prop, then this component will already
            be wrapped in the QueryClientProvider higher in the DOM tree */}
          {!queryClientProp ? (
            <QueryClientProvider client={queryClient.current}>
              <RetryContextProvider>
                <ErrorBoundary>
                  <DecisionViewerBody {...props} />
                </ErrorBoundary>
              </RetryContextProvider>
            </QueryClientProvider>
          ) : (
            <RetryContextProvider>
              <ErrorBoundary>
                <DecisionViewerBody {...props} />
              </ErrorBoundary>
            </RetryContextProvider>
          )}
        </div>
      </AccessContext.Provider>
    </>
  );
};

export default DecisionViewer;
