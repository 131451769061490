import React, { useEffect, useState, ReactNode } from 'react';
import { cx, css } from '@emotion/css';
import { horizon } from '../colors';
import CarouselSlide from './CarouselSlide';
import CarouselControl from './CarouselControl';
import CarouselIndicator, { Direction } from './CarouselIndicator';
import CarouselLoading from './CarouselLoading';
import CarouselFallback from './CarouselFallback';
import cvar from '../theme/cvar';
import { PublicComponentProps } from '../types';

export interface CarouselProps extends PublicComponentProps {
  /**
   * Images to use as slides for the carousel.
   */
  children?: ReactNode;

  /**
   * Use this to force the carousel to navigate to a particular slide.
   */
  currentSlide?: number;

  /**
   * Component to display when the carousel has no images.
   */
  fallbackPreview?: ReactNode;

  /**
   * Hides navigation and thumbnails for the carousel.
   */
  hideIndicators?: boolean;

  /**
   * Displays the carousel in landscape mode, with the thumbnails vertically stacked to the left of the carousel. Not available in minimal mode.
   */
  landscape?: boolean;

  /**
   * If true, the carousel will display a loading spinner.
   */
  loading?: boolean;
  /**
   * Whether to show the carousel in minimal mode, with a numerical indicator, or in full mode, with thumbnail images.
   */
  minimal?: boolean;

  /**
   * Callback function invoked when the large carousel image is clicked.
   */
  onCarouselClick?: (currentSlide: number) => void;

  /**
   * Callback function invoked whenever the carousel navigates to a new slide.
   */
  onSlideChanged?: (currentSlide: number) => void;

  /**
   * Size for the main carousel image, in pixels. Only used when the carousel is in minimal mode.
   */
  pxSize?: number;

  /**
   * Shows a zoom overlay on hover. Must also provide onCarouselClick to show.
   * @default false
   */
  showZoomOverlay?: boolean;

  /**
   * Content to put in the zoom overlay.
   */
  zoomOverlayContent?: ReactNode;

  /**
   * Size for the carousel. If carousel is in minimal mode, this size will be ignored in favor of pxSize.
   */
  size?: 'xs' | 's' | 'm' | 'l' | 'xl';
}

export interface SlideState {
  currentSlide: number;
  previousSlide: number | null;
  transitioning: boolean;
  timer: number | null;
  direction: Direction | '';
}

const THUMBNAIL_WIDTH = 70;

const getCarouselWidthForThumbs = (numberOfThumbs: number) => 40 + THUMBNAIL_WIDTH * numberOfThumbs;

const sizeMap = {
  xs: { maxThumbs: 3, pxWidth: getCarouselWidthForThumbs(3) },
  s: { maxThumbs: 4, pxWidth: getCarouselWidthForThumbs(4) },
  m: { maxThumbs: 5, pxWidth: getCarouselWidthForThumbs(5) },
  l: { maxThumbs: 6, pxWidth: getCarouselWidthForThumbs(6) },
  xl: { maxThumbs: 7, pxWidth: getCarouselWidthForThumbs(7) },
};

const carouselCss = css`
  position: relative;
  div {
    height: 100%;
  }
`;
const slideCss = css`
  border: 1px solid ${cvar('color-border-light')};
  background-color: ${cvar('color-background')};
  height: 100%;
`;

const carouselInnerCss = css`
  position: relative;
  width: 100%;
  overflow: hidden;

  img {
    margin: auto;
    max-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    max-width: 100%;
    line-height: 1;
  }
`;

const carouselNavigationCss = css`
  margin-top: 25px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
`;

const carouselNavigationSmallCss = css`
  margin-top: 16px;
`;

const carouselNavigationVerticalCss = css`
  margin-top: 0;
  margin-right: 25px;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: start;
  justify-content: flex-start;
`;

const carouselIndicatorsCss = css`
  position: relative;
  bottom: 0;
  left: 0;
  margin: 0;
  width: auto;
  line-height: 0;
  overflow: hidden;
  height: 70px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  z-index: 15;
  padding-left: 0;
  text-align: center;
  list-style: none;
  flex: 1;

  li {
    transition: 0.6s ease-in-out all;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 5px;
    width: 60px;
    height: 60px;
    background-color: ${cvar('color-background')};
    border-radius: 0;
    overflow: hidden;
    text-indent: 0;
    position: relative;
    display: inline-block;
    cursor: pointer;

    img {
      margin: auto;
      max-height: 100%;
      max-width: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
`;

const smallCss = css`
  font-size: 85%;
  width: 100%;
`;

export const Carousel = ({
  loading,
  size = 'm',
  pxSize = 200,
  minimal = false,
  children,
  onCarouselClick,
  fallbackPreview = <CarouselFallback />,
  hideIndicators = false,
  showZoomOverlay = false,
  zoomOverlayContent,
  className,
  style,
  landscape = false,
  currentSlide = 0,
  onSlideChanged,
  ...rest
}: CarouselProps) => {
  const total = React.Children.count(children);
  const pixels = size && !minimal ? sizeMap[size].pxWidth : pxSize;
  const allowNavigation = (minimal && total > 1) || (!minimal && total > sizeMap[size].maxThumbs);
  const isLandscape = minimal ? false : landscape;

  /**
   * State and control for slides
   */
  const [slideState, setSlideState] = useState<SlideState>({
    currentSlide: currentSlide > total - 1 ? 0 : currentSlide,
    previousSlide: null,
    transitioning: false,
    timer: null,
    direction: '',
  });

  useEffect(() => {
    setSlideState(previousState => {
      if (currentSlide !== undefined && previousState.currentSlide !== currentSlide) {
        const slideNumber = currentSlide < 0 || currentSlide >= total ? previousState.currentSlide : currentSlide;
        return {
          ...previousState,
          currentSlide: slideNumber,
        };
      }
      return previousState;
    });
  }, [currentSlide, total]);

  const clearPendingAnimation = () => {
    if (slideState.timer) {
      clearTimeout(slideState.timer);
    }
  };

  const endAnimation = () =>
    window.setTimeout(
      () =>
        setSlideState(prevState => ({
          ...prevState,
          previousSlide: null,
          transitioning: false,
        })),
      600,
    );

  const goToSlide = (nextSlide: number, direction?: Direction) => {
    if (nextSlide === slideState.currentSlide) {
      return;
    }

    clearPendingAnimation();

    setSlideState(prevState => ({
      ...prevState,
      direction: direction || (nextSlide > slideState.currentSlide ? 'next' : 'prev'),
      previousSlide: prevState.currentSlide,
      currentSlide: nextSlide,
      transitioning: true,
      timer: endAnimation(),
    }));
    onSlideChanged && onSlideChanged(nextSlide);
  };

  const goToNextSlide = () => {
    let nextSlide = slideState.currentSlide + 1;
    if (nextSlide >= React.Children.count(children)) {
      nextSlide = 0;
    }

    goToSlide(nextSlide, 'next');
  };

  const goToPreviousSlide = () => {
    const nextSlide = slideState.currentSlide > 0 ? slideState.currentSlide - 1 : React.Children.count(children) - 1;
    goToSlide(nextSlide, 'prev');
  };

  /**
   * State and controls for thumbnails
   */
  const [thumbsPosition, setThumbsPosition] = useState(0);
  const goToThumbs = (direction: Direction) => {
    let newThumbsPosition = thumbsPosition;

    const totalLength = React.Children.count(children) * THUMBNAIL_WIDTH;
    const thumbsWidth = sizeMap[size].maxThumbs * THUMBNAIL_WIDTH;
    const loopPosition = direction === 'next' ? 0 : (Math.floor(totalLength / thumbsWidth) - 1) * thumbsWidth * -1;
    direction === 'next' ? (newThumbsPosition -= thumbsWidth) : (newThumbsPosition += thumbsWidth);
    newThumbsPosition =
      Math.abs(newThumbsPosition) >= totalLength || newThumbsPosition > 0 ? loopPosition : newThumbsPosition;

    setThumbsPosition(newThumbsPosition);
  };
  const goToNextThumbs = () => goToThumbs('next');
  const goToPreviousThumbs = () => goToThumbs('prev');

  const slides = React.Children.map(children, (child, i) => (
    <CarouselSlide
      active={i === slideState.currentSlide}
      index={i}
      animateOut={i === slideState.previousSlide && slideState.transitioning}
      animateIn={i === slideState.currentSlide && slideState.previousSlide !== null && slideState.transitioning}
      direction={slideState.direction}
      onCarouselClick={onCarouselClick}
      showZoomOverlay={showZoomOverlay}
      zoomOverlayContent={zoomOverlayContent}
    >
      {child}
    </CarouselSlide>
  ));

  const componentStyle: React.CSSProperties = isLandscape
    ? { height: `${pixels}px`, display: 'flex', flexDirection: 'row-reverse', width: 'fit-content' }
    : { width: `${pixels}px` };

  return (
    <div className={cx('crc-carousel', className)} style={{ ...componentStyle, ...style }} {...rest}>
      <div style={isLandscape ? { width: `${pixels}px` } : { height: `${pixels}px` }}>
        <div className={cx(carouselCss, slideCss)}>
          <div className={carouselInnerCss} role="listbox">
            {loading ? <CarouselLoading size={pixels} /> : slides && slides.length ? slides : fallbackPreview}
          </div>
        </div>
      </div>
      {!hideIndicators && (
        <div
          className={cx(carouselNavigationCss, {
            [carouselNavigationSmallCss]: minimal,
            [carouselNavigationVerticalCss]: isLandscape,
          })}
        >
          <CarouselControl
            disabled={!allowNavigation}
            onClick={minimal ? goToPreviousSlide : goToPreviousThumbs}
            large={!minimal}
            direction={isLandscape ? 'up' : 'left'}
            hintText="Previous"
          />
          <ol
            className={cx(carouselIndicatorsCss, {
              [css`
                flex-direction: column;
              `]: isLandscape,
              [css`
                justify-content: center;
              `]: !total,
            })}
            style={minimal ? { height: 'auto', overflow: 'visible' } : {}}
          >
            {total && !minimal ? (
              React.Children.map(children, (child, i) => (
                <CarouselIndicator
                  slideNumber={i}
                  active={slideState.currentSlide === i}
                  goToSlide={goToSlide}
                  offset={isLandscape ? { top: thumbsPosition } : { left: thumbsPosition }}
                >
                  {loading ? null : child}
                </CarouselIndicator>
              ))
            ) : minimal ? (
              <div className={smallCss}>{total > 0 ? `${slideState.currentSlide + 1} / ${total}` : '– / –'}</div>
            ) : (
              <li style={{ backgroundColor: horizon }} />
            )}
          </ol>
          <CarouselControl
            disabled={!allowNavigation}
            onClick={minimal ? goToNextSlide : goToNextThumbs}
            large={!minimal}
            direction={isLandscape ? 'down' : 'right'}
            hintText="Next"
          />
        </div>
      )}
    </div>
  );
};
