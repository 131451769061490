import React, { ReactNode } from 'react';
import ReactSelect, { Props } from 'react-select';
import { SelectWrapper, SelectWrapperProps } from '../SelectWrapper';
import styles from './styles';
import { PublicComponentProps } from '../types';

export interface SelectProps
  extends SelectWrapperProps,
    Omit<Props, 'onBlur' | 'onFocus' | 'value'>,
    PublicComponentProps {
  /**
   * allowed validation states
   */
  status?: 'success' | 'warning' | 'error';
  /**
   * A class name to append to the div that wraps the select component.
   */
  containerClassName?: string;

  /**
   * Additional help text to display under the select field.
   */
  helpText?: ReactNode;

  /**
   * Label for the select field. If you provide a `placeholder` (per react-select props), it will be used as the label.
   */
  label?: string;

  /**
   * Used for tethering to the window. If set to true, the dropdown list will be hoisted to the body and positioned correctly.
   */
  tether?: boolean;

  /**
   * Function to call on selection from the list
   */
  onChange?: (e: any) => void;
}

// The default CT Select is just a CT-skinned version of react-select
export const Select = (props: SelectProps) => (
  <SelectWrapper {...props} styles={{ ...props.styles, ...styles }} selectedSelect={ReactSelect} />
);
