/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { get } from 'lodash';
import { Dropdown, Button } from '@cimpress/react-components';
import IconNavigationMenuVertical from '@cimpress-technology/react-streamline-icons/lib/IconNavigationMenuVertical';
import { useGetRoutingRequest } from '../hooks/useRequestManager';
import { AccessContext } from './DecisionViewer';
import { SuccessfulDecision, FailedDecision } from '../types';
import { useAsyncError } from '../hooks/useAsyncError';
import InlineErrorBoundary from './ErrorBoundary/InlineErrorBoundary';

type OverviewDropdownProps = {
  showDecisionLinks?: boolean;
  decisionLink?: string;
  routingDecision?: SuccessfulDecision | FailedDecision | undefined;
};

const dropDownStyles = css`
  // Keeps hamburger button from slightly overlapping parent div border
  margin-right: 1px;

  ul {
    left: auto;
    right: 0;
  }

  // Hide the initial decision list item (MOTR only)
  // when it's empty while waiting to get the decision link
  li:empty {
    display: none;
  }

  a {
    width: max-content;
    text-align: left;
  }
`;

const InitialDecisionButton = ({ routingDecision }: { routingDecision: SuccessfulDecision | FailedDecision }) => {
  const throwAsyncError = useAsyncError();
  const { accessToken } = useContext(AccessContext);
  const [initialRoutingDecision, setInitialRoutingDecision] = useState<string | undefined>();

  const {
    data: routingRequestData,
    isLoading: routingRequestIsLoading,
    error: routingRequestError,
  } = useGetRoutingRequest({
    accessToken,
    routingRequestUrl: routingDecision?._links?.routingRequest.href,
  });

  useEffect(() => {
    if (!routingRequestData) {
      return;
    }

    const initialDecision = get(
      routingRequestData,
      'routingRequest._embedded.itemRoutingRequest[0]._links.initiatedDecision.href',
    );

    if (initialDecision) {
      setInitialRoutingDecision(initialDecision);
    }
  }, [routingRequestData]);

  if (routingRequestError) {
    // If the user doesn't have permission to see the initial decision, then swallow the error
    if (routingRequestError.message.includes('You do not have permission')) {
      return null;
    }

    // Keep the error message simple in the UI given the space constraints
    throwAsyncError(new Error('There was an error getting the initial decision.'));
    return null;
  }

  if (routingRequestIsLoading || !initialRoutingDecision) {
    return null;
  }

  return (
    <Button href={initialRoutingDecision} variant="anchor" target="_blank" data-testid="initial-decision-link">
      View initial decision
    </Button>
  );
};

export const OverviewDropdown = ({
  showDecisionLinks = true,
  decisionLink,
  routingDecision,
}: OverviewDropdownProps) => {
  return (
    <Dropdown
      title={<IconNavigationMenuVertical weight="fill" />}
      variant="simple"
      css={dropDownStyles}
      data-testid="overview-dropdown"
    >
      {showDecisionLinks && routingDecision && (
        <InlineErrorBoundary>
          <InitialDecisionButton routingDecision={routingDecision} />
        </InlineErrorBoundary>
      )}
      {showDecisionLinks && decisionLink && (
        <Button href={decisionLink} variant="anchor" target="_blank" data-testid="current-decision-link">
          View current decision
        </Button>
      )}
      <Button
        href="https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/14935785539"
        variant="anchor"
        target="_blank"
        data-testid="documentation-link"
      >
        View routing documentation
      </Button>
    </Dropdown>
  );
};
