// brand
export const persimmon = {
  base: '#f0563a',
  darker: '#D63625',
  darkest: '#BC2111',
};

export const cobalt = {
  base: '#3a414c',
  darker: '#212730',
  darkest: '#0D1116',
};

// interaction
export const ocean = {
  base: '#0088A9',
  darker: '#00738E',
  darkest: '#005C75',
};

export const teal = {
  base: '#006F8A',
  darker: '#005B70',
  darkest: '#004456',
};

export const sky = {
  base: '#d4ebf2',
  darker: '#b0d9e6',
  darkest: '#92c8da',
};

export const granite = {
  base: '#4F5D75',
  darker: '#354259',
  darkest: '#212C3F',
};

// link colors
export const linkPrimary = '#41B5DC';
export const linkVisited = '#005C75';
export const linkHover = '#0088A9';

// greyscale
export const coal = '#333943';
export const shale = '#697582';
export const slate = '#8090a2';
export const alloy = '#c4cdd6';
export const platinum = '#e1e6eb';
export const silver = '#eff3f5';
export const horizon = '#edf3f8';
export const white = '#ffffff';

// contextual messaging types
export const success = {
  base: '#35AD96',
  darker: '#22917E',
  darkest: '#127769',
};

export const info = {
  base: '#41B5DC',
  darker: '#2B9DC1',
  darkest: '#188AA8',
};

export const warning = {
  base: '#FFA23F',
  darker: '#E58628',
  darkest: '#CC7712',
};

export const danger = {
  base: '#D24A35',
  darker: '#B73721',
  darkest: '#9E2810',
};

export const disabled = {
  base: '#DFE0E2',
  darker: alloy,
  darkest: 'rgba(58, 65, 76, 0.5)',
};
