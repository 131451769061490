import React from 'react';
import { RoutingUIError } from './RoutingUIError';

export type IState = {
  hasError: boolean;
  error: RoutingUIError | Error | undefined;
};
export type IChildren = {
  children?: React.ReactNode;
};

// React Error Boundary Docs: https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
export default class InlineErrorBoundary extends React.Component<IChildren, IState> {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  constructor(props: IChildren) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error: RoutingUIError | Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: RoutingUIError | Error) {
    this.setState({ error });
  }

  render() {
    if (this.state.hasError && this.state.error) {
      return <p>{this.state.error.message}</p>;
    }
    return this.props.children;
  }
}
