import React, { useState } from 'react';
import { css } from '@emotion/css';
import SelectableCard from './SelectableCard';
import cvar from '../theme/cvar';

export interface SelectableCardsProps {
  /** Whether you can select multiple cards (true) or only one at a time (false)
   * @default false
   */
  multiSelect?: boolean;
  /** The size of the cards
   * @default sm
   */
  size?: 'sm' | 'lg';
  /** The data shown on the selectable cards */
  cards?: Card[];
  /** The spacing between the cards */
  spacing?: string;
}

interface Card {
  label?: string;
  image?: string;
}

const cardsContainer = css`
  display: flex;
  align-items: center;
`;

export default function SelectableCards(props: SelectableCardsProps) {
  const { multiSelect = false, cards = [], spacing = cvar('spacing-8'), size = 'sm' } = props;
  const [selectedCards, setSelectedCards] = useState<Card[]>([]);

  const onCardSelected = (card: Card) => {
    if (multiSelect) {
      setSelectedCards(selectedCards.concat(card));
    } else {
      setSelectedCards([card]);
    }
  };

  const onCardDeselected = (card: Card) => {
    if (multiSelect) {
      setSelectedCards(selectedCards.filter(c => c !== card));
    } else {
      setSelectedCards([]);
    }
  };

  return (
    <div className={cardsContainer}>
      {cards.map(c => (
        <SelectableCard
          key={c.label}
          label={c.label}
          image={c.image}
          cardStyle={{ marginRight: spacing }}
          selected={selectedCards.includes(c)}
          onCardSelected={() => onCardSelected(c)}
          onCardDeselected={() => onCardDeselected(c)}
          size={size}
        />
      ))}
    </div>
  );
}
