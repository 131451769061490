import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import IconCog from '@cimpress-technology/react-streamline-icons/lib/IconCog';
import Modal from '@cimpress/react-components/lib/Modal';
import NavTab from '@cimpress/react-components/lib/NavTab';
import NavTabItem from '@cimpress/react-components/lib/NavTabItem';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import { getSettings, putGlobalSettings } from '../SettingsClient';
import GlobalSettings from './GlobalSettings';
import { languageCodes } from '../constants/languages';
import { withTranslation } from 'react-i18next';
import { getI18nInstance } from '../i18n';

const GLOBAL_TAB = 'GLOBAL';
const APP_TAB = 'APP';

class SettingsModal extends Component {
  static propTypes = {
    lang: PropTypes.string,
    authToken: PropTypes.string.isRequired,
    appSettingsTitle: PropTypes.node,
    appSettingsContent: PropTypes.node,
    canSave: PropTypes.bool,
    onOpen: PropTypes.func,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    supportedLanguages: PropTypes.arrayOf(PropTypes.oneOf(languageCodes)),
  };

  static defaultProps = {
    lang: 'en',
    canSave: true,
    onOpen: () => {},
    onSave: () => {},
    onCancel: () => {},
    supportedLanguages: languageCodes,
  };

  loadGlobalSettings = () => {
    this.setState({ loading: true });
    getSettings(this.props.authToken, this.props.supportedLanguages)
      .then(settings => {
        const { language, regionalSettings, timezone } = settings.global || {};
        this.setState({
          language: language,
          locale: regionalSettings,
          timeZone: timezone,
          loading: false,
        });
      })
      .catch(error => {
        this.setState({ loading: false });
        console.warn(error);
      });
  };

  state = {
    loading: false,
    showModal: false,
    language: undefined,
    locale: undefined,
    timeZone: undefined,
    selectedTab: this.props.appSettingsContent ? APP_TAB : GLOBAL_TAB,
  };

  open = () => {
    this.props.onOpen();
    this.setState({ showModal: true });
    this.loadGlobalSettings();
  };

  close = () => this.setState({ showModal: false });

  selectAppTab = () => this.setState({ selectedTab: APP_TAB });

  selectGlobalTab = () => this.setState({ selectedTab: GLOBAL_TAB });

  onLanguageUpdated = language => this.setState({ language });

  onLocaleUpdated = locale => this.setState({ locale });

  onTimeZoneUpdated = timeZone => this.setState({ timeZone });

  canSave = () => {
    const { language, locale, timeZone } = this.state;
    return Boolean(language && locale && timeZone && this.props.canSave);
  };

  cancel = () => {
    this.props.onCancel();
    this.close();
  };

  save = () => {
    const { language, locale, timeZone } = this.state;
    this.setState({ loading: true });
    putGlobalSettings(this.props.authToken, language, locale, timeZone)
      .then(() => {
        this.setState({ loading: false });
        this.props.onSave();
        this.close();
      })
      .catch(error => {
        this.setState({ loading: false });
        console.warn(error);
      });
  };

  tt = key => {
    const { t, lang } = this.props;
    return t(key, { lng: lang });
  };

  render() {
    const { selectedTab, loading, language, locale, timeZone } = this.state;
    const { lang, appSettingsContent, supportedLanguages } = this.props;

    return (
      <Fragment>
        <MenuPortal />
        <a className="clickable" onClick={this.open}>
          <IconCog />
        </a>
        <Modal
          show={this.state.showModal}
          onRequestHide={this.cancel}
          closeButton
          title={this.tt('modal_title')}
          footer={
            <Fragment>
              <button className="btn btn-default" onClick={this.cancel}>
                {this.tt('modal_button_cancel_title')}
              </button>
              <button className="btn btn-primary" onClick={this.save} disabled={loading || !this.canSave()}>
                {this.tt('modal_button_save_title')}
              </button>
            </Fragment>
          }>
          <div>
            {appSettingsContent ? (
              <NavTab>
                <NavTabItem active={selectedTab === APP_TAB}>
                  <button style={{ marginLeft: 0, paddingLeft: 0 }} onClick={this.selectAppTab}>
                    {this.props.appSettingsTitle || this.tt('app_settings_title')}
                  </button>
                </NavTabItem>
                <NavTabItem active={selectedTab === GLOBAL_TAB}>
                  <button onClick={this.selectGlobalTab}>{this.tt('global_settings_title')}</button>
                </NavTabItem>
              </NavTab>
            ) : null}
            {loading ? (
              <Spinner className="flex-center" />
            ) : selectedTab === APP_TAB ? (
              appSettingsContent
            ) : (
              <GlobalSettings
                lang={lang}
                language={language}
                onLanguageUpdated={this.onLanguageUpdated}
                locale={locale}
                onLocaleUpdated={this.onLocaleUpdated}
                timeZone={timeZone}
                onTimeZoneUpdated={this.onTimeZoneUpdated}
                supportedLanguages={supportedLanguages}
              />
            )}
          </div>
        </Modal>
      </Fragment>
    );
  }
}

function MenuPortal() {
  const menu = (
    <div
      id="settings-modal"
      style={{
        zIndex: 9999,
        position: 'absolute',
        top: '0px',
      }}
      tabIndex="-1"
    />
  );

  return ReactDOM.createPortal(menu, document.body);
}

const SettingsModalWithTranslation = withTranslation('react-platform-settings')(SettingsModal);
export default props => <SettingsModalWithTranslation i18n={getI18nInstance()} {...props} />;
