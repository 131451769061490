/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, createContext } from 'react';
import { get } from 'lodash';
import { useGetDecisionFromUrl } from '../hooks/useOrca';
import { AccessContext, DecisionViewerProps } from './DecisionViewer';
import { FulfillmentOptions } from './FulfillmentOptions';
import { useDecorateOptions } from '../hooks/useDecorateOptions';
import { RoutingRequestOverview } from './RoutingRequestOverview';
import { useAsyncError } from '../hooks/useAsyncError';
import { useGetItemByItemId } from '../hooks/useItemService';
import { SpinnerWrapper } from './SpinnerWrapper';
import { FailedDecision, isFailedDecision, SuccessfulDecision } from '../types';
import { NoOptions } from './NoData/NoOptions';
import { getRoutingRequestUrlFromItem, isItemSuspendedInRouting } from '../helpers/item';
import { RetryAlert } from './Retry/RetryAlert';

const decisionViewerStyles = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 1rem;
`;

export type RoutingDecisionContextValues = {
  routingProblem: FailedDecision['problem'] | undefined;
  routingRequestUrl: string | undefined;
  orderedSkuCode: string | undefined;
};

export const RoutingDecisionContext = createContext<RoutingDecisionContextValues>({
  routingProblem: undefined,
  routingRequestUrl: undefined,
  orderedSkuCode: undefined,
});

export const DecisionViewerBody = ({
  decisionLink,
  showDecisionLinks,
  item,
  decisionJson,
  halfWeight,
}: DecisionViewerProps) => {
  const throwAsyncError = useAsyncError();
  const { environment, accessToken } = useContext(AccessContext);

  const urlDecision = useGetDecisionFromUrl({
    accessToken,
    decisionLink,
    enabled: !decisionJson,
  });

  const isRoutingDecisionLoading = urlDecision.isLoading;
  const routingDecisionError = urlDecision.error;
  let routingDecision = urlDecision.data;

  const {
    isLoading: isItemDataLoading,
    data: itemData,
    error: itemError,
  } = useGetItemByItemId({
    environment,
    accessToken,
    decisionLink,
    itemId: routingDecision?.itemId,
    enabled: !item && !!routingDecision && !decisionJson,
  });

  if (decisionJson) {
    routingDecision = JSON.parse(decisionJson) as FailedDecision | SuccessfulDecision;
  }

  const decoratedOptions = useDecorateOptions({
    environment,
    accessToken,
    decision: routingDecision,
    options: routingDecision?.consideredOptions,
  });

  // Network error getting routing decision
  if (routingDecisionError) {
    throwAsyncError(routingDecisionError, {
      item, // May or may not be defined depending on how it's called
      decisionLink,
      showDecisionLinks, // Should have the decision link, so we could still show it
    });
    return null;
  }

  // Network error getting item
  if (itemError) {
    throwAsyncError(itemError);
    /* istanbul ignore next - not sure how to test this */
    return null;
  }

  const isLoading = isRoutingDecisionLoading || isItemDataLoading;

  if (isLoading) {
    return <SpinnerWrapper />;
  }

  const routingProblem =
    routingDecision && isFailedDecision(routingDecision) ? get(routingDecision, ['problem']) : undefined;
  const routingRequestUrl = getRoutingRequestUrlFromItem(itemData);
  const orderedSkuCode = get(itemData, ['orderedSkuCode']);

  if (decoratedOptions.length === 0) {
    return (
      <div css={decisionViewerStyles} data-testid="decision-viewer-body-with-errors">
        <RetryAlert />
        <RoutingRequestOverview
          item={item ?? itemData}
          routingDecision={routingDecision}
          decisionLink={decisionLink}
          showDecisionLinks={showDecisionLinks}
        />
        <RoutingDecisionContext.Provider value={{ routingProblem, routingRequestUrl, orderedSkuCode }}>
          <NoOptions options={decoratedOptions} />
        </RoutingDecisionContext.Provider>
      </div>
    );
  }

  return (
    <div css={decisionViewerStyles}>
      <RetryAlert />
      <RoutingRequestOverview
        item={item ?? itemData}
        routingDecision={routingDecision}
        decisionLink={decisionLink}
        showDecisionLinks={showDecisionLinks}
        halfWeight={halfWeight}
      />
      <FulfillmentOptions
        options={decoratedOptions}
        isItemSuspendedInRouting={isItemSuspendedInRouting(item || itemData)}
        halfWeight={halfWeight}
      />
    </div>
  );
};
