import React from 'react';
import { Table } from '../Table/index';
import { PropInfo } from './PropInfo';

export interface PropsTableProps<T> {
  propInfos: PropInfo<T>[];
}

export function PropsTable<T>({ propInfos }: PropsTableProps<T>) {
  return (
    <div>
      <h3>Props</h3>
      <div>
        <Table
          columns={[
            { Header: 'Name', accessor: 'name' },
            { Header: 'Type', accessor: 'type' },
            { Header: 'Default', accessor: 'default' },
            { Header: 'Description', accessor: 'description' },
          ]}
          data={propInfos}
        />
      </div>
    </div>
  );
}
