import React, { useState, useEffect, CSSProperties, ReactNode, Component } from 'react';
import { css, cx } from '@emotion/css';

import TabGroup from './TabGroup';
import Tab from './Tab';
import cvar from '../theme/cvar';
import { PublicComponentProps } from '../types';

type TabType = {
  id: string; // The unique identifier of the tab
  title: ReactNode; // The top level title of the tab
  description?: ReactNode; // Optional description shown below the title
  icon?: typeof Component; // The icon from the @cimpress-technology/react-streamline-icons library
  body?: ReactNode; // The content to display when the tab is active
};

type TabGroupType = {
  label: string; // The title of the group of tabs
  tabIds: string[]; // Array of tab ids to be included in this grouping
};

const generateTabMap = (tabs: TabType[]): { [key: string]: TabType } =>
  tabs.reduce((tabAcc, tab) => ({ ...tabAcc, [tab.id]: tab }), {});

const validateIcons = (tabs: TabType[]): boolean => {
  const tabsWithIcons = tabs.filter((tab: TabType) => Boolean(tab.icon));
  const isValid = !tabsWithIcons.length || tabsWithIcons.length === tabs.length;

  if (!isValid) {
    console.warn('TabMenu: All tabs must have icons for them to render. Ignoring icons for all tabs...');
  }

  return isValid;
};

const validateDescriptions = (tabs: TabType[]): boolean => {
  const tabsWithDescriptions = tabs.filter((tab: TabType) => Boolean(tab.description));
  const isValid = !tabsWithDescriptions.length || tabsWithDescriptions.length === tabs.length;

  if (!isValid) {
    console.warn('TabMenu: All tabs must have descriptions for them to render. Ignoring descriptions for all tabs...');
  }

  return isValid;
};

const tabMenuStyle = css`
  display: flex;
`;

const tabsBaseStyle = css`
  display: flex;
  flex-direction: column;
  min-width: 250px;
`;

const activeTabBodyStyle = css`
  padding-left: ${cvar('spacing-24')};
  flex-grow: 3;
`;

export interface TabMenuProps extends PublicComponentProps {
  /**
   * If provided, the tab menu will mark the matching tab as active and its corresponding
   * body will be shown.  If not provided, the tab menu will default to the first tab.
   */
  activeTabId?: string;

  /**
   * An array of tabs to display
   */
  tabs: TabType[];

  /**
   * An array of tab groups for organization
   */
  tabGroups?: TabGroupType[];

  /**
   * If provided, a callback function executed when a user clicks on a specific tab
   */
  onTabClick?: (e: any, tabId: string) => void;

  /**
   * Inline styles applied to the body of the Active Tab.
   */
  bodyStyle?: CSSProperties;

  /**
   * Inline styles applied to the tab container of the Tab Menu.
   */
  tabStyle?: CSSProperties;
}

export const TabMenu = ({
  className = '',
  activeTabId,
  tabs,
  tabGroups = [],
  onTabClick = () => {},
  bodyStyle = {},
  tabStyle = {},
  style = {},
  ...rest
}: TabMenuProps) => {
  const [tabMap, setTabMap] = useState<{ [key: string]: TabType }>(generateTabMap(tabs));
  const [showIcons, setShowIcons] = useState<boolean>(validateIcons(tabs));
  const [showDescriptions, setShowDescriptions] = useState<boolean>(validateDescriptions(tabs));

  useEffect(() => {
    // Create a lookup mapping of tabs by id to optimize
    setTabMap(generateTabMap(tabs));
    setShowIcons(validateIcons(tabs));
    setShowDescriptions(validateDescriptions(tabs));
  }, [tabs]);

  if (!tabs || !tabs.length) {
    return null;
  }

  let activeTab = tabs[0];

  if (activeTabId) {
    activeTab = tabMap[activeTabId] || activeTab;
  }

  return (
    <div className={cx('crc-tab-menu', tabMenuStyle, className)} style={style} {...rest}>
      <div className={tabsBaseStyle} style={tabStyle}>
        {tabGroups.length > 1
          ? tabGroups.map(({ label, tabIds = [] }) => (
              <TabGroup key={`${label}-${tabIds.join('-')}`} label={label}>
                {tabIds.map(tabId => {
                  const { id, title, description, icon } = tabMap[tabId];
                  return (
                    <Tab
                      key={id}
                      id={id}
                      title={title}
                      onTabClick={onTabClick}
                      isActive={activeTab.id === id}
                      {...(showIcons ? { icon } : {})}
                      {...(showDescriptions ? { description } : {})}
                    />
                  );
                })}
              </TabGroup>
            ))
          : tabs.map(({ id, title, description, icon }) => (
              <Tab
                key={id}
                id={id}
                title={title}
                onTabClick={onTabClick}
                isActive={activeTab.id === id}
                {...(showIcons ? { icon } : {})}
                {...(showDescriptions ? { description } : {})}
              />
            ))}
      </div>
      <div className={activeTabBodyStyle} style={bodyStyle}>
        {activeTab && activeTab.body}
      </div>
    </div>
  );
};
