/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Environment } from '../../types';
import { useContext } from 'react';
import { AccessContext } from '../DecisionViewer';
import { RoutingDecisionContext } from '../DecisionViewerBody';

const genericNoOptionsWrapperStyles = css`
  h3 {
    font-size: 1.6rem;
    font-weight: 600;
    margin: 0 0 1.6rem;
  }

  p {
    margin: 1.6rem 0;
  }

  p:last-of-type {
    margin: 1.6rem 0 0;
  }

  ul {
    margin-top: 1.6rem;
  }
`;

const genericNoOptionsListStyles = css`
  margin-left: 3.2rem;
`;

const getActionUrls = ({
  environment,
  orderedSkuCode,
}: {
  environment: Environment;
  orderedSkuCode: string | undefined;
}) => {
  if (!orderedSkuCode) {
    return undefined;
  }

  return {
    fcs:
      environment === 'prd'
        ? 'https://fulfillmentconfigurations.cimpress.io/'
        : 'https://int.fulfillmentconfigurations.cimpress.io/',
    equivalency: `https://noms.lynx.cimpress.io/equivalency/${orderedSkuCode}`,
    productManager: `https://productmanager-v2.products.cimpress.io/product-details/${orderedSkuCode}`,
  };
};

export const GenericNoOptions = () => {
  const { environment } = useContext(AccessContext);
  const { orderedSkuCode } = useContext(RoutingDecisionContext);

  const actionUrls = getActionUrls({ environment, orderedSkuCode });

  return (
    <div css={genericNoOptionsWrapperStyles} data-testid="generic-no-options">
      <h3>No Options Available</h3>
      {actionUrls ? (
        <div data-testid="action-urls">
          <p>
            There are no options available for routing this item. Please setup options through one of the following
            approaches:
          </p>
          <ul css={genericNoOptionsListStyles}>
            <li>
              <a target="_blank" rel="noreferrer" href={actionUrls.equivalency}>
                Add Product Equivalency (Legacy)
              </a>
            </li>
            <li>
              <a target="_blank" rel="noreferrer" href={actionUrls.fcs}>
                Associate this product to a Fulfillment Configuration
              </a>
            </li>
            <li>
              <a target="_blank" rel="noreferrer" href={actionUrls.productManager}>
                Add a Fulfiller to the Fulfilled Product (Legacy)
              </a>
            </li>
          </ul>
        </div>
      ) : (
        <p>There are no options available for routing this item.</p>
      )}
      <p>
        For help configuring options or if you still see this message after configuring options, please contact the
        <a href="https://cimpress.slack.com/archives/C5XG09F6J" target="_blank" rel="noreferrer">
          #mcp-support-pim
        </a>{' '}
        channel on Slack.
      </p>
    </div>
  );
};
