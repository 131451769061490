import React from 'react';
import debounce from 'lodash.debounce';

import { css, cx } from '@emotion/css';
import { useMemoizedId } from '../utils';
import { RadioIcon } from './RadioIcon';
import cvar from '../theme/cvar';
import { PublicComponentProps } from '../types';
import { useRadioGroupContext } from './RadioGroup';

export const RADIO_NAME = 'CimRadio';

const disabledStyle = css`
  cursor: not-allowed;
  color: ${cvar('color-icon-disabled')};
`;

const radioLabel = css`
  display: flex;
  align-items: center;

  &:has(input:checked) {
    --crc-radio-icon-display: block;
  }
`;

const radioIcon = css`
  margin-right: ${cvar('spacing-8')};
`;

const radioInput = css`
  display: none;
`;

export interface RadioProps extends PublicComponentProps {
  id?: string;
  name?: string;
  checked?: boolean;
  label?: React.ReactNode;
  value: string | number | string[] | undefined;
  disabled?: boolean;
  backgroundColor?: string;
  onCheck?: (e: React.ChangeEvent<HTMLInputElement>, debouncedValue: string | number | string[] | undefined) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const Radio = (props: RadioProps) => {
  const {
    id,
    name,
    checked,
    label,
    value,
    disabled = false,
    backgroundColor,
    className = '',
    onCheck,
    onBlur,
    onFocus,
    style,
    ...rest
  } = props;

  const {
    onChange: radioGroupOnChange,
    name: radioGroupName,
    selected: radioGroupSelected,
  } = useRadioGroupContext() ?? {};
  // `undefined` in case of uncontrolled radio
  const isChecked = checked ?? (radioGroupSelected != null ? radioGroupSelected === value : undefined);

  const debouncedChange = debounce(
    (e: React.ChangeEvent<HTMLInputElement>, debouncedValue: string | number | string[] | undefined) => {
      onCheck?.(e, debouncedValue);
      radioGroupOnChange?.(e, debouncedValue);
    },
    200,
    {
      leading: true,
      trailing: false,
    },
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    debouncedChange(e, value);
  };

  const inputId = useMemoizedId({ id });

  return (
    <label
      className={cx('crc-radio', className, radioLabel, { [disabledStyle]: disabled })}
      htmlFor={inputId}
      style={style}
    >
      <RadioIcon
        className={radioIcon}
        color={disabled ? cvar('color-icon-disabled') : cvar('color-button-primary')}
        backgroundColor={backgroundColor}
      />
      <input
        id={inputId}
        className={radioInput}
        name={name ?? radioGroupName}
        type="radio"
        disabled={disabled}
        checked={isChecked}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={handleChange}
        {...rest}
      />
      {label}
    </label>
  );
};

Radio.displayName = RADIO_NAME;
