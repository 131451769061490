// these are taken from POM
const locales = ['x-ISO8601'].concat(
  [
    'en',
    'en-GB',
    'en-AU',
    'en-CA',
    'de',
    'de-CH',
    'de-AT',
    'fr',
    'fr-CA',
    'nl',
    'nl-BE',
    'pl',
    'sk',
    'it',
    'es',
    'es-US',
    'pt',
    'pt-BR',
    'ja',
    'zh-CN',
  ].sort()
);

export default locales.map(locale => ({ label: locale === 'x-ISO8601' ? 'ISO8601' : locale, value: locale }));
