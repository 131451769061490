import { css } from '@emotion/css';
import React from 'react';
import { slate, silver } from '../colors';

const divCss = css`
  transform: translate3d(0, 0, 0);
  left: 0;
  transition: transform 0.6s ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000px;
`;

const CarouselFallback = () => (
  <div
    className={divCss}
    style={{
      backgroundColor: silver,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }}
  >
    <p className="text-center" style={{ color: slate, marginBottom: 0 }}>
      No image available
    </p>
  </div>
);

export default CarouselFallback;
