import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import * as colors from './colors';

function createUniqueId(label?: string): string {
  const uniqueId = label ? `${label}-${uuid()}` : uuid();
  return uniqueId.replace(/[^A-Za-z0-9-]/gi, '');
}

export const mapBsStyleToColor = (
  bsStyle: keyof typeof colors,
  { shade = 'base', fallback = colors.coal }: { shade?: 'base' | 'darker' | 'darkest'; fallback?: string } = {},
): string => {
  let result = colors[bsStyle];

  if (typeof result === 'object' && result[shade]) {
    result = result[shade];
  } else {
    result = fallback;
  }

  return result;
};

type UseMemoizedIdParams = {
  /** If you already have a unique id that you want memoized */
  id?: string;
  /** Generate a unique id with the following label */
  label?: string;
};

export const useMemoizedId = ({ id, label }: UseMemoizedIdParams) =>
  useMemo(() => id || createUniqueId(label), [id, label]);

export function deprecatedProp(validator: (...args: any[]) => any, suggestion: string = '') {
  return function deprecated(props: Record<any, any>, propName: string, ...rest: any[]) {
    if (props[propName] != null) {
      console.warn(`${propName} is deprecated. ${suggestion}`);
    }
    return validator(props, propName, ...rest);
  };
}

export function isBrowser() {
  return typeof window !== 'undefined';
}
