/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Accordion } from '@cimpress/react-components';
import ItemRoutingDecisionViewer from '../ItemRoutingDecisionViewer/ItemRoutingDecisionViewer';
import { Environment, Item } from '../../types';
import { isItemSuspendedInRouting } from '../../helpers/item';
import { ItemsAccordionHeader, RetryAllButton } from './ItemsAccordionHeader';

const accordionHeaderStyles = {
  backgroundColor: '#ffffff',
};

const accordionStyles = css`
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  &.accordion-open {
    height: 100%;
    padding-bottom: 1.6rem;
  }

  &.accordion-open > div:nth-of-type(2) > div:has(.accordion-open) {
    height: 100%;
  }
`;

const bodyOnlyStyles = css`
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;

  .retry-all-button {
    justify-self: center;
    align-self: flex-end;
    margin-bottom: 1.6rem;
  }
`;

const listStyles = css`
  list-style-type: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  padding-left: 0;

  li:last-of-type .item-accordion {
    margin-bottom: 0;
  }
`;

type RoutingDecisionListBodyProps = {
  environment: Environment;
  accessToken: string;
  showBodyOnly?: boolean;
  showDecisionLinks?: boolean;
  items: Item[];
};

const ItemAccordionHeader = ({ item }: { item: Item }) => {
  const headerStyles = css`
    line-height: 19px;
    margin-bottom: 0;

    span {
      font-weight: 600;
    }
  `;

  return (
    <>
      <p css={headerStyles}>
        <span>{item.orderedSkuCode}</span>
        <br />
        {item.merchantProductName}
      </p>
    </>
  );
};

export const RoutingDecisionListBody = ({
  items,
  showBodyOnly = false,
  environment,
  accessToken,
  ...rest
}: RoutingDecisionListBodyProps) => {
  const suspendedItems = items.filter((item) => isItemSuspendedInRouting(item));
  const hasSuspendedItems = !!suspendedItems.length;

  if (showBodyOnly) {
    return (
      <div css={bodyOnlyStyles} className="body-styles-only" data-testid="routing-decision-list-body-only">
        {hasSuspendedItems && <RetryAllButton environment={environment} accessToken={accessToken} items={items} />}
        <ul css={listStyles}>
          {items.map((item) => (
            <li key={item.itemId}>
              <Accordion
                title={<ItemAccordionHeader item={item} />}
                className="item-accordion"
                headerStyle={accordionHeaderStyles}
              >
                <ItemRoutingDecisionViewer
                  item={item}
                  isModal={false}
                  showModal={false}
                  environment={environment}
                  accessToken={accessToken}
                  {...rest}
                />
              </Accordion>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <Accordion
      title={
        <ItemsAccordionHeader
          environment={environment}
          accessToken={accessToken}
          hasSuspendedItems={hasSuspendedItems}
          items={items}
        />
      }
      css={accordionStyles}
      headerStyle={accordionHeaderStyles}
      bodyStyle={{ paddingTop: 0, paddingBottom: 0, overflowY: 'scroll', height: '100%' }}
      className="outer-accordion"
      data-testid="routing-decision-list-body-accordion"
    >
      <ul css={listStyles}>
        {items.map((item) => (
          <li key={item.itemId}>
            <Accordion
              title={<ItemAccordionHeader item={item} />}
              className="item-accordion"
              headerStyle={accordionHeaderStyles}
            >
              <ItemRoutingDecisionViewer
                item={item}
                isModal={false}
                showModal={false}
                environment={environment}
                accessToken={accessToken}
                {...rest}
              />
            </Accordion>
          </li>
        ))}
      </ul>
    </Accordion>
  );
};
