import { fetchWithAuth } from './fetchWithAuth';

type VersionsResponse = {
  _links: {
    fulfillmentConfiguration: {
      name: string;
    };
  };
  versionId: string;
  versionNumber: number;
};

export type GetFulfillmentConfigurationLinkForOptionParams = {
  versionUrl: string | undefined;
  accessToken: string;
};

export type getFulfillmentConfigurationLinkForOptionReturn = Promise<{
  fulfillmentConfigurationVersion: number | undefined;
  fulfillmentConfigurationUrl: string | undefined;
}>;

export const getFulfillmentConfigurationLinkForOption = async ({
  versionUrl,
  accessToken,
}: GetFulfillmentConfigurationLinkForOptionParams): getFulfillmentConfigurationLinkForOptionReturn => {
  if (!versionUrl) {
    return { fulfillmentConfigurationVersion: undefined, fulfillmentConfigurationUrl: undefined };
  }

  const response = await fetchWithAuth({ path: versionUrl, accessToken });

  if (response.ok) {
    const versionsData = (await response.json()) as VersionsResponse;
    if (versionsData && versionsData._links?.fulfillmentConfiguration?.name && versionsData.versionId) {
      // The fulfillment configuration version on the routing decision only exists in prod, so no need for int links
      return {
        fulfillmentConfigurationVersion: versionsData.versionNumber,
        fulfillmentConfigurationUrl: `https://fulfillmentconfigurations.cimpress.io/fulfillmentconfiguration/${versionsData._links.fulfillmentConfiguration.name}/versions/${versionsData?.versionId}`,
      };
    }
  }

  // We don't need to throw an error if this doesn't exist because it's a progressive enhancement feature
  // (i.e., great if we have it, but we'll just not show it otherwise)
  return { fulfillmentConfigurationVersion: undefined, fulfillmentConfigurationUrl: undefined };
};
