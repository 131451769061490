import { css } from '@emotion/css';
import cvar from '../theme/cvar';

export const calendarIconStyle = css({
  top: '24px',
  position: 'absolute',
  transform: ' translateY(-50%)',
  right: cvar('spacing-16'),
  pointerEvents: 'none',
  fill: cvar('color-icon-interactive'),
});

export const clearIconStyle = css`
  color: ${cvar('color-button-primary')};
  position: absolute;
  top: 26px;
  transform: translateY(-50%);
  right: 48px;
  z-index: 1;
  cursor: pointer;
`;

export const formGroup = css`
  position: relative;
  margin-bottom: ${cvar('spacing-16')};

  input {
    box-shadow: none;
    padding: ${cvar('spacing-16')};
    transition: all 0.2s ease-out;
    width: 100%;
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: ${cvar('color-button-primary-active')};
    }
  }
  label {
    color: ${cvar('color-text-label')};
    font-weight: normal;
    position: absolute;
    left: ${cvar('spacing-16')};
    top: ${cvar('spacing-16')};
    line-height: 14px;
    margin-bottom: 0;
    transition: all 0.2s ease-out;
    z-index: 5;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
  }
`;

export const formGroupActive = css`
  input {
    padding: ${cvar('spacing-24')} ${cvar('spacing-16')} ${cvar('spacing-8')};
    width: 100%;
  }
  label {
    top: ${cvar('spacing-4')};
    font-size: 12px;
  }
`;

export const controlLabel = css`
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
`;
export const datetime = css`
  position: relative;
  display: inline-block;

  & > i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 27px;
    pointer-events: none;
    width: 100%;
  }

  &.datepicker--disabled {
    svg {
      fill: ${cvar('color-icon-disabled')};
    }
  }
`;

export const datePicker = css`
  width: 100%;

  .rdtPicker {
    width: 300px;
  }
  .rdtPicker table {
    border: none;
    border-collapse: separate;
    display: table;
    border-spacing: 3px;
    width: 100%;
    margin: 0;
  }
  .rdtPicker td {
    border: none;
    border-radius: 2px;
    min-width: 38px;
    height: 38px;
  }
  .rdtPicker th {
    min-width: 38px;
    height: 38px;
    border: none;
  }

  .rdtPicker tr {
    background: none;
    border: none;
  }

  /* These look funky and should maybe be fixed */
  .rdtPicker td.rdtMonth {
    height: 67px;
    width: 67px;
  }
  .rdtPicker td.rdtYear {
    height: 67px;
    width: 67px;
  }
  .rdtDay {
    color: ${cvar('color-button-primary')};
    min-width: 35px;
    height: 35px;
  }
  .rdtPicker thead tr {
    border: none;
  }
  .rdtPicker thead tr:first-child {
    color: ${cvar('color-button-primary')};
  }
  .rdtPicker thead tr:first-child th:hover {
    background: none;
    color: ${cvar('color-button-primary-hover')};
  }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: ${cvar('color-background-info')};
    text-shadow: none;
  }
  .rdtPicker {
    top: 50px;
  }
  .rdt th {
    vertical-align: middle;
  }
`;

export const timeToggle = css`
  .form-control {
    width: 100%;
    height: 48px;
    font-size: 14px;
    line-height: 1.286;
    background-image: none;
    border: 1px solid ${cvar('color-border-default')};
    border-radius: 2px;
    transition: border-color ease-in-out 0.15s;
  }
  .rdtPicker {
    display: none;
    position: absolute;
    width: 250px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: ${cvar('color-background')};
    box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
    border: 1px solid ${cvar('color-interactive')};
  }
  .rdtPicker td {
    text-align: center;
    height: 28px;
  }
  .rdtPicker td.rdtToday {
    font-weight: 700;
  }
  .rdtPicker td.rdtOld {
    color: ${cvar('color-text-label')};
  }
  .rdtPicker .rdtTimeToggle {
    color: ${cvar('color-interactive')};
    border: 1px solid ${cvar('color-interactive')};
    text-align: center;
  }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: ${cvar('color-interactive-hover')};
    color: ${cvar('color-background')};
  }
  .rdt {
    position: relative;
  }
  .rdtPicker {
    display: none;
    position: absolute;
    width: 250px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: ${cvar('color-background')};
    box-shadow: 0 1px 3px ${cvar('color-text-default')};
    border: 1px solid ${cvar('color-interactive')};
  }
  .rdtOpen .rdtPicker {
    display: block;
  }
  .rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
  }

  .rdtPicker td,
  .rdtPicker th {
    padding: 0;
    text-align: center;
    height: 28px;
  }
  .rdtPicker td {
    cursor: pointer;
  }
  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: ${cvar('color-hover')};
    cursor: pointer;
  }
  .rdtPicker td.rdtOld,
  .rdtPicker td.rdtNew {
    color: ${cvar('color-text-label')};
  }
  .rdtPicker td.rdtToday {
    position: relative;
  }
  .rdtPicker td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 8px solid transparent;
    border-bottom: 8px solid ${cvar('color-interactive')};
    border-top-color: transparent;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  .rdtPicker .row {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
  }
  .rdtPicker th.rdtSwitch {
    width: 100px;
  }
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
  }
  .rdtPicker thead tr:first-child th {
    cursor: pointer;
  }
  .rdtPicker thead tr:first-child th:hover {
    background: ${cvar('color-hover')};
  }

  .rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
  }
  .rdtPicker button:hover {
    background-color: ${cvar('color-hover')};
  }

  .rdtPicker thead button {
    width: 100%;
    height: 100%;
  }

  td.rdtMonth,
  td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
  }
  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: ${cvar('color-hover')};
  }

  .rdtCounters {
    color: ${cvar('color-interactive')};
    display: inline-block;
  }

  .rdtCounters > div {
    float: left;
  }

  .rdtCounter {
    width: 40px;
    height: 100px;
  }

  .rdtCounterSeparator {
    line-height: 100px;
  }

  .rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;
    user-select: none;
  }
  .rdtCounter .rdtBtn:hover {
    background: ${cvar('color-hover')};
  }

  .rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
  }

  .rdtCount {
    color: ${cvar('color-text-label')};
  }

  .rdtTime td {
    cursor: default;
  }
  .rdtPicker thead tr:not(:first-child) th {
    color: ${cvar('color-text-label')};
    font-weight: 700;
    font-size: 10px;
  }
  .rdtNext span,
  .rdtPrev span {
    display: block;
    user-select: none;
  }
`;
