import { fetchWithAuth } from './fetchWithAuth';

export type McpProduct = {
  referenceId: string;
  fulfillerId: string;
  name: string;
  description: string;
};

const BASE_URL = 'https://introduction.products.cimpress.io';

export type GetMcpProductByMcpSkuParams = {
  accessToken: string;
  mcpSku: string;
};

export const getMcpProductByMcpSku = async ({
  accessToken,
  mcpSku,
}: GetMcpProductByMcpSkuParams): Promise<McpProduct> => {
  const path = `${BASE_URL}/v1/products/${mcpSku}`;
  const response = await fetchWithAuth({ path, accessToken });

  if (!response.ok) {
    throw new Error(
      `Error retrieving mcpSku ${mcpSku} from Product Introduction Service. Received status code ${response.status}.`,
    );
  }

  return response.json() as Promise<McpProduct>;
};
