import { Environment, FulfillerIdentityResponse } from '../types';
import { fetchWithAuth } from './fetchWithAuth';

const INT_BASE_URL = 'https://fulfilleridentity.staging.trdlnk.cimpress.io';
const PROD_BASE_URL = 'https://fulfilleridentity.trdlnk.cimpress.io';

const getBaseUrl = (environment: Environment): string => {
  return environment === 'int' ? INT_BASE_URL : PROD_BASE_URL;
};

export type GetFulfillerIdentityByFulfillerIdParams = {
  environment: Environment;
  accessToken: string;
  fulfillerId: string;
};

export const getFulfillerIdentityByFulfillerId = async ({
  environment,
  accessToken,
  fulfillerId,
}: GetFulfillerIdentityByFulfillerIdParams): Promise<{
  fulfillerIdentity: Promise<FulfillerIdentityResponse> | undefined;
}> => {
  const path = `${getBaseUrl(environment)}/v1/fulfillers/${fulfillerId}`;
  const response = await fetchWithAuth({ path, accessToken });

  if (!response.ok) {
    // If we can't access the fulfiller identity service,
    // gracefully return instead of throwing an error since we can default to showing
    // the fulfiller ID (instead of the name information we get from the Fulfiller Identity Service)
    return { fulfillerIdentity: undefined };
  }

  return { fulfillerIdentity: (await response.json()) as Promise<FulfillerIdentityResponse> };
};
