
import React from 'react';
import PropTypes from 'prop-types';
import TreeSelect from 'rc-tree-select';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { injectIntl, IntlProvider } from 'react-intl';
import ErrorBoundary from '@products-react-components/utils/lib/ErrorBoundary';
import 'rc-tree-select/assets/index.css';

import './treeSelect.css';
import messages from './messages';


const mapCategory = (category, disableNonSchemaCategories, pathList) => {
  const path = pathList.concat([category.name]);

  return {
    disabled: disableNonSchemaCategories && Boolean(!category.schemaTypeId),
    title: category.name,
    key: category.categoryId,
    value: category.categoryId,
    path,
    // eslint-disable-next-line no-use-before-define
    children: mapCategories(category.children, disableNonSchemaCategories, path),
  };
};
function mapCategories(categories, disableNonSchemaCategories, pathList = []) {
  return categories.map(category => mapCategory(category, disableNonSchemaCategories, pathList));
}

const intlShape = { formatMessage: PropTypes.func.isRequired };

class IntlTreeSelector extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    selectTree: PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string,
      value: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    intl: PropTypes.shape(intlShape).isRequired,
  };

  static defaultProps = {
    multiple: false,
    value: null,
    selectTree: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: props.disabled,
    };
  }

  componentDidMount =() => {
    const tree = this.props.selectTree;
    if (tree) {
      this.setTreeData(tree);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (!isEqual(prevProps.selectTree, this.props.selectTree)) {
      this.setTreeData(this.props.selectTree);
    }
  };

  onChange = (value, title, rest) => {
    const categoryPath = get(rest, 'triggerNode.props.path', []);
    if (this.props.onChange) {
      this.props.onChange(value, title, categoryPath, rest);
    }
  };

  setTreeData = () => {
    this.setState({ loading: true });
    const parsedTree = this.parseTree(this.props.selectTree);
    this.setState({ categoryTree: parsedTree.children, loading: false });
  }

  parseTree = tree => ({
    title: tree.name,
    key: tree.categoryId,
    value: tree.categoryId,
    children: mapCategories(tree.children, !this.props.multiple),
  });

  render() {
    const {
      multiple,
      onChange,
      value,
      disabled,
      intl: { formatMessage },
      ...rest
    } = this.props;
    return (
      <React.Fragment>
        <TreeSelect
          multiple={multiple}
          disabled={this.state.loading}
          placeholder={multiple ? formatMessage(messages.selectCategories) : formatMessage(messages.selectACategory)}
          showSearch
          allowClear={multiple}
          value={value}
          treeData={this.state.categoryTree}
          treeNodeFilterProp="title"
          treeLine
          onChange={this.onChange}
          maxTagCount={5}
          maxTagPlaceholder={<span>...</span>}
          {...rest}
        />
      </React.Fragment>
    );
  }
}

const HOCTreeSelector = injectIntl(IntlTreeSelector);

// eslint-disable-next-line react/no-multi-comp
function TreeSelector(props) {
  return (
    <ErrorBoundary>
      <IntlProvider language="en" locale="en">
        <HOCTreeSelector {...props} />
      </IntlProvider>
    </ErrorBoundary>
  );
}
TreeSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  selectTree: PropTypes.shape({
    title: PropTypes.string,
    key: PropTypes.string,
    value: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

TreeSelector.defaultProps = {
  multiple: false,
  value: null,
  selectTree: null,
};


export default TreeSelector;
