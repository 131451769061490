import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import { DisplayModal, DisplayModalProps } from './Modal';

export interface ModalProps extends DisplayModalProps {
  show: boolean;
}

export const Modal = ({ show, ...rest }: ModalProps) => {
  useEffect(() => {
    if (!show) {
      document.body.className = document.body.className.replace(/ ?modal-open/, '');
    } else {
      document.body.className += document.body.className.length ? ' modal-open' : 'modal-open';
    }

    // clean up any open modal classes from the body before unmounting the component
    return () => {
      document.body.className = document.body.className.replace(/ ?modal-open/, '');
    };
  }, [show]);

  const nodeRef = React.useRef(null);
  return (
    <CSSTransition classNames="modal" nodeRef={nodeRef} in={show} timeout={300} exit={false} unmountOnExit>
      <DisplayModal {...rest} />
    </CSSTransition>
  );
};
