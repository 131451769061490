import React, { useEffect, useState, CSSProperties, ReactNode } from 'react';
import DateTimeField, { DatetimepickerProps } from 'react-datetime';
import { css, cx } from '@emotion/css';
import moment, { Moment } from 'moment';
import { useMemoizedId } from '../utils';
import { persimmon } from '../colors';
import * as styles from './styles';
import { Calendar } from '../internal/Calendar';
import { BlockButton, Close } from '../internal';
import { useFeatureFlags } from '../FeatureFlags';

export interface DatePickerProps extends DatetimepickerProps {
  /**
   * Indicates whether the input box is readonly.
   */
  readonly?: boolean;

  /**
   * The placeholder that will displayed inside the input box.
   */
  placeholder?: string;

  /**
   * Floating label for an input field. Use either this or placeholder but not both.
   */
  label?: string;

  /**
   * Additional help text to display under the input field.
   */
  helpText?: ReactNode;

  /**
   * Indicates whether a selected date time is required.
   */
  required?: boolean;

  /**
   * Indicates whether the input box should be disabled.
   */
  disabled?: boolean;

  /**
   * Indicates whether the selected value can be cleared
   */
  isClearable?: boolean;

  /**
   * Callback trigger for when the user clicks the input box.
   */
  onClickInput?: (e: any) => void;

  /**
   * Additional class names to apply to the root element.
   */
  className?: string;

  /**
   * Inline styles to be applied to the root element.
   */
  style?: CSSProperties;
  onClose?: () => void;
  onOpen?: () => void;
}

const MDY_DATE_FORMAT = 'Y-MM-DD';
const TIME_FORMAT = 'HH:mm zz';

const noOuterMarginCss = css`
  margin-bottom: 0;
`;

export function DatePicker({
  readonly,
  placeholder,
  value: valueProp,
  helpText,
  disabled,
  input = true,
  label,
  required,
  onClickInput,
  initialValue,
  initialViewDate,
  initialViewMode,
  onNavigate,
  closeOnClickOutside,
  closeOnSelect = true,
  onOpen,
  onClose,
  className = '',
  style,
  isClearable,
  onChange,
  dateFormat = MDY_DATE_FORMAT,
  timeFormat = TIME_FORMAT,
  ...rest
}: DatePickerProps) {
  const id = useMemoizedId({ label: 'datepicker' });
  const [localValue, setLocalValue] = useState<string | Moment>('');
  const value = valueProp !== undefined ? valueProp : localValue;

  const [shouldFloat, setShouldFloat] = useState(!!initialValue || !!value);

  useEffect(() => {
    setShouldFloat(!!value);
  }, [value]);

  const getFormat = () => (dateFormat && timeFormat ? `${dateFormat} ${timeFormat}` : dateFormat || timeFormat);

  const { v17_noOuterSpacing } = useFeatureFlags();

  const wrapperClassNames = cx(
    styles.timeToggle,
    styles.datetime,
    styles.formGroup,
    styles.datePicker,
    v17_noOuterSpacing && noOuterMarginCss,
    {
      [styles.formGroupActive]: shouldFloat,
      'datepicker--disabled': disabled,
    },
  );

  return (
    <div className={`${wrapperClassNames} ${className}`} style={style}>
      {input ? (
        <label
          htmlFor={id}
          style={{
            pointerEvents: 'none',
            wordWrap: 'break-word',
            width: '80%',
          }}
          className={cx(styles.controlLabel)}
          title={placeholder || label}
        >
          {placeholder || label}
          {required ? <span style={{ color: persimmon.base }}> *</span> : ''}
        </label>
      ) : null}
      <DateTimeField
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        input={input}
        inputProps={{
          id,
          readOnly: true,
          disabled,
          required,
          onClick: onClickInput,
          style: { width: style?.width },
        }}
        renderInput={inputProps => {
          const formattedValue = value ? moment(value).format(getFormat()?.toString()) : '';
          return <input {...inputProps} value={formattedValue} />;
        }}
        onClose={() => {
          setShouldFloat(!!value);
          const onCloseHandler = onClose;
          if (onCloseHandler) {
            onCloseHandler();
          }
        }}
        onOpen={() => {
          setShouldFloat(true);
          const onOpenHandler = onOpen;
          if (onOpenHandler) {
            onOpenHandler();
          }
        }}
        initialValue={initialValue}
        value={valueProp}
        initialViewDate={initialViewDate}
        initialViewMode={initialViewMode}
        closeOnSelect={closeOnSelect}
        closeOnClickOutside={closeOnClickOutside}
        onNavigate={onNavigate}
        onChange={d => {
          if (onChange) {
            onChange(d);
          }
          setLocalValue(d);
        }}
        {...rest}
      />
      {input ? <Calendar className={cx(styles.datetime, styles.calendarIconStyle)} aria-hidden="true" /> : null}
      {input && value && isClearable ? (
        <BlockButton
          className={styles.clearIconStyle}
          onClick={e => {
            if (onChange) {
              onChange('');
              setLocalValue('');
              e.stopPropagation();
            }
          }}
        >
          <Close width="16px" />
        </BlockButton>
      ) : null}
      {helpText && input ? <small className="help-block">{helpText}</small> : null}
    </div>
  );
}
