/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Button, Modal } from '@cimpress/react-components';

export const modalStyles = css`
  height: 100%;

  > div {
    height: 100%;
  }

  > div > div:nth-of-type(2) {
    padding: 0.8rem 0;
  }
`;

const ModalHeader = ({ headerText }: { headerText: string }) => {
  const modalHeaderStyles = css`
    font-weight: 400;
    font-size: 1.9rem;
    margin: 0;
  `;

  return <h2 css={modalHeaderStyles}>{headerText}</h2>;
};

const ModalFooter = ({ clickHandler }: { clickHandler?: () => void }) => {
  const modalFooterWrapperStyles = css`
    display: flex;
    align-items: flex-start;
  `;

  return (
    <div css={modalFooterWrapperStyles}>
      <Button variant="default" onClick={clickHandler}>
        Close
      </Button>
    </div>
  );
};

export const RoutingModal = ({
  modalHeaderText,
  showModal,
  closeModal,
  styles,
  testId,
  children,
}: {
  modalHeaderText: string;
  showModal?: boolean;
  closeModal?: () => void;
  styles?: SerializedStyles;
  testId?: string;
  children: React.ReactNode;
}) => {
  return (
    <Modal
      show={!!showModal}
      size="xl"
      title={<ModalHeader headerText={modalHeaderText} />}
      footer={<ModalFooter clickHandler={closeModal} />}
      closeButton={true}
      closeOnOutsideClick={true}
      onRequestHide={closeModal}
      css={[modalStyles, styles]}
      data-testid={testId}
    >
      {children}
    </Modal>
  );
};
