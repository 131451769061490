import { css, cx } from '@emotion/css';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { ReactSVG } from 'react-svg';
import { PublicComponentProps } from './types';
import { useFeatureFlags } from './FeatureFlags';

export interface RobotProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    PublicComponentProps {
  /**
   * The contextual style for the background and the demeanor of the robot. One of success, info, warning, or danger.
   */
  status: 'success' | 'info' | 'warning' | 'danger';
  /**
   * Sets the size of the robot. One of sm or lg. Use the larger robot only for full-page messages.
   */
  size?: 'sm' | 'lg';
  /**
   * Uses an alternate robot image, if available. Not all states have alternates.
   */
  alternate?: boolean;
}

const robotStyle = css`
  position: relative;
  margin: 15px;
`;

const robotSm = css`
  height: 200px;
  width: 160px;
`;

const robotLg = css`
  height: 350px;
  width: 280px;
`;

const robotBgSm = css`
  position: absolute;
  transform-origin: 0 0;
  top: 45px;
`;
const robotBgLg = css`
  position: absolute;
  transform-origin: 0 0;
  top: 45px;
  transform: scale(1.75);
`;
const robotFigureSm = css`
  position: absolute;
  transform-origin: 0 0;
  transform: translate(-50%);
  left: 50%;
`;
const robotFigureLg = css`
  position: absolute;
  transform-origin: 0 0;
  transform: scale(1.75) translate(-50%);
  left: 50%;
`;

const noOuterMarginCss = css`
  margin: 0;
`;

export const Robot = ({ status, size = 'sm', alternate, className, ...rest }: RobotProps) => {
  const { v17_noOuterSpacing } = useFeatureFlags();

  return (
    <div
      className={cx(
        'crc-robot',
        robotStyle,
        size === 'sm' ? robotSm : robotLg,
        v17_noOuterSpacing && noOuterMarginCss,
        className,
      )}
      {...rest}
    >
      <div className={cx(size === 'sm' ? robotBgSm : robotBgLg)}>
        <ReactSVG src={`https://static.ux.cimpress.io/assets/images/bg_diamond-${status || 'success'}.svg`} />
      </div>
      <div className={cx(size === 'sm' ? robotFigureSm : robotFigureLg)}>
        <ReactSVG
          src={`https://static.ux.cimpress.io/assets/images/robot-${status || 'success'}${alternate ? '-2' : ''}.svg`}
        />
      </div>
    </div>
  );
};
