/* istanbul ignore file */
import { RetryRoutingRequestParams } from '../../clients/requestManager';
import { useRetryRoutingRequest } from '../../hooks/useRequestManager';
import { useState, createContext } from 'react';
import { UseMutationResult, useQueryClient } from 'react-query';

type RetryState = 'IDLE' | 'PENDING' | 'DONE' | 'ERROR';
export type RetryContextValues = {
  pickedOption: { chosenFCUrl: string; chosenProductUrl: string } | undefined;
  setPickedOption: React.Dispatch<React.SetStateAction<{ chosenFCUrl: string; chosenProductUrl: string } | undefined>>;
  retryState: RetryState;
  setRetryState: React.Dispatch<React.SetStateAction<RetryState>>;
  retryError: Error | undefined;
  setRetryError: React.Dispatch<React.SetStateAction<Error | undefined>>;
  retryRoutingRequest: UseMutationResult<void, Error, RetryRoutingRequestParams, unknown> | undefined;
};

export const RetryContext = createContext<RetryContextValues>({
  pickedOption: undefined,
  setPickedOption: () => {},
  retryState: 'IDLE',
  setRetryState: () => {},
  retryError: undefined,
  setRetryError: () => {},
  retryRoutingRequest: undefined,
});

export const RetryContextProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const [pickedOption, setPickedOption] = useState<{ chosenFCUrl: string; chosenProductUrl: string } | undefined>(
    undefined,
  );
  const [retryState, setRetryState] = useState<RetryState>('IDLE');
  const [retryError, setRetryError] = useState<Error | undefined>(undefined);

  const retryRoutingRequest = useRetryRoutingRequest({
    onSuccess: () => {
      setPickedOption(undefined);
      setRetryState('DONE');
    },
    onError: (error: Error) => {
      setRetryState('ERROR');
      setRetryError(error);
    },
    onMutate: () => {
      // Runs when the mutation function is called
      setRetryError(undefined);
    },
    onSettled: () => {
      // Called on success and error
      // Reset the mutation function state
      retryRoutingRequest.reset();

      // Invalidate the query cache for the request, decision, and item so that the UI refreshes to show
      // the last attempted update date/time and number of retries if there aren't any
      // options. Swallow the error; worst case, the UI doesn't automatically update.
      queryClient.invalidateQueries('getRoutingRequest').catch(() => {});
      queryClient.invalidateQueries('getDecisionFromUrl').catch(() => {});
      queryClient.invalidateQueries('itemService').catch(() => {});
    },
  });

  return (
    <RetryContext.Provider
      value={{
        pickedOption,
        setPickedOption,
        retryState,
        setRetryState,
        retryError,
        setRetryError,
        retryRoutingRequest,
      }}
    >
      {children}
    </RetryContext.Provider>
  );
};
