import React, { SVGProps } from 'react';

export interface CalendarProps extends SVGProps<SVGSVGElement> {
  /** The width and height properties of the circle's svg, defaults to '14'. */
  width?: number;
}

export const Calendar = ({ width = 16, ...rest }: CalendarProps) => (
  <svg
    width={`${width}px`}
    height={`${width}px`}
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...rest}
  >
    <g>
      <path
        d="M14.2,2.3h-0.6V1.9c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9v0.3H6.3V1.9C6.3,0.9,5.4,0,4.4,0C3.3,0,2.4,0.9,2.4,1.9
      v0.3H1.8C0.8,2.3,0,3.1,0,4.1v10.1c0,1,0.8,1.8,1.8,1.8h12.4c1,0,1.8-0.8,1.8-1.8V4.1C16,3.1,15.2,2.3,14.2,2.3z M10.9,1.9
      c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8v1.5h-1.6V1.9z M3.6,1.9c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8v1.5H3.6V1.9z
       M14.9,14.2c0,0.4-0.3,0.7-0.7,0.7H1.8c-0.4,0-0.7-0.3-0.7-0.7V6.4c0-0.2,0.2-0.4,0.4-0.4h13c0.2,0,0.4,0.2,0.4,0.4V14.2z"
      />
    </g>
  </svg>
);
