import { cx, css } from '@emotion/css';
import React, { memo, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';

export interface SpinnerProps extends PublicComponentProps {
  /**
   * Number greater than 0. The duration of the animation (i.e. time taken to make 2 rotations).
   * @default 2
   */
  duration?: number;

  /**
   * Setting this to true will render the spinner in the center of the page. (Note this does not take into account vertical scrolling).
   * @default false
   */
  fullPage?: boolean;

  /**
   * Called when component is unmounted. Argument supplied to callback is the current animation offset.
   */
  handleOffset?: (currentOffset: number) => void;

  /**
   * Number between 0 and 1 (inclusive). Treated as a percentage.
   * Starts the animation at the given offset time (e.g. offset=0.25 starts the animation 25% of the way through).
   * @default 0
   */
  offset?: number;

  /**
   * Size of spinner (large = 72px, medium = 38px, small = 16px).
   * @default 'large'
   */
  size?: 'large' | 'medium' | 'small';
}

const standardDimensions = {
  large: '72px',
  medium: '38px',
  small: '16px',
};

const pageCenterStyle = css`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const spinnerStyle = css`
  .crc-spinner__ring-bg {
    stroke: ${cvar('color-spinner-ring')};
    fill: none;
    stroke-width: 10;
    stroke-linecap: butt;
  }
  .crc-spinner__ring-animated {
    stroke: ${cvar('color-background-info')};
    fill: none;
    stroke-width: 10;
    stroke-linecap: butt;
  }
`;

export const Spinner = memo(
  ({
    className = '',
    duration = 2,
    fullPage = false,
    handleOffset,
    offset = 0,
    size = 'large',
    ...rest
  }: SpinnerProps) => {
    const el = useRef<SVGSVGElement>(null);

    useEffect(() => {
      const getCurrentOffset = () => (el.current!.getCurrentTime() % duration) / duration;
      return () => handleOffset && handleOffset(getCurrentOffset());
    }, [duration, handleOffset]);

    const renderSpinner = () => {
      const modOffset = offset % 1;
      const begin = (1 - modOffset) * duration - duration;

      return (
        <div
          className={cx(['crc-spinner', fullPage && pageCenterStyle, spinnerStyle, className])}
          role="progressbar"
          aria-valuemin={0}
          aria-valuemax={100}
          {...rest}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={standardDimensions[size]}
            height={standardDimensions[size]}
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="crc-spinner__svg"
            ref={el}
          >
            <circle className="crc-spinner__ring-bg" cx="50" cy="50" r="40" />
            <circle className="crc-spinner__ring-animated" cx="50" cy="50" r="40">
              <animate
                attributeName="stroke-dashoffset"
                dur={duration}
                repeatCount="indefinite"
                from="0"
                to="-502"
                begin={begin}
              />
              <animate
                attributeName="stroke-dasharray"
                dur={duration}
                repeatCount="indefinite"
                values="75.3 175.7;1 250;75.3 175.7"
                begin={begin}
              />
            </circle>
          </svg>
        </div>
      );
    };

    return fullPage ? createPortal(renderSpinner(), document.body) : renderSpinner();
  },
);
