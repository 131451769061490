import { css, cx } from '@emotion/css';
import memoize from 'memoize-one';
import React, { PureComponent } from 'react';

import { Pagination } from '../Pagination';
import { Select } from '../Select';
import { TextField } from '../TextInput';
import cvar from '../theme/cvar';
import { FeatureFlags } from '../FeatureFlags';

export default class TablePagination extends PureComponent<TablePaginationProps> {
  state = {
    goToPage: '',
  };

  onPageChange = ({ selected }: { selected: number }) => {
    this.props.onPageChange(selected);
  };

  onPageSizeChange = (e: any) => {
    e && this.props.onPageSizeChange(Number(e.value));
  };

  onGoToPageChange = (e: any) => {
    this.setState({ goToPage: e.target.value });
  };

  onGoToPageSubmit: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
    if (e.key === 'Enter') {
      const parsedPage = parseInt(this.state.goToPage, 10);
      if (!Number.isNaN(parsedPage)) {
        this.props.onPageChange(parsedPage - 1);
      }
    }
  };

  getPageSizeOptions = memoize((pageSizeOptions: number[]) =>
    pageSizeOptions.map(pageSize => ({ label: `${pageSize}`, value: `${pageSize}` })),
  );

  getSelectedPageSizeOption = () => ({ label: `${this.props.pageSize}`, value: `${this.props.pageSize}` });

  render() {
    const {
      className,
      page,
      pages,
      pageSizeOptions,
      showPageJump,
      showPageSizeOptions,
      goToLabelText = 'Go To',
      perPageLabelText = 'Per Page',
    } = this.props;
    const { goToPage } = this.state;

    if (pages < 1) {
      return null;
    }

    const pagesContainer = css`
      display: flex;
      align-items: center;
    `;

    const pageJump = css`
      width: calc(${cvar('spacing-48')} * 2);
      margin-bottom: 0px;
      margin-right: ${cvar('spacing-12')};
    `;

    const rtPagination = css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      .pagination {
        margin: 0 0 ${cvar('spacing-16')} ${cvar('spacing-32')};
      }
    `;

    const pageSizeSelectStyle = css`
      width: calc(${cvar('spacing-48')} * 3);
    `;

    return (
      <FeatureFlags flags={{ v17_noOuterSpacing: true }}>
        <div className={cx(className, rtPagination)}>
          {showPageSizeOptions && (
            <Select
              className={pageSizeSelectStyle}
              isClearable={false}
              label={perPageLabelText}
              options={this.getPageSizeOptions(pageSizeOptions)}
              value={this.getSelectedPageSizeOption()}
              onChange={this.onPageSizeChange}
            />
          )}
          {pages > 1 && (
            <div className={pagesContainer}>
              {showPageJump && (
                <TextField
                  className={pageJump}
                  label={goToLabelText}
                  value={goToPage}
                  onChange={this.onGoToPageChange}
                  onKeyDown={this.onGoToPageSubmit}
                />
              )}
              <Pagination
                pageRangeDisplayed={2}
                marginPagesDisplayed={3}
                initialPage={page}
                forcePage={page}
                pageCount={pages}
                onPageChange={this.onPageChange}
              />
            </div>
          )}
        </div>
      </FeatureFlags>
    );
  }
}

type TablePaginationProps = {
  className?: string;
  page: number;
  pages: number;
  pageSize: number;
  pageSizeOptions: number[];
  showPageJump?: boolean;
  showPageSizeOptions?: boolean;
  onPageChange: (selected: number) => void;
  onPageSizeChange: (e: any) => void;
  perPageLabelText?: string;
  goToLabelText?: string;
};
