import * as React from 'react';
import { SortIndicatorSvgProps } from './SortIndicatorSvg';

const SortIndicatorDownSvg = ({ size, color, style }: SortIndicatorSvgProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    color={color}
    style={style}
  >
    <path d="M23.468 2.984a2 2 0 0 0-1.742-1.018H2.274A2 2 0 0 0 .563 5l9.726 16.07a2 2 0 0 0 3.422 0L23.437 5a2 2 0 0 0 .031-2.016z" />
  </svg>
);

export default SortIndicatorDownSvg;
