export type Method = 'GET' | 'POST';

type FetchWithAuthParams = {
  path: string;
  accessToken: string;
  method?: Method;
  contentType?: string;
  body?: string;
};

export const fetchWithAuth = ({ path, accessToken, method = 'GET', contentType, body }: FetchWithAuthParams) => {
  if (contentType) {
    return fetch(path, {
      headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': contentType },
      method,
      ...(body && { body }),
    });
  }

  return fetch(path, { headers: { Authorization: `Bearer ${accessToken}` }, method, ...(body && { body }) });
};
