import React, { useCallback, HTMLAttributes } from 'react';

export interface BlockButtonProps extends HTMLAttributes<HTMLElement> {
  as?: string;
  [passthruProps: string]: any;
}

export const BlockButton = ({ as: asComponent, ...passthruProps }: BlockButtonProps) => {
  const { onClick } = passthruProps;

  const Element = asComponent || 'div';
  const keyDown = useCallback(
    event => {
      if (onClick && (event.keyCode === 13 || event.keyCode === 32)) {
        event.preventDefault();
        onClick(event);
      }
    },
    [onClick],
  );

  return <Element tabIndex={0} role="button" onKeyDown={keyDown} {...passthruProps} />;
};
