import { cobalt, disabled, ocean, shale, slate, white } from '../colors';

export default {
  'color-icon-default': cobalt.base,
  'color-icon-secondary': slate,
  'color-icon-secondary-active': shale,
  'color-icon-interactive': ocean.base,
  'color-icon-disabled': disabled.darker,
  'color-icon-inverse': white,
};
