/** @jsxImportSource @emotion/react */
import { QueryClient, QueryClientProvider } from 'react-query';
import { ItemRoutingDecisionViewerBody } from './ItemRoutingDecisionViewerBody';
import { ItemRoutingDecisionViewerProps } from './ItemRoutingDecisionViewer';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { RoutingModal } from '../Modal/ModalComponents';

type ItemRoutingDecisionViewerModalProps = ItemRoutingDecisionViewerProps & {
  queryClient: QueryClient;
};

export const ItemRoutingDecisionViewerModal = (props: ItemRoutingDecisionViewerModalProps) => {
  const { showModal = false, closeModal, queryClient } = props;

  return (
    <RoutingModal
      modalHeaderText="Routing Decision"
      showModal={showModal}
      closeModal={closeModal}
      testId="item-routing-decision-viewer-modal"
    >
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary>
          <ItemRoutingDecisionViewerBody {...props} />
        </ErrorBoundary>
      </QueryClientProvider>
    </RoutingModal>
  );
};
