import { cobalt, danger, disabled, ocean, shale, slate, success, warning, white } from '../colors';

const DEFAULT_FONT_STACK = '"Open Sans", "San Francisco", "Segoe UI", "Helvetica Neue", Arial, sans-serif';
const MONOSPACE_FONT_STACK = 'Menlo, Monaco, monospace';

export default {
  'color-text-default': cobalt.base,
  'color-text-inverse': white,
  'color-text-interactive': ocean.base,
  'color-text-disabled': disabled.darker,
  'color-text-disabled-dark': disabled.darkest,
  'color-text-hover': ocean.darker,
  'color-text-selected': ocean.darkest,
  'color-text-success': success.base,
  'color-text-warning': warning.base,
  'color-text-error': danger.base,
  'color-text-label': slate,
  'color-text-table-header': shale,
  'text-xsmall': `10px/24px ${DEFAULT_FONT_STACK}`,
  'text-button-large': `600 14px/19px ${DEFAULT_FONT_STACK}`,
  'text-button-medium': `600 14px/19px ${DEFAULT_FONT_STACK}`,
  'text-button-small': `600 10px/14px ${DEFAULT_FONT_STACK}`,
  'text-paragraph-default': `14px/24px ${DEFAULT_FONT_STACK}`,
  'text-h1': `300 34px ${DEFAULT_FONT_STACK}`,
  'text-h2': `300 24px ${DEFAULT_FONT_STACK}`,
  'text-h3': `300 19px ${DEFAULT_FONT_STACK}`,
  'text-h4': `500 17px ${DEFAULT_FONT_STACK}`,
  'text-h5': `500 15px ${DEFAULT_FONT_STACK}`,
  'text-h6': `700 11px ${DEFAULT_FONT_STACK}`,
  'text-h7': `700 10px ${DEFAULT_FONT_STACK}`,
  'text-size-h1': '34px',
  'text-size-h2': '24px',
  'text-size-h3': '19px',
  'text-size-h4': '17px',
  'text-size-h5': '15px',
  'text-size-h6': '11px',
  'text-size-h7': '10px',
  'text-size-base': '14px',
  'text-size-small': '12px',
  'text-link-small': `11px/15px ${DEFAULT_FONT_STACK}`,
  'text-label-small': `10px/12px ${DEFAULT_FONT_STACK}`,
  'text-label-medium': `14px/16px ${DEFAULT_FONT_STACK}`,
  'text-label-default': `12px/16px ${DEFAULT_FONT_STACK}`,
  'text-label-large': `16px/24px ${DEFAULT_FONT_STACK}`,
  'text-table-header': `600 12px/16px ${DEFAULT_FONT_STACK}`,
  'font-default': DEFAULT_FONT_STACK,
  'font-monospace': MONOSPACE_FONT_STACK,
  'line-height-small': '12px',
  'line-height-default': '16px',
  'line-height-large': '24px',
};
