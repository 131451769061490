import { RoutingUIError } from '../components/ErrorBoundary/RoutingUIError';
import { getErrorMessage } from '../helpers/errorStatus';
import { SuccessfulDecision, FailedDecision, Environment } from '../types';
import { fetchWithAuth } from './fetchWithAuth';

export type GetDecisionsSearchParams = {
  orderId?: string;
  itemId?: string;
};

export type GetDecisionsParams = {
  environment: Environment;
  accessToken: string;
  params: GetDecisionsSearchParams;
};

const INT_BASE_URL = 'https://int-orca.lynx.cimpress.io';
const PROD_BASE_URL = 'https://orca.lynx.cimpress.io';

const getBaseUrl = (environment: Environment): string => (environment === 'int' ? INT_BASE_URL : PROD_BASE_URL);

export async function getDecisions({
  environment,
  accessToken,
  params,
}: GetDecisionsParams): Promise<SuccessfulDecision[] | FailedDecision[]> {
  const url = new URL(getBaseUrl(environment));
  url.pathname = '/v1/decisions';

  const querystring: Record<string, string> = {};
  if (params.orderId) {
    querystring.orderId = params.orderId;
  }

  if (params.itemId) {
    querystring.itemId = params.itemId;
  }

  url.search = new URLSearchParams(querystring).toString();
  const response = await fetchWithAuth({ path: url.toString(), accessToken });

  if (!response.ok) {
    throw new Error(
      `Error retrieving decisions.${params.orderId ? ' Order ID: ' + params.orderId + '.' : ''}${params.itemId ? ' Item ID: ' + params.itemId + '.' : ''} Received status code ${response.status}.`,
    );
  }

  return response.json() as Promise<SuccessfulDecision[] | FailedDecision[]>;
}

export type GetDecisionParams = {
  environment: Environment;
  accessToken: string;
  decisionId: string;
};

export async function getDecision({
  environment,
  accessToken,
  decisionId,
}: GetDecisionParams): Promise<SuccessfulDecision | FailedDecision> {
  const path = `${getBaseUrl(environment)}/v1/decisions/${decisionId}`;
  const response = await fetchWithAuth({ path, accessToken });

  if (!response.ok) {
    throw new Error(`Error retrieving decision for ${decisionId}. Received status code ${response.status}.`);
  }

  return response.json() as Promise<SuccessfulDecision | FailedDecision>;
}

export type GetDecisionFromUrlParams = {
  accessToken: string;
  decisionLink?: string;
  enabled?: boolean;
};

export const getDecisionFromUrl = async ({
  accessToken,
  decisionLink,
}: GetDecisionFromUrlParams): Promise<SuccessfulDecision | FailedDecision> => {
  if (!decisionLink) {
    throw new RoutingUIError({
      name: 'ROUTING_DECISION_ERROR',
      message: 'A decision link must be provided in order to load a routing decision.',
    });
  }

  const response = await fetchWithAuth({ path: decisionLink, accessToken });

  if (!response.ok) {
    throw new RoutingUIError({
      name: 'ROUTING_DECISION_ERROR',
      message: getErrorMessage({ erroredItem: 'routing decision', statusCode: response.status }),
      link: { href: decisionLink, name: 'Routing decision link' },
    });
  }

  return response.json() as Promise<SuccessfulDecision | FailedDecision>;
};
