import React from 'react';
import { css, cx } from '@emotion/css';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';

export interface ButtonGroupProps extends PublicComponentProps {
  /**
   * The variant of button group that you are creating.
   */
  variant?: 'justified' | 'vertical';

  children?: React.ReactNode;
}

const btnGrpCss = css`
  position: relative;
  display: inline-block;
  vertical-align: middle;
`;

const btnGrpJustifiedCss = css`
  button {
    display: inline-block;
    border-right: 1px solid ${cvar('color-button-primary')};
    border-top: 1px solid ${cvar('color-button-primary')};
    border-bottom: 1px solid ${cvar('color-button-primary')};

    &:not(:first-child) {
      border-radius: 0;
      border-left: none;
    }

    &:not(:last-child) {
      border-radius: 0;
    }

    &:first-child {
      border-left: 1px solid ${cvar('color-button-primary')};
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    &:last-child {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
`;

const btnGrpVerticalCss = css`
  button {
    display: block;
    border-bottom: 1px solid ${cvar('color-button-primary')};
    border-left: 1px solid ${cvar('color-button-primary')};
    border-right: 1px solid ${cvar('color-button-primary')};

    &:not(:first-child) {
      border-radius: 0;
      border-top: none;
    }

    &:not(:last-child) {
      border-radius: 0;
    }

    &:first-child {
      border-top: 1px solid ${cvar('color-button-primary')};
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }

    &:last-child {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
`;

const hoverActiveBorderCss = css`
  button {
    &:hover {
      border-color: ${cvar('color-button-primary')};
    }
    &:active {
      border-color: ${cvar('color-button-primary')};
    }
  }
`;

export function ButtonGroup({ variant = 'justified', className = '', ...props }: ButtonGroupProps) {
  return (
    <div
      className={cx(
        'crc-button-group',
        btnGrpCss,
        { [btnGrpVerticalCss]: variant === 'vertical', [btnGrpJustifiedCss]: variant !== 'vertical' },
        hoverActiveBorderCss,
        className,
      )}
      {...props}
    >
      {props.children}
    </div>
  );
}
