import React, { ReactNode } from 'react';
import { css } from '@emotion/css';

import cvar from '../theme/cvar';

const tabGroupStyle = css`
  background-color: ${cvar('color-tabmenu-background')};
  padding-top: ${cvar('spacing-2')};

  h6 {
    padding: ${cvar('spacing-8')} ${cvar('spacing-32')};
    margin: 0;
    border-bottom: 1px solid ${cvar('color-border-default')};
  }
`;

export interface TabGroupProps {
  /**
   * The title of the group of tabs
   */
  label: string;

  /**
   * Inner content of TabGroup element
   */
  children?: ReactNode;
}

const TabGroup = ({ label, children }: TabGroupProps) => (
  <div className={tabGroupStyle}>
    <h6>{label}</h6>
    {children}
  </div>
);

export default TabGroup;
