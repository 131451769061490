/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Spinner } from '@cimpress/react-components';

const spinnerStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 400px;
`;

export const SpinnerWrapper = () => {
  return (
    <div css={spinnerStyles}>
      <Spinner data-testid="decision-viewer-body-spinner" />
    </div>
  );
};
