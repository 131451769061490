/* eslint-disable react/no-unstable-nested-components */
import React, { PureComponent, ReactNode } from 'react';
import memoizeOne from 'memoize-one';
import { components, StylesConfig, GroupBase } from 'react-select';
import { css } from '@emotion/css';

import CURRENCY_CODES from './currencyCodes';
import { Select } from '../Select';
import { PublicComponentProps } from '../types';

type IsMulti = boolean;

type Group = GroupBase<unknown>;

/** The callback argument to the `onChange` event of a `CurrencySelector`. */
export interface CurrencyChangeArg {
  /** The ISO 4217 code for the selected currency. */
  value: string;

  /** The name of the selected currency's originating country or region. */
  label: string;

  /** An image of the flag of the selected currency's country or region. */
  flag: ReactNode;

  /** The symbol representing the currency. For example, '$'. */
  symbol: string;

  /** The name of the currency's originating country or region. */
  country: string;

  /** The ISO 3611-1 Alpha-2 code for the currency's originating country or region. */
  iso2: string;
}

/** The properties used to initialize a `CurrencySelector` component. */
interface BaseCurrencySelectorProps extends PublicComponentProps {
  /** Array of strings representing the currently selected currency code(s). */
  currency?: string | string[];

  /** Boolean determining whether to disable the selector. */
  isDisabled?: boolean;

  /** A list of ISO 4217 3-letter currency codes to disable. If this prop is used, the passed currencies will be visible but not selectable. */
  disabledCurrencies?: string[];

  /** A list of ISO 4217 3-letter currency codes to restrict to. If this prop is used only the passed currencies will be displayed */
  allowedCurrencies?: string[];

  /** The label for the currency selector. */
  label?: string;

  /** Boolean determining whether to display full currency names or 3-character currency codes. */
  displayFullName?: boolean;

  /** Boolean determining whether the Select element is clearable. */
  isClearable?: boolean;

  /** Inline styles to be applied to the root currency selector div. */
  styles?: StylesConfig<unknown, IsMulti, Group>;
}

interface MultiCurrencySelectorProps extends BaseCurrencySelectorProps {
  /** Boolean determining whether the currency selector allows multiple values to be selected. */
  isMulti: true;

  /** Array of strings representing the currently selected currency code(s). */
  currency?: string[];

  /** Callback function that is fired when a user selects a currency. */
  /** When isMulti is true, this will be called with a list of currencies. */
  onChange?: (e: CurrencyChangeArg[]) => void;
}

interface SingleCurrencySelectorProps extends BaseCurrencySelectorProps {
  /** Boolean determining whether the currency selector allows multiple values to be selected. */
  isMulti?: false;

  /** String representing the currently selected currency code(s). */
  currency?: string;

  /** Callback function that is fired when a user selects a currency. */
  /** When isMulti is false, this will be called with a single currency. */
  onChange?: (e: CurrencyChangeArg) => void;
}

export type CurrencySelectorProps = SingleCurrencySelectorProps | MultiCurrencySelectorProps;

interface CustomCurrencySelectProps {
  value: string;
  label: string;
  displayFullName: boolean;
  flag: ReactNode;
  [key: string]: any;
}

const flagContainerStyle = css`
  display: flex;
  align-items: center;

  .select-emoji {
    width: 18px;
  }
`;

const flagStyle = css`
  display: contents;
`;

const CustomCurrencySelect = ({ value, flag, label, displayFullName }: CustomCurrencySelectProps) => (
  <div className={flagContainerStyle}>
    {/* eslint-disable react/no-danger  */}
    <span className={flagStyle} dangerouslySetInnerHTML={{ __html: `${flag}` }} />
    {/* eslint-enable react/no-danger */}
    &nbsp; {displayFullName ? `${label} (${value})` : value}
  </div>
);

export class CurrencySelector extends PureComponent<CurrencySelectorProps> {
  static defaultProps: CurrencySelectorProps = {
    isDisabled: false,
    disabledCurrencies: [],
    allowedCurrencies: [],
    isMulti: false,
    className: '',
    displayFullName: false,
    isClearable: false,
    currency: undefined,
    label: 'Currency',
    styles: {},
  };

  onChangeCurrency = (currency: any) => {
    const { onChange } = this.props;
    if (onChange) onChange(currency);
  };

  getCurrencyCodes = memoizeOne((disabledCurrencies: string[] | undefined, allowedCurrencies: string[] | undefined) => {
    // This prevents every currency selector from making a copy of the list of currencies.
    // Because the common use case is to not disable currencies (or provide restriction), most selectors will not copy the list.
    let availableCurrencies = CURRENCY_CODES;

    if (allowedCurrencies && allowedCurrencies.length > 0) {
      const allowedCurrenciesSet = new Set(allowedCurrencies);
      availableCurrencies = CURRENCY_CODES.filter(currecny => allowedCurrenciesSet.has(currecny.value));
    }

    if (!disabledCurrencies || disabledCurrencies.length === 0) {
      return availableCurrencies;
    }

    const disabledCurrencySet = new Set(disabledCurrencies);

    const currencies = availableCurrencies.map(currency =>
      disabledCurrencySet.has(currency.value) ? { ...currency, disabled: true } : currency,
    );

    return currencies;
  });

  getCurrencyValue = (codeOptions: CurrencyChangeArg[]) => {
    const { currency } = this.props;
    if (Array.isArray(currency)) {
      return currency.map(selectedCurrency => {
        const currencyIdx = codeOptions.findIndex(option => option.value === selectedCurrency);
        return codeOptions[currencyIdx];
      });
    }

    const currencyIdx = codeOptions.findIndex(option => option.value === currency);
    return codeOptions[currencyIdx];
  };

  render() {
    const {
      className,
      isClearable,
      currency,
      isDisabled,
      disabledCurrencies,
      allowedCurrencies,
      displayFullName,
      label,
      styles,
      ...rest
    } = this.props;

    const currencyClassName = `currency-select ${className}`;

    const currencyCodes = this.getCurrencyCodes(disabledCurrencies, allowedCurrencies);

    return (
      <Select
        label={label}
        isDisabled={isDisabled}
        className={currencyClassName}
        // This is the v2+ way of handling custom styling for various parts of the select
        // The inline functions are done in order to pass displayFullName along for the display logic
        components={{
          Option: ({ data, ...props }: any) => (
            <components.Option {...props}>
              <CustomCurrencySelect {...data} displayFullName={displayFullName} />
            </components.Option>
          ),
          SingleValue: ({ data, ...props }: any) => (
            <components.SingleValue {...props}>
              <CustomCurrencySelect {...data} displayFullName={displayFullName} />
            </components.SingleValue>
          ),
          MultiValue: ({ data, ...props }: any) => (
            <components.MultiValue {...props}>
              <CustomCurrencySelect {...data} displayFullName={displayFullName} />
            </components.MultiValue>
          ),
        }}
        isOptionDisabled={(option: any) => option.disabled}
        isClearable={isClearable}
        onChange={this.onChangeCurrency}
        options={currencyCodes}
        value={this.getCurrencyValue(currencyCodes)}
        styles={styles}
        {...rest}
      />
    );
  }
}
