import { css, cx } from '@emotion/css';
import React from 'react';

import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';
import { useFeatureFlags } from './FeatureFlags';

export interface PaginationProps extends ReactPaginateProps, PublicComponentProps {
  /**
   * The initial page that is selected in the component.
   */
  initialPage?: number;

  /**
   * Callback function that is triggered when a new page number is selected.
   */
  onPageChange: ({ selected }: { selected: number }) => void;

  /**
   * Defines the total number of pages available.
   */
  pageCount: number;

  /**
   * The number of pages displayed between the ... boxes. Only updates on odd number inputs. (i.e. 6 will show 7 boxes).
   */
  pagesBetweenElipses?: number;

  /**
   * The number of pages displayed to the left and right of the ... boxes.
   */
  pagesOutsideElipses?: number;
}

const containerCss = css`
  display: inline-block;
  padding-left: 0;
  margin: ${cvar('spacing-16')} 0;
  border-radius: ${cvar('spacing-2')};

  > li {
    display: inline;
    > a {
      position: relative;
      float: left;
      padding: ${cvar('spacing-16')} 20px;
      line-height: 1.286;
      text-decoration: none;
      color: ${cvar('color-button-primary')};
      background-color: ${cvar('color-background')};
      border: 1px solid ${cvar('color-border-default')};
      margin-left: -1px;
      cursor: pointer;

      &:focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
      }
    }
    &:first-child {
      > a {
        margin-left: 0;
        border-top-left-radius: ${cvar('spacing-2')};
        border-bottom-left-radius: ${cvar('spacing-2')};
      }
    }
    &:last-child {
      > a {
        border-top-right-radius: ${cvar('spacing-2')};
        border-bottom-right-radius: ${cvar('spacing-2')};
      }
    }
  }

  > li > a {
    &:hover,
    &:focus {
      z-index: 2;
      color: ${cvar('color-button-primary')};
      background-color: ${cvar('color-hover')};
      border-color: ${cvar('color-border-default')};
    }
  }

  > .active > a {
    &,
    &:hover,
    &:focus {
      z-index: 3;
      color: ${cvar('color-text-default')};
      background-color: ${cvar('color-hover')};
      border-color: ${cvar('color-border-default')};
      cursor: default;
    }
  }

  > .disabled {
    > a,
    > a:hover,
    > a:focus {
      color: ${cvar('color-border-default')};
      background-color: ${cvar('color-background')};
      border-color: ${cvar('color-border-default')};
      cursor: not-allowed;
    }
  }
`;

const noOuterMarginCss = css`
  margin-top: 0;
  margin-bottom: 0;
`;

export const Pagination = ({
  className,
  initialPage = 0,
  pagesOutsideElipses = 1,
  pagesBetweenElipses = 3,
  marginPagesDisplayed,
  pageRangeDisplayed,
  ...rest
}: PaginationProps) => {
  const { v17_noOuterSpacing } = useFeatureFlags();

  return (
    <ReactPaginate
      marginPagesDisplayed={pagesOutsideElipses}
      pageRangeDisplayed={pagesBetweenElipses}
      previousLabel="<"
      nextLabel=">"
      breakLabel="..."
      containerClassName={cx('crc-pagination', containerCss, v17_noOuterSpacing && noOuterMarginCss, className)}
      pageClassName="pageNumber"
      activeClassName="active pageNumber"
      previousClassName="arrow"
      nextClassName="arrow"
      breakClassName="ellipses"
      initialPage={initialPage}
      {...rest}
    />
  );
};
