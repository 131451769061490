import React from 'react';
import * as colors from '../colors';
import { ArrowDirection } from '../common';

export interface ArrowAltProps {
  className?: string;
  color?: string;
  direction?: ArrowDirection;
  outline?: string;
  outlineWidth?: string;
  size?: number;
}

export function ArrowAlt(props: ArrowAltProps) {
  const {
    className = '',
    color = '',
    direction = 'down',
    outline = colors.alloy,
    outlineWidth = '1px',
    size = 16,
    ...rest
  } = props;
  const transformDegrees = {
    down: 0,
    left: 90,
    up: 180,
    right: 270,
  };
  return (
    <svg
      className={className}
      fill={color}
      stroke={outline}
      strokeWidth={outlineWidth}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        d="M7.4,12.8c0.3,0.3,0.8,0.3,1.1,0l1.9-2.1l0,0L15.7,5C15.9,4.8,16,4.5,16,4.2c0-0.3-0.1-0.6-0.3-0.8c-0.4-0.4-1.1-0.4-1.5,0
        L8.5,9.4c-0.3,0.3-0.8,0.3-1.1,0L1.8,3.3c-0.4-0.4-1.1-0.4-1.5,0C0.1,3.6,0,3.8,0,4.2C0,4.5,0.1,4.8,0.3,5l6.2,6.7l0,0L7.4,12.8z"
        transform={`rotate(${transformDegrees[direction]} 8 8)`}
      />
    </svg>
  );
}
