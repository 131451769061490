import React from 'react';
import { css, cx } from '@emotion/css';
import { BlockButton } from './internal';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';

export interface ToggleProps extends PublicComponentProps {
  /**
   * Disables the switch. onClick function will not fire.
   */
  disabled?: boolean;

  /**
   * Text to display for the 'off' position of the switch. For best results, limit to 3 or fewer characters.
   */
  offText?: string;

  /**
   * Controls whether or not the switch is in the 'on' position.
   */
  on?: boolean;

  /**
   * Function to trigger when the toggle is clicked.
   */
  onClick?: (e: any) => void;

  /**
   * Text to display for the 'on' position of the switch. For best results, limit to 3 or fewer characters.
   */
  onText?: string;

  /**
   * Determines the size of the switch. Defaults to the larger size, pass 'sm' for a smaller toggle.
   */
  size?: 'sm';
}

const toggle = css`
  border-radius: ${cvar('spacing-2')};
  width: 96px;
  font-size: 14px;
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;

  .toggle-label {
    width: 58px;
    padding: ${cvar('spacing-8')};
    text-align: center;
    color: ${cvar('color-text-inverse')};
    font-weight: 600;
  }

  .toggle-switch {
    width: 30px;
    height: 30px;
    margin: 5px;
    background: ${cvar('color-background')};
    border-radius: ${cvar('spacing-2')};
  }
`;

const toggleOn = css`
  flex-direction: row;
  background: ${cvar('color-interactive')};
`;

const toggleOff = css`
  flex-direction: row-reverse;
  background: ${cvar('color-toggle-background-off')};
`;

const toggleDisabled = css`
  cursor: not-allowed;
  opacity: 0.65;
  background-color: ${cvar('color-background-disabled')};
  .toggle-label {
    color: ${cvar('color-text-disabled-dark')};
  }
`;

const toggleSmall = css`
  border-radius: 1px;
  width: 40px;
  height: ${cvar('spacing-16')};
  font-size: 0;
  justify-content: flex-end;
  .toggle-switch {
    width: 10px;
    height: 10px;
    margin: 3px;
    border-radius: 1px;
  }
  .toggle-label {
    display: none;
  }
`;

export const Toggle = ({
  className,
  disabled = false,
  offText = 'Off',
  on,
  onClick,
  onText = 'On',
  size,
  style,
  ...props
}: ToggleProps) => (
  <BlockButton
    className={cx(
      'crc-toggle',
      toggle,
      { [toggleSmall]: size === 'sm' },
      { [toggleOn]: on },
      { [toggleOff]: !on },
      { [toggleDisabled]: disabled },
      className,
    )}
    onClick={disabled ? undefined : onClick}
    style={style}
    {...props}
  >
    <div className="toggle-label">{on ? onText : offText}</div>
    <div className="toggle-switch" />
  </BlockButton>
);
