import React, { useState, useEffect, ReactNode, CSSProperties } from 'react';
import Collapse from 'react-smooth-collapse';
import { cx, css } from '@emotion/css';
import { BlockButton } from '../internal';
import cvar from '../theme/cvar';
import { ArrowAlt } from './ArrowAlt';
import { PublicComponentProps } from '../types';
import { useFeatureFlags } from '../FeatureFlags';

export interface AccordionProps extends PublicComponentProps {
  /** Optional actions displayed at the right-hand side of the accordion title area */
  actions?: ReactNode;
  /** Inline styles to be applied to the accordion body div */
  bodyStyle?: CSSProperties;
  /** Provide content for the accordion as children */
  children?: ReactNode;
  /** Can be used to customize when the accordion should be open. If this prop is used, defaultOpen will be ignored */
  customOpen?: boolean;
  /** Specify whether the accordion should be open or closed when it initially renders */
  defaultOpen?: boolean;
  /** Inline styles to be applied to the accordion header div */
  headerStyle?: CSSProperties;
  /** Function to be called when the accordion closes itself */
  onClose?: () => void;
  /** Function to be called when the component is clicked */
  onHeaderClick?: (e: any, isNowOpen: boolean) => void;
  /** Content for the title area of the accordion */
  title?: ReactNode;
  /** Determines the visual variant of the accordion */
  variant?: 'default' | 'ghost' | 'minimal';
}

const accordionBase = css({
  border: `1px solid ${cvar('color-border-light')}`,
  borderRadius: '2px',
  marginBottom: cvar('spacing-16'),
});

const accordionNoOuterMargin = css({
  marginBottom: 0,
});

const accordionGhostBase = css({
  border: 'none',
});

const accordionMinimalBase = css({
  border: 'none',
  padding: `${cvar('spacing-8')} 0px`,
});

const accordionHeader = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: cvar('color-background-light'),
  padding: cvar('spacing-16'),
  cursor: 'pointer',
  borderRadius: '2px',
});

const accordionGhostHeader = css({
  background: cvar('color-background'),
  border: `1px solid ${cvar('color-border-light')}`,
});

const accordionMinimalHeader = css({
  background: cvar('color-background'),
});

// TODO: Check whether or not .list-group and table rules below are still necessary
const accordionBody = css`
  padding: ${cvar('spacing-16')};
  .list-group {
    margin: -${cvar('spacing-16')};
    box-shadow: none;
    .list-group-item {
      border-color: ${cvar('color-border-light')};
      padding-left: ${cvar('spacing-16')};
      padding-right: ${cvar('spacing-16')};
      border-left: none;
      border-right: none;
      &:last-child {
        border-bottom: none;
      }
      &:first-child {
        border-top: none;
      }
    }
  }
  table {
    margin: -${cvar('spacing-16')};
  }
`;

const accordionMinimalBody = css({
  paddingTop: cvar('spacing-8'),
  background: cvar('color-background'),
});

export function Accordion(props: AccordionProps) {
  const {
    actions,
    bodyStyle,
    children,
    className = '',
    customOpen,
    defaultOpen = false,
    headerStyle,
    onClose,
    onHeaderClick,
    style = {},
    title,
    variant = 'default',
    ...rest
  } = props;
  const initialOpen = customOpen || defaultOpen;
  const [open, setOpen] = useState(initialOpen);
  const { v17_noOuterSpacing } = useFeatureFlags();

  useEffect(() => {
    if (!open) {
      onClose && onClose();
    }
  }, [onClose, open]);

  const onLocalHeaderClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setOpen(prevOpen => {
      onHeaderClick && onHeaderClick(event, !prevOpen);
      return !prevOpen;
    });
  };

  const onActionContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  useEffect(() => {
    setOpen(customOpen || defaultOpen);
  }, [customOpen, defaultOpen]);

  const ghostVariant = variant === 'ghost';
  const minimalVariant = variant === 'minimal';

  const headerButton = cx(accordionHeader, {
    [accordionGhostHeader]: ghostVariant,
    [accordionMinimalHeader]: minimalVariant,
  });

  return (
    <div
      className={cx('crc-accordion', accordionBase, className, v17_noOuterSpacing && accordionNoOuterMargin, {
        'accordion-open': open,
        [accordionGhostBase]: ghostVariant,
        [accordionMinimalBase]: minimalVariant,
      })}
      style={style}
      {...rest}
    >
      <BlockButton className={headerButton} onClick={onLocalHeaderClick} style={headerStyle}>
        <div className={css({ display: 'flex', alignItems: 'center', flexGrow: 1, marginRight: cvar('spacing-16') })}>
          <ArrowAlt
            className={css({ marginRight: cvar('spacing-16') })}
            color={cvar('color-button-primary')}
            direction={open ? 'down' : 'right'}
            size={14}
          />
          {title}
        </div>
        {actions && <BlockButton onClick={onActionContainerClick}>{actions}</BlockButton>}
      </BlockButton>
      <Collapse expanded={open}>
        <div className={cx(accordionBody, { [accordionMinimalBody]: minimalVariant })} style={bodyStyle}>
          {children}
        </div>
      </Collapse>
    </div>
  );
}
