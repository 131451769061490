import { useQuery } from 'react-query';
import { GetItemByIdParams, getItemByItemId } from '../clients/itemService';
import { RoutingUIError } from '../components/ErrorBoundary/RoutingUIError';
import { Item } from '../types';

type UseGetItemByItemIdProps = GetItemByIdParams & {
  enabled?: boolean;
};

export const useGetItemByItemId = (props: UseGetItemByItemIdProps) => {
  const { enabled = true } = props;
  return useQuery<Item, RoutingUIError | Error>(['itemService', props.itemId], () => getItemByItemId(props), {
    enabled,
  });
};
