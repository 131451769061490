/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import { Environment, Item } from '../../types';
import { RoutingDecisionListBody } from './RoutingDecisionListBody';
import { RoutingModal } from '../Modal/ModalComponents';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RetryContextProvider } from '../Retry/RetryContext';
import { RetryAlert } from '../Retry/RetryAlert';

const localModalStyles = css`
  // Allows the modal to be the accurate height for the content when the outermost
  // accordions are collapsed
  &:not(:has(.accordion-open)),
  &:not(:has(:is(.body-styles-only, .accordion-open .accordion-open))),
  &:has(.body-styles-only):not(:has(.accordion-open)) {
    height: auto;
  }
`;

type RoutingDecisionListProps = {
  environment?: Environment;
  accessToken: string;
  isModal?: boolean;
  showModal?: boolean;
  showBodyOnly?: boolean;
  showDecisionLinks?: boolean;
  closeModal?: () => void;
  items: Item[];
};

const RoutingDecisionList = ({
  isModal = false,
  showModal = false,
  environment = 'prd',
  closeModal,
  ...rest
}: RoutingDecisionListProps) => {
  const queryClient = new QueryClient();

  if (isModal) {
    return (
      <>
        <Global
          styles={css`
            @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
          `}
        />
        <RoutingModal
          modalHeaderText="Routing Decisions"
          showModal={showModal}
          closeModal={closeModal}
          styles={localModalStyles}
          testId="routing-decision-list-modal"
        >
          <QueryClientProvider client={queryClient}>
            <RetryContextProvider>
              <RetryAlert />
              <RoutingDecisionListBody environment={environment} {...rest} />
            </RetryContextProvider>
          </QueryClientProvider>
        </RoutingModal>
      </>
    );
  }

  return (
    <>
      {' '}
      <Global
        styles={css`
          @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
        `}
      />
      <QueryClientProvider client={queryClient}>
        <RetryContextProvider>
          <RetryAlert />
          <RoutingDecisionListBody environment={environment} {...rest} />
        </RetryContextProvider>
      </QueryClientProvider>
    </>
  );
};

export default RoutingDecisionList;
