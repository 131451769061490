/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { get } from 'lodash';
import { useContext } from 'react';
import { Button } from '@cimpress/react-components';
import { RetryContext } from '../Retry/RetryContext';
import { Environment, Item } from '../../types';

export const RetryAllButton = ({
  environment,
  accessToken,
  items,
}: {
  environment: Environment;
  accessToken: string;
  items: Item[];
}) => {
  const { retryRoutingRequest, setRetryState, retryState } = useContext(RetryContext);
  const isRetrying = retryState === 'PENDING';

  const handleRetry = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Don't close the accordion when the retry button is clicked
    event.stopPropagation();

    if (!items.length || !retryRoutingRequest) {
      return;
    }

    const routingRequestId = get(items[0], ['_links', 'routingRequest', 'name']) as string | undefined;
    if (!routingRequestId) {
      return;
    }

    setRetryState('PENDING');

    retryRoutingRequest.mutate({
      environment,
      accessToken,
      routingRequestId,
    });
  };

  return (
    <Button
      variant="default"
      disabled={isRetrying}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleRetry(event)}
      className="retry-all-button"
      data-testid="retry-all-button"
    >
      {isRetrying ? 'Retrying all...' : 'Retry all'}
    </Button>
  );
};

export const ItemsAccordionHeader = ({
  environment,
  accessToken,
  hasSuspendedItems,
  items,
}: {
  environment: Environment;
  accessToken: string;
  hasSuspendedItems: boolean;
  items: Item[];
}) => {
  if (!hasSuspendedItems) {
    return <span>Items</span>;
  }

  const headerStyles = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `;

  return (
    <div css={headerStyles} data-testid="items-accordion-with-retry-all">
      <span>Items</span>
      <RetryAllButton environment={environment} accessToken={accessToken} items={items} />
    </div>
  );
};
