import React, { ReactElement, ReactNode } from 'react';
import { PropInfo } from './PropInfo';
import { PropsTable } from './PropsTable';

export interface ComponentDocProps<T> {
  /**
   * Children for the component doc, usually a demo of the component and the code example.
   */
  children?: ReactNode;

  /**
   * Header for the code demo, if desired.
   */
  demoName?: string;

  /**
   * The name of the component being documented.
   */
  name: string;

  /**
   * Array of `PropInfo` components to document the props.
   */
  propInfos: PropInfo<T>[];

  /**
   * Additional remarks or notes about the component.
   */
  remarks?: ReactElement<any>;
}

export function ComponentDoc<T>({ name, demoName = 'Demo', propInfos = [], remarks, children }: ComponentDocProps<T>) {
  return (
    <div>
      <div className="row" style={{ paddingBottom: '10px' }}>
        <div className="col-md-12">
          <h2>{name}</h2>
          {propInfos.length ? <PropsTable propInfos={propInfos} /> : null}
          <div>{remarks}</div>
        </div>
      </div>

      {children ? (
        <div className="row" style={{ paddingBottom: '30px' }}>
          <div className="col-md-12">
            <h3>{demoName}</h3>
            {children}
          </div>
        </div>
      ) : null}
    </div>
  );
}
