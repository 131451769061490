import React from 'react';
import { alloy } from '../../colors';

export interface CircleProps {
  /** The class name of the circle's svg, defaults to 'default'. */
  className?: string;
  /** The fill property of the circle's svg, defaults to ''. */
  color?: string;
  /** The width and height properties of the circle's svg, defaults to '14'. */
  width?: number;
  /** The stroke property of the circle's svg, defaults to alloy color. */
  outline?: string;
  /** The strokeWidth property of the circle's svg, defaults to '1px'. */
  outlineWidth?: string;
}

export const Circle = ({
  className = 'default',
  color = '',
  width = 14,
  outline = alloy,
  outlineWidth = '1px',
  ...rest
}: CircleProps) => (
  <svg
    className={className}
    fill={color}
    stroke={outline}
    strokeWidth={outlineWidth}
    width={width}
    height={width}
    viewBox="0 0 18 18"
    {...rest}
  >
    <circle cx="9" cy="9" r="8" />
  </svg>
);
