import React from 'react';
import { TableColumnProps } from '../types';
import { Robot } from '../../Robot';

export interface EmptyTableRowProps {
  columns: TableColumnProps[];
  message?: string;
}

const EmptyTableRow: React.FC<EmptyTableRowProps> = ({ columns, message }) => (
  <tr>
    <td colSpan={columns.length} align="center">
      <Robot status="info" size="sm" />
      {message}
    </td>
  </tr>
);

export { EmptyTableRow };
