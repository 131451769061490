import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { cx, css } from '@emotion/css';
import ReactDOM from 'react-dom';
import cvar from './theme/cvar';
import { AlertStateless } from './Alert';
import { BREAKPOINT_M } from './breakpoint';
import { PublicComponentProps } from './types';

type Status = 'danger' | 'info' | 'success' | 'warning';
export interface SnackbarProps extends PublicComponentProps {
  /**
   * One of the four validation styles: info, success, warning, or danger.
   */
  status?: Status;

  /**
   * Snackbar renders any children given to it as contents on the right-hand side of the snackbar.
   */
  children?: ReactNode;

  /**
   * How long to display the snackbar before autodismissing, in milliseconds.
   * If not provided or if a falsy delay is given, the snackbar must be manually dismissed.
   */
  delay?: number;

  /**
   * Callback function to be called when the snackbar auto-dismisses or is closed.
   */
  onHideSnackbar?: () => void;

  /**
   * Controls whether or not the snackbar is hidden.
   */
  show?: boolean;
}

// from npm package react-material-snackbar which would not build
export const Snackbar = (props: SnackbarProps) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [localHeight, setLocalHeight] = useState(60);

  const { show, delay, onHideSnackbar, className, style, status, ...rest } = props;
  const el: any = useRef(null);

  const hideSnackbar = useCallback(() => {
    setShowSnackbar(false);
    onHideSnackbar && onHideSnackbar();
  }, [onHideSnackbar]);

  const setHeight = () => {
    if (el && el.clientHeight && localHeight !== el.clientHeight) {
      setLocalHeight(el.clientHeight);
    }
  };

  useEffect(() => {
    setShowSnackbar(!!show);
    let timer: ReturnType<typeof setTimeout>;
    if (delay && show) {
      timer = setTimeout(hideSnackbar, delay);
    }
    return () => clearTimeout(timer);
  }, [delay, hideSnackbar, show]);

  const snackbarCss = css({
    position: 'fixed',
    left: 0,
    right: 0,
    top: `-${localHeight + 30}px`,
    margin: `0 ${cvar('spacing-48')} 0 ${cvar('spacing-48')}`,
    justifyContent: 'center',
    transitionTimingFunction: 'ease-in-out',
    transition: '300ms',
    transform: showSnackbar ? `translateY(${localHeight + 50}px)` : 'translateY(0px)',
    zIndex: 2000,
    visibility: showSnackbar ? 'visible' : 'hidden',
    backgroundColor: cvar('color-background'),
    boxShadow: '0 4px 6px 2px rgb(0 0 0 / 10%)',
  });

  const snackBarMediaQuery = css`
    @media screen and (min-width: ${BREAKPOINT_M}) {
      margin: auto;
      width: 900px;
    }
  `;

  setHeight();

  const snackbarNode = (
    <AlertStateless
      status={status}
      message={props.children}
      className={cx('crc-snackbar', snackbarCss, snackBarMediaQuery, className)}
      style={style}
      onDismiss={hideSnackbar}
      {...rest}
    />
  );

  return ReactDOM.createPortal(snackbarNode, document.body);
};
