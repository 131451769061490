import { cobalt, info, persimmon, platinum, shale, silver, slate, success, teal, warning } from '../colors';

export default {
  // light theme
  'light-color-code-base': shale,
  'light-color-code-background': silver,
  'light-color-code-border': platinum,
  'light-color-code-string': warning.darker,

  // dark theme
  'dark-color-code-base': slate,
  'dark-color-code-background': cobalt.base,
  'dark-color-code-border': cobalt.base,
  'dark-color-code-string': warning.base,

  // both
  'color-code-comment': slate,
  'color-code-variable': success.base,
  'color-code-literal': persimmon.base,
  'color-code-title': info.base,
  'color-code-keyword': teal.base,
};
