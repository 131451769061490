import { get } from 'lodash';
import { Item } from '../types';

export const getRoutingRequestUrlFromItem = (item: Item | undefined): string | undefined =>
  get(item, ['_links', 'routingRequest', 'href']) as string | undefined;

export const isItemSuspendedInRouting = (item: Item | undefined): boolean => {
  return (
    item?.statuses?.routing?.state === 'current' &&
    (item?.statuses?.suspended?.state === 'current' || item?.statuses?.onHold?.state === 'current')
  );
};
