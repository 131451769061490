import { cx, css } from '@emotion/css';
import React, { ReactNode } from 'react';
import { BlockButton } from '../internal';
import cvar from '../theme/cvar';

export type Direction = 'next' | 'prev';

export interface CarouselIndicatorProps {
  active: boolean;
  children: ReactNode;
  goToSlide: (slideNumber: number) => void;
  slideNumber: number;
  offset: {
    top?: number;
    button?: number;
    left?: number;
    right?: number;
  };
}

const activeCss = css`
  border: 1px solid ${cvar('color-interactive')};
`;

const inactiveCss = css`
  border: 1px solid ${cvar('color-border-light')};
`;

const CarouselIndicator = ({ active, children, goToSlide, slideNumber, offset }: CarouselIndicatorProps) => {
  const onSlideClick = () => {
    goToSlide(slideNumber);
  };

  return (
    <BlockButton
      as="li"
      style={{ ...offset, textIndent: '0', overflow: 'hidden' }}
      className={cx({ [activeCss]: active, [inactiveCss]: !active })}
      onClick={onSlideClick}
    >
      {children}
    </BlockButton>
  );
};

export default CarouselIndicator;
