import emojiFlags from './isoUnicode';

const CURRENCY_CODES = [
  { value: 'AUD', flag: emojiFlags.AU, label: 'Australia Dollar', country: 'Australia', symbol: 'A$', iso2: 'AU' },
  { value: 'BRL', flag: emojiFlags.BR, label: 'Brazil Real', country: 'Brazil', symbol: 'R$', iso2: 'BR' },
  { value: 'CAD', flag: emojiFlags.CA, label: 'Canada Dollar', country: 'Canada', symbol: 'C$', iso2: 'CA' },
  { value: 'CHF', flag: emojiFlags.CH, label: 'Switzerland Franc', country: 'Switzerland', symbol: 'Fr', iso2: 'CH' },
  { value: 'CNY', flag: emojiFlags.CN, label: 'China Yuan Renminbi', country: 'China', symbol: '元', iso2: 'CN' },
  {
    value: 'CZK',
    flag: emojiFlags.CZ,
    label: 'Czech Republic Koruna',
    country: 'Czech Republic',
    symbol: 'Kč',
    iso2: 'CZ',
  },
  { value: 'DKK', flag: emojiFlags.DK, label: 'Denmark Krone', country: 'Denmark', symbol: 'kr', iso2: 'DK' },
  { value: 'EUR', flag: emojiFlags.EU, label: 'Euro', country: 'European Union', symbol: '€', iso2: 'EU' },
  {
    value: 'GBP',
    flag: emojiFlags.GB,
    label: 'Great Britain Pound',
    country: 'Great Britain',
    symbol: '£',
    iso2: 'GB',
  },
  { value: 'HKD', flag: emojiFlags.HK, label: 'Hong Kong Dollar', country: 'Hong Kong', symbol: 'HK$', iso2: 'HK' },
  { value: 'HRK', flag: emojiFlags.HR, label: 'Croatia Kuna', country: 'Croatia', symbol: 'kn', iso2: 'HR' },
  { value: 'HUF', flag: emojiFlags.HU, label: 'Hungary Forint', country: 'Hungary', symbol: 'Ft', iso2: 'HU' },
  { value: 'INR', flag: emojiFlags.IN, label: 'India Rupee', country: 'India', symbol: 'INR', iso2: 'IN' },
  { value: 'JMD', flag: emojiFlags.JM, label: 'Jamaica Dollar', country: 'Jamaica', symbol: 'J$', iso2: 'JM' },
  { value: 'JPY', flag: emojiFlags.JP, label: 'Japan Yen', country: 'Japan', symbol: '¥', iso2: 'JP' },
  { value: 'KRW', flag: emojiFlags.KR, label: 'Korea (South) Won', country: 'South Korea', symbol: '₩', iso2: 'KR' },
  { value: 'MYR', flag: emojiFlags.MY, label: 'Malaysia Ringgit', country: 'Malaysia', symbol: 'RM', iso2: 'MY' },
  { value: 'NOK', flag: emojiFlags.NO, label: 'Norway Krone', country: 'Norway', symbol: 'kr', iso2: 'NO' },
  { value: 'NZD', flag: emojiFlags.NZ, label: 'New Zealand Dollar', country: 'New Zealand', symbol: 'NZ$', iso2: 'NZ' },
  { value: 'PHP', flag: emojiFlags.PH, label: 'Philippines Peso', country: 'Phillippines', symbol: '₱', iso2: 'PH' },
  { value: 'PLN', flag: emojiFlags.PL, label: 'Poland Zloty', country: 'Poland', symbol: 'zł', iso2: 'PL' },
  { value: 'SEK', flag: emojiFlags.SE, label: 'Sweden Krona', country: 'Sweden', symbol: 'kr', iso2: 'SE' },
  { value: 'SGD', flag: emojiFlags.SG, label: 'Singapore Dollar', country: 'Singapore', symbol: 'S$', iso2: 'SG' },
  { value: 'THB', flag: emojiFlags.TH, label: 'Thailand Baht', country: 'Thailand', symbol: '฿', iso2: 'TH' },
  { value: 'TND', flag: emojiFlags.TN, label: 'Tunisia Dinar', country: 'Tunisia', symbol: 'DT', iso2: 'TN' },
  { value: 'TRY', flag: emojiFlags.TR, label: 'Turkey Lira', country: 'Turkey', symbol: 'TL', iso2: 'TR' },
  { value: 'TWD', flag: emojiFlags.TW, label: 'Taiwan New Dollar', country: 'Taiwan', symbol: 'NT$', iso2: 'TW' },
  { value: 'USD', flag: emojiFlags.US, label: 'United States Dollar', country: 'America', symbol: '$', iso2: 'US' },
  { value: 'ZAR', flag: emojiFlags.ZA, label: 'South Africa Rand', country: 'South Africa', symbol: 'R', iso2: 'ZA' },
];

export default CURRENCY_CODES;
