import { css } from '@emotion/css';
import { alloy, cobalt, white, ocean } from '../colors';

const createStyle = ({ checkbox, label }: { checkbox: string; label: string }) => css`
  ${checkbox} {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
    margin: 10px 0;

    & + ${label} {
      position: relative;
      display: inline-flex;
      height: 18px;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      vertical-align: middle;
      cursor: pointer;
      padding: 0;
      font-weight: 400;
      color: ${cobalt.base};
    }

    // Box.
    & + ${label} > :first-child {
      display: inline-flex;
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      border-radius: 1px;
      user-select: none;

      & > svg {
        display: none;
        width: 10px;
        height: 10px;
        flex-shrink: 0;
        color: ${white};
      }
    }

    // Default box when not checked, indeterminate, hovered, focused, or disabled
    &:not(:checked):not(.focusable-checkbox-indeterminate):not(:hover):not(:focus):not(:disabled)
      + ${label}
      > :first-child {
      border: 2px solid ${cobalt.base};
      background: ${white};
    }

    // Hovered box when not disabled, or Focused box when not checked
    &:not(:disabled):hover + ${label} > :first-child,
    &:not(:checked):focus + ${label} > :first-child {
      border: 2px solid ${ocean.darker};
    }

    // Focused box when checked or indeterminate
    &:focus + ${label} > :first-child {
      box-shadow: 0px 0px 3px ${ocean.darker};
    }

    // Box checked
    &:checked + ${label} > :first-child,
    &.focusable-checkbox-indeterminate + ${label} > :first-child {
      background: ${ocean.darker};
      outline: 0;
    }

    // Icons when checked
    &:checked:not(.focusable-checkbox-indeterminate) + ${label} .focusable-checkbox__check-icon,
    &.focusable-checkbox-indeterminate + ${label} .focusable-checkbox__indeterminate-icon {
      display: block;
    }

    // Disabled state ${label}.
    &:disabled + ${label} {
      color: ${alloy};
      cursor: auto;
    }

    // Disabled box when not checked
    &:not(:checked):disabled + ${label} > :first-child {
      box-shadow: none;
      border: 2px solid ${alloy};
    }

    // Disabled box when checked or indeterminate
    &:checked:disabled + ${label} > :first-child,
    &.focusable-checkbox-indeterminate:disabled + ${label} > :first-child {
      background: ${alloy};
    }
  }
`;

export default createStyle;
