const timeZones = [
  {
    value: 'Africa/Abidjan',
    label: 'Africa/Abidjan (UTC+00:00)',
  },
  {
    value: 'Africa/Accra',
    label: 'Africa/Accra (UTC+00:00)',
  },
  {
    value: 'Africa/Addis_Ababa',
    label: 'Africa/Addis_Ababa (UTC+03:00)',
  },
  {
    value: 'Africa/Algiers',
    label: 'Africa/Algiers (UTC+01:00)',
  },
  {
    value: 'Africa/Asmara',
    label: 'Africa/Asmara (UTC+03:00)',
  },
  {
    value: 'Africa/Asmera',
    label: 'Africa/Asmera (UTC+03:00)',
  },
  {
    value: 'Africa/Bamako',
    label: 'Africa/Bamako (UTC+00:00)',
  },
  {
    value: 'Africa/Bangui',
    label: 'Africa/Bangui (UTC+01:00)',
  },
  {
    value: 'Africa/Banjul',
    label: 'Africa/Banjul (UTC+00:00)',
  },
  {
    value: 'Africa/Bissau',
    label: 'Africa/Bissau (UTC+00:00)',
  },
  {
    value: 'Africa/Blantyre',
    label: 'Africa/Blantyre (UTC+02:00)',
  },
  {
    value: 'Africa/Brazzaville',
    label: 'Africa/Brazzaville (UTC+01:00)',
  },
  {
    value: 'Africa/Bujumbura',
    label: 'Africa/Bujumbura (UTC+02:00)',
  },
  {
    value: 'Africa/Cairo',
    label: 'Africa/Cairo (UTC+02:00)',
  },
  {
    value: 'Africa/Casablanca',
    label: 'Africa/Casablanca (UTC+01:00)',
  },
  {
    value: 'Africa/Ceuta',
    label: 'Africa/Ceuta (UTC+02:00)',
  },
  {
    value: 'Africa/Conakry',
    label: 'Africa/Conakry (UTC+00:00)',
  },
  {
    value: 'Africa/Dakar',
    label: 'Africa/Dakar (UTC+00:00)',
  },
  {
    value: 'Africa/Dar_es_Salaam',
    label: 'Africa/Dar_es_Salaam (UTC+03:00)',
  },
  {
    value: 'Africa/Djibouti',
    label: 'Africa/Djibouti (UTC+03:00)',
  },
  {
    value: 'Africa/Douala',
    label: 'Africa/Douala (UTC+01:00)',
  },
  {
    value: 'Africa/El_Aaiun',
    label: 'Africa/El_Aaiun (UTC+01:00)',
  },
  {
    value: 'Africa/Freetown',
    label: 'Africa/Freetown (UTC+00:00)',
  },
  {
    value: 'Africa/Gaborone',
    label: 'Africa/Gaborone (UTC+02:00)',
  },
  {
    value: 'Africa/Harare',
    label: 'Africa/Harare (UTC+02:00)',
  },
  {
    value: 'Africa/Johannesburg',
    label: 'Africa/Johannesburg (UTC+02:00)',
  },
  {
    value: 'Africa/Juba',
    label: 'Africa/Juba (UTC+03:00)',
  },
  {
    value: 'Africa/Kampala',
    label: 'Africa/Kampala (UTC+03:00)',
  },
  {
    value: 'Africa/Khartoum',
    label: 'Africa/Khartoum (UTC+02:00)',
  },
  {
    value: 'Africa/Kigali',
    label: 'Africa/Kigali (UTC+02:00)',
  },
  {
    value: 'Africa/Kinshasa',
    label: 'Africa/Kinshasa (UTC+01:00)',
  },
  {
    value: 'Africa/Lagos',
    label: 'Africa/Lagos (UTC+01:00)',
  },
  {
    value: 'Africa/Libreville',
    label: 'Africa/Libreville (UTC+01:00)',
  },
  {
    value: 'Africa/Lome',
    label: 'Africa/Lome (UTC+00:00)',
  },
  {
    value: 'Africa/Luanda',
    label: 'Africa/Luanda (UTC+01:00)',
  },
  {
    value: 'Africa/Lubumbashi',
    label: 'Africa/Lubumbashi (UTC+02:00)',
  },
  {
    value: 'Africa/Lusaka',
    label: 'Africa/Lusaka (UTC+02:00)',
  },
  {
    value: 'Africa/Malabo',
    label: 'Africa/Malabo (UTC+01:00)',
  },
  {
    value: 'Africa/Maputo',
    label: 'Africa/Maputo (UTC+02:00)',
  },
  {
    value: 'Africa/Maseru',
    label: 'Africa/Maseru (UTC+02:00)',
  },
  {
    value: 'Africa/Mbabane',
    label: 'Africa/Mbabane (UTC+02:00)',
  },
  {
    value: 'Africa/Mogadishu',
    label: 'Africa/Mogadishu (UTC+03:00)',
  },
  {
    value: 'Africa/Monrovia',
    label: 'Africa/Monrovia (UTC+00:00)',
  },
  {
    value: 'Africa/Nairobi',
    label: 'Africa/Nairobi (UTC+03:00)',
  },
  {
    value: 'Africa/Ndjamena',
    label: 'Africa/Ndjamena (UTC+01:00)',
  },
  {
    value: 'Africa/Niamey',
    label: 'Africa/Niamey (UTC+01:00)',
  },
  {
    value: 'Africa/Nouakchott',
    label: 'Africa/Nouakchott (UTC+00:00)',
  },
  {
    value: 'Africa/Ouagadougou',
    label: 'Africa/Ouagadougou (UTC+00:00)',
  },
  {
    value: 'Africa/Porto-Novo',
    label: 'Africa/Porto-Novo (UTC+01:00)',
  },
  {
    value: 'Africa/Sao_Tome',
    label: 'Africa/Sao_Tome (UTC+01:00)',
  },
  {
    value: 'Africa/Timbuktu',
    label: 'Africa/Timbuktu (UTC+00:00)',
  },
  {
    value: 'Africa/Tripoli',
    label: 'Africa/Tripoli (UTC+02:00)',
  },
  {
    value: 'Africa/Tunis',
    label: 'Africa/Tunis (UTC+01:00)',
  },
  {
    value: 'Africa/Windhoek',
    label: 'Africa/Windhoek (UTC+02:00)',
  },
  {
    value: 'America/Adak',
    label: 'America/Adak (UTC-09:00)',
  },
  {
    value: 'America/Anchorage',
    label: 'America/Anchorage (UTC-08:00)',
  },
  {
    value: 'America/Anguilla',
    label: 'America/Anguilla (UTC-04:00)',
  },
  {
    value: 'America/Antigua',
    label: 'America/Antigua (UTC-04:00)',
  },
  {
    value: 'America/Araguaina',
    label: 'America/Araguaina (UTC-03:00)',
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    label: 'America/Argentina/Buenos_Aires (UTC-03:00)',
  },
  {
    value: 'America/Argentina/Catamarca',
    label: 'America/Argentina/Catamarca (UTC-03:00)',
  },
  {
    value: 'America/Argentina/ComodRivadavia',
    label: 'America/Argentina/ComodRivadavia (UTC-03:00)',
  },
  {
    value: 'America/Argentina/Cordoba',
    label: 'America/Argentina/Cordoba (UTC-03:00)',
  },
  {
    value: 'America/Argentina/Jujuy',
    label: 'America/Argentina/Jujuy (UTC-03:00)',
  },
  {
    value: 'America/Argentina/La_Rioja',
    label: 'America/Argentina/La_Rioja (UTC-03:00)',
  },
  {
    value: 'America/Argentina/Mendoza',
    label: 'America/Argentina/Mendoza (UTC-03:00)',
  },
  {
    value: 'America/Argentina/Rio_Gallegos',
    label: 'America/Argentina/Rio_Gallegos (UTC-03:00)',
  },
  {
    value: 'America/Argentina/Salta',
    label: 'America/Argentina/Salta (UTC-03:00)',
  },
  {
    value: 'America/Argentina/San_Juan',
    label: 'America/Argentina/San_Juan (UTC-03:00)',
  },
  {
    value: 'America/Argentina/San_Luis',
    label: 'America/Argentina/San_Luis (UTC-03:00)',
  },
  {
    value: 'America/Argentina/Tucuman',
    label: 'America/Argentina/Tucuman (UTC-03:00)',
  },
  {
    value: 'America/Argentina/Ushuaia',
    label: 'America/Argentina/Ushuaia (UTC-03:00)',
  },
  {
    value: 'America/Aruba',
    label: 'America/Aruba (UTC-04:00)',
  },
  {
    value: 'America/Asuncion',
    label: 'America/Asuncion (UTC-04:00)',
  },
  {
    value: 'America/Atikokan',
    label: 'America/Atikokan (UTC-05:00)',
  },
  {
    value: 'America/Atka',
    label: 'America/Atka (UTC-09:00)',
  },
  {
    value: 'America/Bahia',
    label: 'America/Bahia (UTC-03:00)',
  },
  {
    value: 'America/Bahia_Banderas',
    label: 'America/Bahia_Banderas (UTC-05:00)',
  },
  {
    value: 'America/Barbados',
    label: 'America/Barbados (UTC-04:00)',
  },
  {
    value: 'America/Belem',
    label: 'America/Belem (UTC-03:00)',
  },
  {
    value: 'America/Belize',
    label: 'America/Belize (UTC-06:00)',
  },
  {
    value: 'America/Blanc-Sablon',
    label: 'America/Blanc-Sablon (UTC-04:00)',
  },
  {
    value: 'America/Boa_Vista',
    label: 'America/Boa_Vista (UTC-04:00)',
  },
  {
    value: 'America/Bogota',
    label: 'America/Bogota (UTC-05:00)',
  },
  {
    value: 'America/Boise',
    label: 'America/Boise (UTC-06:00)',
  },
  {
    value: 'America/Buenos_Aires',
    label: 'America/Buenos_Aires (UTC-03:00)',
  },
  {
    value: 'America/Cambridge_Bay',
    label: 'America/Cambridge_Bay (UTC-06:00)',
  },
  {
    value: 'America/Campo_Grande',
    label: 'America/Campo_Grande (UTC-04:00)',
  },
  {
    value: 'America/Cancun',
    label: 'America/Cancun (UTC-05:00)',
  },
  {
    value: 'America/Caracas',
    label: 'America/Caracas (UTC-04:00)',
  },
  {
    value: 'America/Catamarca',
    label: 'America/Catamarca (UTC-03:00)',
  },
  {
    value: 'America/Cayenne',
    label: 'America/Cayenne (UTC-03:00)',
  },
  {
    value: 'America/Cayman',
    label: 'America/Cayman (UTC-05:00)',
  },
  {
    value: 'America/Chicago',
    label: 'America/Chicago (UTC-05:00)',
  },
  {
    value: 'America/Chihuahua',
    label: 'America/Chihuahua (UTC-06:00)',
  },
  {
    value: 'America/Coral_Harbour',
    label: 'America/Coral_Harbour (UTC-05:00)',
  },
  {
    value: 'America/Cordoba',
    label: 'America/Cordoba (UTC-03:00)',
  },
  {
    value: 'America/Costa_Rica',
    label: 'America/Costa_Rica (UTC-06:00)',
  },
  {
    value: 'America/Creston',
    label: 'America/Creston (UTC-07:00)',
  },
  {
    value: 'America/Cuiaba',
    label: 'America/Cuiaba (UTC-04:00)',
  },
  {
    value: 'America/Curacao',
    label: 'America/Curacao (UTC-04:00)',
  },
  {
    value: 'America/Danmarkshavn',
    label: 'America/Danmarkshavn (UTC+00:00)',
  },
  {
    value: 'America/Dawson',
    label: 'America/Dawson (UTC-07:00)',
  },
  {
    value: 'America/Dawson_Creek',
    label: 'America/Dawson_Creek (UTC-07:00)',
  },
  {
    value: 'America/Denver',
    label: 'America/Denver (UTC-06:00)',
  },
  {
    value: 'America/Detroit',
    label: 'America/Detroit (UTC-04:00)',
  },
  {
    value: 'America/Dominica',
    label: 'America/Dominica (UTC-04:00)',
  },
  {
    value: 'America/Edmonton',
    label: 'America/Edmonton (UTC-06:00)',
  },
  {
    value: 'America/Eirunepe',
    label: 'America/Eirunepe (UTC-05:00)',
  },
  {
    value: 'America/El_Salvador',
    label: 'America/El_Salvador (UTC-06:00)',
  },
  {
    value: 'America/Ensenada',
    label: 'America/Ensenada (UTC-07:00)',
  },
  {
    value: 'America/Fort_Nelson',
    label: 'America/Fort_Nelson (UTC-07:00)',
  },
  {
    value: 'America/Fort_Wayne',
    label: 'America/Fort_Wayne (UTC-04:00)',
  },
  {
    value: 'America/Fortaleza',
    label: 'America/Fortaleza (UTC-03:00)',
  },
  {
    value: 'America/Glace_Bay',
    label: 'America/Glace_Bay (UTC-03:00)',
  },
  {
    value: 'America/Godthab',
    label: 'America/Godthab (UTC-02:00)',
  },
  {
    value: 'America/Goose_Bay',
    label: 'America/Goose_Bay (UTC-03:00)',
  },
  {
    value: 'America/Grand_Turk',
    label: 'America/Grand_Turk (UTC-04:00)',
  },
  {
    value: 'America/Grenada',
    label: 'America/Grenada (UTC-04:00)',
  },
  {
    value: 'America/Guadeloupe',
    label: 'America/Guadeloupe (UTC-04:00)',
  },
  {
    value: 'America/Guatemala',
    label: 'America/Guatemala (UTC-06:00)',
  },
  {
    value: 'America/Guayaquil',
    label: 'America/Guayaquil (UTC-05:00)',
  },
  {
    value: 'America/Guyana',
    label: 'America/Guyana (UTC-04:00)',
  },
  {
    value: 'America/Halifax',
    label: 'America/Halifax (UTC-03:00)',
  },
  {
    value: 'America/Havana',
    label: 'America/Havana (UTC-04:00)',
  },
  {
    value: 'America/Hermosillo',
    label: 'America/Hermosillo (UTC-07:00)',
  },
  {
    value: 'America/Indiana/Indianapolis',
    label: 'America/Indiana/Indianapolis (UTC-04:00)',
  },
  {
    value: 'America/Indiana/Knox',
    label: 'America/Indiana/Knox (UTC-05:00)',
  },
  {
    value: 'America/Indiana/Marengo',
    label: 'America/Indiana/Marengo (UTC-04:00)',
  },
  {
    value: 'America/Indiana/Petersburg',
    label: 'America/Indiana/Petersburg (UTC-04:00)',
  },
  {
    value: 'America/Indiana/Tell_City',
    label: 'America/Indiana/Tell_City (UTC-05:00)',
  },
  {
    value: 'America/Indiana/Vevay',
    label: 'America/Indiana/Vevay (UTC-04:00)',
  },
  {
    value: 'America/Indiana/Vincennes',
    label: 'America/Indiana/Vincennes (UTC-04:00)',
  },
  {
    value: 'America/Indiana/Winamac',
    label: 'America/Indiana/Winamac (UTC-04:00)',
  },
  {
    value: 'America/Indianapolis',
    label: 'America/Indianapolis (UTC-04:00)',
  },
  {
    value: 'America/Inuvik',
    label: 'America/Inuvik (UTC-06:00)',
  },
  {
    value: 'America/Iqaluit',
    label: 'America/Iqaluit (UTC-04:00)',
  },
  {
    value: 'America/Jamaica',
    label: 'America/Jamaica (UTC-05:00)',
  },
  {
    value: 'America/Jujuy',
    label: 'America/Jujuy (UTC-03:00)',
  },
  {
    value: 'America/Juneau',
    label: 'America/Juneau (UTC-08:00)',
  },
  {
    value: 'America/Kentucky/Louisville',
    label: 'America/Kentucky/Louisville (UTC-04:00)',
  },
  {
    value: 'America/Kentucky/Monticello',
    label: 'America/Kentucky/Monticello (UTC-04:00)',
  },
  {
    value: 'America/Knox_IN',
    label: 'America/Knox_IN (UTC-05:00)',
  },
  {
    value: 'America/Kralendijk',
    label: 'America/Kralendijk (UTC-04:00)',
  },
  {
    value: 'America/La_Paz',
    label: 'America/La_Paz (UTC-04:00)',
  },
  {
    value: 'America/Lima',
    label: 'America/Lima (UTC-05:00)',
  },
  {
    value: 'America/Los_Angeles',
    label: 'America/Los_Angeles (UTC-07:00)',
  },
  {
    value: 'America/Louisville',
    label: 'America/Louisville (UTC-04:00)',
  },
  {
    value: 'America/Lower_Princes',
    label: 'America/Lower_Princes (UTC-04:00)',
  },
  {
    value: 'America/Maceio',
    label: 'America/Maceio (UTC-03:00)',
  },
  {
    value: 'America/Managua',
    label: 'America/Managua (UTC-06:00)',
  },
  {
    value: 'America/Manaus',
    label: 'America/Manaus (UTC-04:00)',
  },
  {
    value: 'America/Marigot',
    label: 'America/Marigot (UTC-04:00)',
  },
  {
    value: 'America/Martinique',
    label: 'America/Martinique (UTC-04:00)',
  },
  {
    value: 'America/Matamoros',
    label: 'America/Matamoros (UTC-05:00)',
  },
  {
    value: 'America/Mazatlan',
    label: 'America/Mazatlan (UTC-06:00)',
  },
  {
    value: 'America/Mendoza',
    label: 'America/Mendoza (UTC-03:00)',
  },
  {
    value: 'America/Menominee',
    label: 'America/Menominee (UTC-05:00)',
  },
  {
    value: 'America/Merida',
    label: 'America/Merida (UTC-05:00)',
  },
  {
    value: 'America/Metlakatla',
    label: 'America/Metlakatla (UTC-08:00)',
  },
  {
    value: 'America/Mexico_City',
    label: 'America/Mexico_City (UTC-05:00)',
  },
  {
    value: 'America/Miquelon',
    label: 'America/Miquelon (UTC-02:00)',
  },
  {
    value: 'America/Moncton',
    label: 'America/Moncton (UTC-03:00)',
  },
  {
    value: 'America/Monterrey',
    label: 'America/Monterrey (UTC-05:00)',
  },
  {
    value: 'America/Montevideo',
    label: 'America/Montevideo (UTC-03:00)',
  },
  {
    value: 'America/Montreal',
    label: 'America/Montreal (UTC-04:00)',
  },
  {
    value: 'America/Montserrat',
    label: 'America/Montserrat (UTC-04:00)',
  },
  {
    value: 'America/Nassau',
    label: 'America/Nassau (UTC-04:00)',
  },
  {
    value: 'America/New_York',
    label: 'America/New_York (UTC-04:00)',
  },
  {
    value: 'America/Nipigon',
    label: 'America/Nipigon (UTC-04:00)',
  },
  {
    value: 'America/Nome',
    label: 'America/Nome (UTC-08:00)',
  },
  {
    value: 'America/Noronha',
    label: 'America/Noronha (UTC-02:00)',
  },
  {
    value: 'America/North_Dakota/Beulah',
    label: 'America/North_Dakota/Beulah (UTC-05:00)',
  },
  {
    value: 'America/North_Dakota/Center',
    label: 'America/North_Dakota/Center (UTC-05:00)',
  },
  {
    value: 'America/North_Dakota/New_Salem',
    label: 'America/North_Dakota/New_Salem (UTC-05:00)',
  },
  {
    value: 'America/Ojinaga',
    label: 'America/Ojinaga (UTC-06:00)',
  },
  {
    value: 'America/Panama',
    label: 'America/Panama (UTC-05:00)',
  },
  {
    value: 'America/Pangnirtung',
    label: 'America/Pangnirtung (UTC-04:00)',
  },
  {
    value: 'America/Paramaribo',
    label: 'America/Paramaribo (UTC-03:00)',
  },
  {
    value: 'America/Phoenix',
    label: 'America/Phoenix (UTC-07:00)',
  },
  {
    value: 'America/Port-au-Prince',
    label: 'America/Port-au-Prince (UTC-04:00)',
  },
  {
    value: 'America/Port_of_Spain',
    label: 'America/Port_of_Spain (UTC-04:00)',
  },
  {
    value: 'America/Porto_Acre',
    label: 'America/Porto_Acre (UTC-05:00)',
  },
  {
    value: 'America/Porto_Velho',
    label: 'America/Porto_Velho (UTC-04:00)',
  },
  {
    value: 'America/Puerto_Rico',
    label: 'America/Puerto_Rico (UTC-04:00)',
  },
  {
    value: 'America/Punta_Arenas',
    label: 'America/Punta_Arenas (UTC-03:00)',
  },
  {
    value: 'America/Rainy_River',
    label: 'America/Rainy_River (UTC-05:00)',
  },
  {
    value: 'America/Rankin_Inlet',
    label: 'America/Rankin_Inlet (UTC-05:00)',
  },
  {
    value: 'America/Recife',
    label: 'America/Recife (UTC-03:00)',
  },
  {
    value: 'America/Regina',
    label: 'America/Regina (UTC-06:00)',
  },
  {
    value: 'America/Resolute',
    label: 'America/Resolute (UTC-05:00)',
  },
  {
    value: 'America/Rio_Branco',
    label: 'America/Rio_Branco (UTC-05:00)',
  },
  {
    value: 'America/Rosario',
    label: 'America/Rosario (UTC-03:00)',
  },
  {
    value: 'America/Santa_Isabel',
    label: 'America/Santa_Isabel (UTC-07:00)',
  },
  {
    value: 'America/Santarem',
    label: 'America/Santarem (UTC-03:00)',
  },
  {
    value: 'America/Santiago',
    label: 'America/Santiago (UTC-03:00)',
  },
  {
    value: 'America/Santo_Domingo',
    label: 'America/Santo_Domingo (UTC-04:00)',
  },
  {
    value: 'America/Sao_Paulo',
    label: 'America/Sao_Paulo (UTC-03:00)',
  },
  {
    value: 'America/Scoresbysund',
    label: 'America/Scoresbysund (UTC+00:00)',
  },
  {
    value: 'America/Shiprock',
    label: 'America/Shiprock (UTC-06:00)',
  },
  {
    value: 'America/Sitka',
    label: 'America/Sitka (UTC-08:00)',
  },
  {
    value: 'America/St_Barthelemy',
    label: 'America/St_Barthelemy (UTC-04:00)',
  },
  {
    value: 'America/St_Johns',
    label: 'America/St_Johns (UTC-02:30)',
  },
  {
    value: 'America/St_Kitts',
    label: 'America/St_Kitts (UTC-04:00)',
  },
  {
    value: 'America/St_Lucia',
    label: 'America/St_Lucia (UTC-04:00)',
  },
  {
    value: 'America/St_Thomas',
    label: 'America/St_Thomas (UTC-04:00)',
  },
  {
    value: 'America/St_Vincent',
    label: 'America/St_Vincent (UTC-04:00)',
  },
  {
    value: 'America/Swift_Current',
    label: 'America/Swift_Current (UTC-06:00)',
  },
  {
    value: 'America/Tegucigalpa',
    label: 'America/Tegucigalpa (UTC-06:00)',
  },
  {
    value: 'America/Thule',
    label: 'America/Thule (UTC-03:00)',
  },
  {
    value: 'America/Thunder_Bay',
    label: 'America/Thunder_Bay (UTC-04:00)',
  },
  {
    value: 'America/Tijuana',
    label: 'America/Tijuana (UTC-07:00)',
  },
  {
    value: 'America/Toronto',
    label: 'America/Toronto (UTC-04:00)',
  },
  {
    value: 'America/Tortola',
    label: 'America/Tortola (UTC-04:00)',
  },
  {
    value: 'America/Vancouver',
    label: 'America/Vancouver (UTC-07:00)',
  },
  {
    value: 'America/Virgin',
    label: 'America/Virgin (UTC-04:00)',
  },
  {
    value: 'America/Whitehorse',
    label: 'America/Whitehorse (UTC-07:00)',
  },
  {
    value: 'America/Winnipeg',
    label: 'America/Winnipeg (UTC-05:00)',
  },
  {
    value: 'America/Yakutat',
    label: 'America/Yakutat (UTC-08:00)',
  },
  {
    value: 'America/Yellowknife',
    label: 'America/Yellowknife (UTC-06:00)',
  },
  {
    value: 'Antarctica/Casey',
    label: 'Antarctica/Casey (UTC+08:00)',
  },
  {
    value: 'Antarctica/Davis',
    label: 'Antarctica/Davis (UTC+07:00)',
  },
  {
    value: 'Antarctica/DumontDUrville',
    label: 'Antarctica/DumontDUrville (UTC+10:00)',
  },
  {
    value: 'Antarctica/Macquarie',
    label: 'Antarctica/Macquarie (UTC+11:00)',
  },
  {
    value: 'Antarctica/Mawson',
    label: 'Antarctica/Mawson (UTC+05:00)',
  },
  {
    value: 'Antarctica/McMurdo',
    label: 'Antarctica/McMurdo (UTC+12:00)',
  },
  {
    value: 'Antarctica/Palmer',
    label: 'Antarctica/Palmer (UTC-03:00)',
  },
  {
    value: 'Antarctica/Rothera',
    label: 'Antarctica/Rothera (UTC-03:00)',
  },
  {
    value: 'Antarctica/South_Pole',
    label: 'Antarctica/South_Pole (UTC+12:00)',
  },
  {
    value: 'Antarctica/Syowa',
    label: 'Antarctica/Syowa (UTC+03:00)',
  },
  {
    value: 'Antarctica/Troll',
    label: 'Antarctica/Troll (UTC+02:00)',
  },
  {
    value: 'Antarctica/Vostok',
    label: 'Antarctica/Vostok (UTC+06:00)',
  },
  {
    value: 'Arctic/Longyearbyen',
    label: 'Arctic/Longyearbyen (UTC+02:00)',
  },
  {
    value: 'Asia/Aden',
    label: 'Asia/Aden (UTC+03:00)',
  },
  {
    value: 'Asia/Almaty',
    label: 'Asia/Almaty (UTC+06:00)',
  },
  {
    value: 'Asia/Amman',
    label: 'Asia/Amman (UTC+03:00)',
  },
  {
    value: 'Asia/Anadyr',
    label: 'Asia/Anadyr (UTC+12:00)',
  },
  {
    value: 'Asia/Aqtau',
    label: 'Asia/Aqtau (UTC+05:00)',
  },
  {
    value: 'Asia/Aqtobe',
    label: 'Asia/Aqtobe (UTC+05:00)',
  },
  {
    value: 'Asia/Ashgabat',
    label: 'Asia/Ashgabat (UTC+05:00)',
  },
  {
    value: 'Asia/Ashkhabad',
    label: 'Asia/Ashkhabad (UTC+05:00)',
  },
  {
    value: 'Asia/Atyrau',
    label: 'Asia/Atyrau (UTC+05:00)',
  },
  {
    value: 'Asia/Baghdad',
    label: 'Asia/Baghdad (UTC+03:00)',
  },
  {
    value: 'Asia/Bahrain',
    label: 'Asia/Bahrain (UTC+03:00)',
  },
  {
    value: 'Asia/Baku',
    label: 'Asia/Baku (UTC+04:00)',
  },
  {
    value: 'Asia/Bangkok',
    label: 'Asia/Bangkok (UTC+07:00)',
  },
  {
    value: 'Asia/Barnaul',
    label: 'Asia/Barnaul (UTC+07:00)',
  },
  {
    value: 'Asia/Beirut',
    label: 'Asia/Beirut (UTC+03:00)',
  },
  {
    value: 'Asia/Bishkek',
    label: 'Asia/Bishkek (UTC+06:00)',
  },
  {
    value: 'Asia/Brunei',
    label: 'Asia/Brunei (UTC+08:00)',
  },
  {
    value: 'Asia/Calcutta',
    label: 'Asia/Calcutta (UTC+05:30)',
  },
  {
    value: 'Asia/Chita',
    label: 'Asia/Chita (UTC+09:00)',
  },
  {
    value: 'Asia/Choibalsan',
    label: 'Asia/Choibalsan (UTC+08:00)',
  },
  {
    value: 'Asia/Chongqing',
    label: 'Asia/Chongqing (UTC+08:00)',
  },
  {
    value: 'Asia/Chungking',
    label: 'Asia/Chungking (UTC+08:00)',
  },
  {
    value: 'Asia/Colombo',
    label: 'Asia/Colombo (UTC+05:30)',
  },
  {
    value: 'Asia/Dacca',
    label: 'Asia/Dacca (UTC+06:00)',
  },
  {
    value: 'Asia/Damascus',
    label: 'Asia/Damascus (UTC+03:00)',
  },
  {
    value: 'Asia/Dhaka',
    label: 'Asia/Dhaka (UTC+06:00)',
  },
  {
    value: 'Asia/Dili',
    label: 'Asia/Dili (UTC+09:00)',
  },
  {
    value: 'Asia/Dubai',
    label: 'Asia/Dubai (UTC+04:00)',
  },
  {
    value: 'Asia/Dushanbe',
    label: 'Asia/Dushanbe (UTC+05:00)',
  },
  {
    value: 'Asia/Famagusta',
    label: 'Asia/Famagusta (UTC+03:00)',
  },
  {
    value: 'Asia/Gaza',
    label: 'Asia/Gaza (UTC+03:00)',
  },
  {
    value: 'Asia/Harbin',
    label: 'Asia/Harbin (UTC+08:00)',
  },
  {
    value: 'Asia/Hebron',
    label: 'Asia/Hebron (UTC+03:00)',
  },
  {
    value: 'Asia/Ho_Chi_Minh',
    label: 'Asia/Ho_Chi_Minh (UTC+07:00)',
  },
  {
    value: 'Asia/Hong_Kong',
    label: 'Asia/Hong_Kong (UTC+08:00)',
  },
  {
    value: 'Asia/Hovd',
    label: 'Asia/Hovd (UTC+07:00)',
  },
  {
    value: 'Asia/Irkutsk',
    label: 'Asia/Irkutsk (UTC+08:00)',
  },
  {
    value: 'Asia/Istanbul',
    label: 'Asia/Istanbul (UTC+03:00)',
  },
  {
    value: 'Asia/Jakarta',
    label: 'Asia/Jakarta (UTC+07:00)',
  },
  {
    value: 'Asia/Jayapura',
    label: 'Asia/Jayapura (UTC+09:00)',
  },
  {
    value: 'Asia/Jerusalem',
    label: 'Asia/Jerusalem (UTC+03:00)',
  },
  {
    value: 'Asia/Kabul',
    label: 'Asia/Kabul (UTC+04:30)',
  },
  {
    value: 'Asia/Kamchatka',
    label: 'Asia/Kamchatka (UTC+12:00)',
  },
  {
    value: 'Asia/Karachi',
    label: 'Asia/Karachi (UTC+05:00)',
  },
  {
    value: 'Asia/Kashgar',
    label: 'Asia/Kashgar (UTC+06:00)',
  },
  {
    value: 'Asia/Kathmandu',
    label: 'Asia/Kathmandu (UTC+05:45)',
  },
  {
    value: 'Asia/Katmandu',
    label: 'Asia/Katmandu (UTC+05:45)',
  },
  {
    value: 'Asia/Khandyga',
    label: 'Asia/Khandyga (UTC+09:00)',
  },
  {
    value: 'Asia/Kolkata',
    label: 'Asia/Kolkata (UTC+05:30)',
  },
  {
    value: 'Asia/Krasnoyarsk',
    label: 'Asia/Krasnoyarsk (UTC+07:00)',
  },
  {
    value: 'Asia/Kuala_Lumpur',
    label: 'Asia/Kuala_Lumpur (UTC+08:00)',
  },
  {
    value: 'Asia/Kuching',
    label: 'Asia/Kuching (UTC+08:00)',
  },
  {
    value: 'Asia/Kuwait',
    label: 'Asia/Kuwait (UTC+03:00)',
  },
  {
    value: 'Asia/Macao',
    label: 'Asia/Macao (UTC+08:00)',
  },
  {
    value: 'Asia/Macau',
    label: 'Asia/Macau (UTC+08:00)',
  },
  {
    value: 'Asia/Magadan',
    label: 'Asia/Magadan (UTC+11:00)',
  },
  {
    value: 'Asia/Makassar',
    label: 'Asia/Makassar (UTC+08:00)',
  },
  {
    value: 'Asia/Manila',
    label: 'Asia/Manila (UTC+08:00)',
  },
  {
    value: 'Asia/Muscat',
    label: 'Asia/Muscat (UTC+04:00)',
  },
  {
    value: 'Asia/Nicosia',
    label: 'Asia/Nicosia (UTC+03:00)',
  },
  {
    value: 'Asia/Novokuznetsk',
    label: 'Asia/Novokuznetsk (UTC+07:00)',
  },
  {
    value: 'Asia/Novosibirsk',
    label: 'Asia/Novosibirsk (UTC+07:00)',
  },
  {
    value: 'Asia/Omsk',
    label: 'Asia/Omsk (UTC+06:00)',
  },
  {
    value: 'Asia/Oral',
    label: 'Asia/Oral (UTC+05:00)',
  },
  {
    value: 'Asia/Phnom_Penh',
    label: 'Asia/Phnom_Penh (UTC+07:00)',
  },
  {
    value: 'Asia/Pontianak',
    label: 'Asia/Pontianak (UTC+07:00)',
  },
  {
    value: 'Asia/Pyongyang',
    label: 'Asia/Pyongyang (UTC+09:00)',
  },
  {
    value: 'Asia/Qatar',
    label: 'Asia/Qatar (UTC+03:00)',
  },
  {
    value: 'Asia/Qyzylorda',
    label: 'Asia/Qyzylorda (UTC+06:00)',
  },
  {
    value: 'Asia/Rangoon',
    label: 'Asia/Rangoon (UTC+06:30)',
  },
  {
    value: 'Asia/Riyadh',
    label: 'Asia/Riyadh (UTC+03:00)',
  },
  {
    value: 'Asia/Saigon',
    label: 'Asia/Saigon (UTC+07:00)',
  },
  {
    value: 'Asia/Sakhalin',
    label: 'Asia/Sakhalin (UTC+11:00)',
  },
  {
    value: 'Asia/Samarkand',
    label: 'Asia/Samarkand (UTC+05:00)',
  },
  {
    value: 'Asia/Seoul',
    label: 'Asia/Seoul (UTC+09:00)',
  },
  {
    value: 'Asia/Shanghai',
    label: 'Asia/Shanghai (UTC+08:00)',
  },
  {
    value: 'Asia/Singapore',
    label: 'Asia/Singapore (UTC+08:00)',
  },
  {
    value: 'Asia/Srednekolymsk',
    label: 'Asia/Srednekolymsk (UTC+11:00)',
  },
  {
    value: 'Asia/Taipei',
    label: 'Asia/Taipei (UTC+08:00)',
  },
  {
    value: 'Asia/Tashkent',
    label: 'Asia/Tashkent (UTC+05:00)',
  },
  {
    value: 'Asia/Tbilisi',
    label: 'Asia/Tbilisi (UTC+04:00)',
  },
  {
    value: 'Asia/Tehran',
    label: 'Asia/Tehran (UTC+04:30)',
  },
  {
    value: 'Asia/Tel_Aviv',
    label: 'Asia/Tel_Aviv (UTC+03:00)',
  },
  {
    value: 'Asia/Thimbu',
    label: 'Asia/Thimbu (UTC+06:00)',
  },
  {
    value: 'Asia/Thimphu',
    label: 'Asia/Thimphu (UTC+06:00)',
  },
  {
    value: 'Asia/Tokyo',
    label: 'Asia/Tokyo (UTC+09:00)',
  },
  {
    value: 'Asia/Tomsk',
    label: 'Asia/Tomsk (UTC+07:00)',
  },
  {
    value: 'Asia/Ujung_Pandang',
    label: 'Asia/Ujung_Pandang (UTC+08:00)',
  },
  {
    value: 'Asia/Ulaanbaatar',
    label: 'Asia/Ulaanbaatar (UTC+08:00)',
  },
  {
    value: 'Asia/Ulan_Bator',
    label: 'Asia/Ulan_Bator (UTC+08:00)',
  },
  {
    value: 'Asia/Urumqi',
    label: 'Asia/Urumqi (UTC+06:00)',
  },
  {
    value: 'Asia/Ust-Nera',
    label: 'Asia/Ust-Nera (UTC+10:00)',
  },
  {
    value: 'Asia/Vientiane',
    label: 'Asia/Vientiane (UTC+07:00)',
  },
  {
    value: 'Asia/Vladivostok',
    label: 'Asia/Vladivostok (UTC+10:00)',
  },
  {
    value: 'Asia/Yakutsk',
    label: 'Asia/Yakutsk (UTC+09:00)',
  },
  {
    value: 'Asia/Yangon',
    label: 'Asia/Yangon (UTC+06:30)',
  },
  {
    value: 'Asia/Yekaterinburg',
    label: 'Asia/Yekaterinburg (UTC+05:00)',
  },
  {
    value: 'Asia/Yerevan',
    label: 'Asia/Yerevan (UTC+04:00)',
  },
  {
    value: 'Atlantic/Azores',
    label: 'Atlantic/Azores (UTC+00:00)',
  },
  {
    value: 'Atlantic/Bermuda',
    label: 'Atlantic/Bermuda (UTC-03:00)',
  },
  {
    value: 'Atlantic/Canary',
    label: 'Atlantic/Canary (UTC+01:00)',
  },
  {
    value: 'Atlantic/Cape_Verde',
    label: 'Atlantic/Cape_Verde (UTC-01:00)',
  },
  {
    value: 'Atlantic/Faeroe',
    label: 'Atlantic/Faeroe (UTC+01:00)',
  },
  {
    value: 'Atlantic/Faroe',
    label: 'Atlantic/Faroe (UTC+01:00)',
  },
  {
    value: 'Atlantic/Jan_Mayen',
    label: 'Atlantic/Jan_Mayen (UTC+02:00)',
  },
  {
    value: 'Atlantic/Madeira',
    label: 'Atlantic/Madeira (UTC+01:00)',
  },
  {
    value: 'Atlantic/Reykjavik',
    label: 'Atlantic/Reykjavik (UTC+00:00)',
  },
  {
    value: 'Atlantic/South_Georgia',
    label: 'Atlantic/South_Georgia (UTC-02:00)',
  },
  {
    value: 'Atlantic/St_Helena',
    label: 'Atlantic/St_Helena (UTC+00:00)',
  },
  {
    value: 'Atlantic/Stanley',
    label: 'Atlantic/Stanley (UTC-03:00)',
  },
  {
    value: 'Australia/ACT',
    label: 'Australia/ACT (UTC+10:00)',
  },
  {
    value: 'Australia/Adelaide',
    label: 'Australia/Adelaide (UTC+09:30)',
  },
  {
    value: 'Australia/Brisbane',
    label: 'Australia/Brisbane (UTC+10:00)',
  },
  {
    value: 'Australia/Broken_Hill',
    label: 'Australia/Broken_Hill (UTC+09:30)',
  },
  {
    value: 'Australia/Canberra',
    label: 'Australia/Canberra (UTC+10:00)',
  },
  {
    value: 'Australia/Currie',
    label: 'Australia/Currie (UTC+10:00)',
  },
  {
    value: 'Australia/Darwin',
    label: 'Australia/Darwin (UTC+09:30)',
  },
  {
    value: 'Australia/Eucla',
    label: 'Australia/Eucla (UTC+08:45)',
  },
  {
    value: 'Australia/Hobart',
    label: 'Australia/Hobart (UTC+10:00)',
  },
  {
    value: 'Australia/LHI',
    label: 'Australia/LHI (UTC+10:30)',
  },
  {
    value: 'Australia/Lindeman',
    label: 'Australia/Lindeman (UTC+10:00)',
  },
  {
    value: 'Australia/Lord_Howe',
    label: 'Australia/Lord_Howe (UTC+10:30)',
  },
  {
    value: 'Australia/Melbourne',
    label: 'Australia/Melbourne (UTC+10:00)',
  },
  {
    value: 'Australia/NSW',
    label: 'Australia/NSW (UTC+10:00)',
  },
  {
    value: 'Australia/North',
    label: 'Australia/North (UTC+09:30)',
  },
  {
    value: 'Australia/Perth',
    label: 'Australia/Perth (UTC+08:00)',
  },
  {
    value: 'Australia/Queensland',
    label: 'Australia/Queensland (UTC+10:00)',
  },
  {
    value: 'Australia/South',
    label: 'Australia/South (UTC+09:30)',
  },
  {
    value: 'Australia/Sydney',
    label: 'Australia/Sydney (UTC+10:00)',
  },
  {
    value: 'Australia/Tasmania',
    label: 'Australia/Tasmania (UTC+10:00)',
  },
  {
    value: 'Australia/Victoria',
    label: 'Australia/Victoria (UTC+10:00)',
  },
  {
    value: 'Australia/West',
    label: 'Australia/West (UTC+08:00)',
  },
  {
    value: 'Australia/Yancowinna',
    label: 'Australia/Yancowinna (UTC+09:30)',
  },
  {
    value: 'Brazil/Acre',
    label: 'Brazil/Acre (UTC-05:00)',
  },
  {
    value: 'Brazil/DeNoronha',
    label: 'Brazil/DeNoronha (UTC-02:00)',
  },
  {
    value: 'Brazil/East',
    label: 'Brazil/East (UTC-03:00)',
  },
  {
    value: 'Brazil/West',
    label: 'Brazil/West (UTC-04:00)',
  },
  {
    value: 'CET',
    label: 'CET (UTC+02:00)',
  },
  {
    value: 'CST6CDT',
    label: 'CST6CDT (UTC-05:00)',
  },
  {
    value: 'Canada/Atlantic',
    label: 'Canada/Atlantic (UTC-03:00)',
  },
  {
    value: 'Canada/Central',
    label: 'Canada/Central (UTC-05:00)',
  },
  {
    value: 'Canada/Eastern',
    label: 'Canada/Eastern (UTC-04:00)',
  },
  {
    value: 'Canada/Mountain',
    label: 'Canada/Mountain (UTC-06:00)',
  },
  {
    value: 'Canada/Newfoundland',
    label: 'Canada/Newfoundland (UTC-02:30)',
  },
  {
    value: 'Canada/Pacific',
    label: 'Canada/Pacific (UTC-07:00)',
  },
  {
    value: 'Canada/Saskatchewan',
    label: 'Canada/Saskatchewan (UTC-06:00)',
  },
  {
    value: 'Canada/Yukon',
    label: 'Canada/Yukon (UTC-07:00)',
  },
  {
    value: 'Chile/Continental',
    label: 'Chile/Continental (UTC-03:00)',
  },
  {
    value: 'Chile/EasterIsland',
    label: 'Chile/EasterIsland (UTC-05:00)',
  },
  {
    value: 'Cuba',
    label: 'Cuba (UTC-04:00)',
  },
  {
    value: 'EET',
    label: 'EET (UTC+03:00)',
  },
  {
    value: 'EST',
    label: 'EST (UTC-05:00)',
  },
  {
    value: 'EST5EDT',
    label: 'EST5EDT (UTC-04:00)',
  },
  {
    value: 'Egypt',
    label: 'Egypt (UTC+02:00)',
  },
  {
    value: 'Eire',
    label: 'Eire (UTC+01:00)',
  },
  {
    value: 'Etc/GMT',
    label: 'Etc/GMT (UTC+00:00)',
  },
  {
    value: 'Etc/GMT+0',
    label: 'Etc/GMT+0 (UTC+00:00)',
  },
  {
    value: 'Etc/GMT+1',
    label: 'Etc/GMT+1 (UTC-01:00)',
  },
  {
    value: 'Etc/GMT+10',
    label: 'Etc/GMT+10 (UTC-10:00)',
  },
  {
    value: 'Etc/GMT+11',
    label: 'Etc/GMT+11 (UTC-11:00)',
  },
  {
    value: 'Etc/GMT+12',
    label: 'Etc/GMT+12 (UTC-12:00)',
  },
  {
    value: 'Etc/GMT+2',
    label: 'Etc/GMT+2 (UTC-02:00)',
  },
  {
    value: 'Etc/GMT+3',
    label: 'Etc/GMT+3 (UTC-03:00)',
  },
  {
    value: 'Etc/GMT+4',
    label: 'Etc/GMT+4 (UTC-04:00)',
  },
  {
    value: 'Etc/GMT+5',
    label: 'Etc/GMT+5 (UTC-05:00)',
  },
  {
    value: 'Etc/GMT+6',
    label: 'Etc/GMT+6 (UTC-06:00)',
  },
  {
    value: 'Etc/GMT+7',
    label: 'Etc/GMT+7 (UTC-07:00)',
  },
  {
    value: 'Etc/GMT+8',
    label: 'Etc/GMT+8 (UTC-08:00)',
  },
  {
    value: 'Etc/GMT+9',
    label: 'Etc/GMT+9 (UTC-09:00)',
  },
  {
    value: 'Etc/GMT-0',
    label: 'Etc/GMT-0 (UTC+00:00)',
  },
  {
    value: 'Etc/GMT-1',
    label: 'Etc/GMT-1 (UTC+01:00)',
  },
  {
    value: 'Etc/GMT-10',
    label: 'Etc/GMT-10 (UTC+10:00)',
  },
  {
    value: 'Etc/GMT-11',
    label: 'Etc/GMT-11 (UTC+11:00)',
  },
  {
    value: 'Etc/GMT-12',
    label: 'Etc/GMT-12 (UTC+12:00)',
  },
  {
    value: 'Etc/GMT-13',
    label: 'Etc/GMT-13 (UTC+13:00)',
  },
  {
    value: 'Etc/GMT-14',
    label: 'Etc/GMT-14 (UTC+14:00)',
  },
  {
    value: 'Etc/GMT-2',
    label: 'Etc/GMT-2 (UTC+02:00)',
  },
  {
    value: 'Etc/GMT-3',
    label: 'Etc/GMT-3 (UTC+03:00)',
  },
  {
    value: 'Etc/GMT-4',
    label: 'Etc/GMT-4 (UTC+04:00)',
  },
  {
    value: 'Etc/GMT-5',
    label: 'Etc/GMT-5 (UTC+05:00)',
  },
  {
    value: 'Etc/GMT-6',
    label: 'Etc/GMT-6 (UTC+06:00)',
  },
  {
    value: 'Etc/GMT-7',
    label: 'Etc/GMT-7 (UTC+07:00)',
  },
  {
    value: 'Etc/GMT-8',
    label: 'Etc/GMT-8 (UTC+08:00)',
  },
  {
    value: 'Etc/GMT-9',
    label: 'Etc/GMT-9 (UTC+09:00)',
  },
  {
    value: 'Etc/GMT0',
    label: 'Etc/GMT0 (UTC+00:00)',
  },
  {
    value: 'Etc/Greenwich',
    label: 'Etc/Greenwich (UTC+00:00)',
  },
  {
    value: 'Etc/UCT',
    label: 'Etc/UCT (UTC+00:00)',
  },
  {
    value: 'Etc/UTC',
    label: 'Etc/UTC (UTC+00:00)',
  },
  {
    value: 'Etc/Universal',
    label: 'Etc/Universal (UTC+00:00)',
  },
  {
    value: 'Etc/Zulu',
    label: 'Etc/Zulu (UTC+00:00)',
  },
  {
    value: 'Europe/Amsterdam',
    label: 'Europe/Amsterdam (UTC+02:00)',
  },
  {
    value: 'Europe/Andorra',
    label: 'Europe/Andorra (UTC+02:00)',
  },
  {
    value: 'Europe/Astrakhan',
    label: 'Europe/Astrakhan (UTC+04:00)',
  },
  {
    value: 'Europe/Athens',
    label: 'Europe/Athens (UTC+03:00)',
  },
  {
    value: 'Europe/Belfast',
    label: 'Europe/Belfast (UTC+01:00)',
  },
  {
    value: 'Europe/Belgrade',
    label: 'Europe/Belgrade (UTC+02:00)',
  },
  {
    value: 'Europe/Berlin',
    label: 'Europe/Berlin (UTC+02:00)',
  },
  {
    value: 'Europe/Bratislava',
    label: 'Europe/Bratislava (UTC+02:00)',
  },
  {
    value: 'Europe/Brussels',
    label: 'Europe/Brussels (UTC+02:00)',
  },
  {
    value: 'Europe/Bucharest',
    label: 'Europe/Bucharest (UTC+03:00)',
  },
  {
    value: 'Europe/Budapest',
    label: 'Europe/Budapest (UTC+02:00)',
  },
  {
    value: 'Europe/Busingen',
    label: 'Europe/Busingen (UTC+02:00)',
  },
  {
    value: 'Europe/Chisinau',
    label: 'Europe/Chisinau (UTC+03:00)',
  },
  {
    value: 'Europe/Copenhagen',
    label: 'Europe/Copenhagen (UTC+02:00)',
  },
  {
    value: 'Europe/Dublin',
    label: 'Europe/Dublin (UTC+01:00)',
  },
  {
    value: 'Europe/Gibraltar',
    label: 'Europe/Gibraltar (UTC+02:00)',
  },
  {
    value: 'Europe/Guernsey',
    label: 'Europe/Guernsey (UTC+01:00)',
  },
  {
    value: 'Europe/Helsinki',
    label: 'Europe/Helsinki (UTC+03:00)',
  },
  {
    value: 'Europe/Isle_of_Man',
    label: 'Europe/Isle_of_Man (UTC+01:00)',
  },
  {
    value: 'Europe/Istanbul',
    label: 'Europe/Istanbul (UTC+03:00)',
  },
  {
    value: 'Europe/Jersey',
    label: 'Europe/Jersey (UTC+01:00)',
  },
  {
    value: 'Europe/Kaliningrad',
    label: 'Europe/Kaliningrad (UTC+02:00)',
  },
  {
    value: 'Europe/Kiev',
    label: 'Europe/Kiev (UTC+03:00)',
  },
  {
    value: 'Europe/Kirov',
    label: 'Europe/Kirov (UTC+03:00)',
  },
  {
    value: 'Europe/Lisbon',
    label: 'Europe/Lisbon (UTC+01:00)',
  },
  {
    value: 'Europe/Ljubljana',
    label: 'Europe/Ljubljana (UTC+02:00)',
  },
  {
    value: 'Europe/London',
    label: 'Europe/London (UTC+01:00)',
  },
  {
    value: 'Europe/Luxembourg',
    label: 'Europe/Luxembourg (UTC+02:00)',
  },
  {
    value: 'Europe/Madrid',
    label: 'Europe/Madrid (UTC+02:00)',
  },
  {
    value: 'Europe/Malta',
    label: 'Europe/Malta (UTC+02:00)',
  },
  {
    value: 'Europe/Mariehamn',
    label: 'Europe/Mariehamn (UTC+03:00)',
  },
  {
    value: 'Europe/Minsk',
    label: 'Europe/Minsk (UTC+03:00)',
  },
  {
    value: 'Europe/Monaco',
    label: 'Europe/Monaco (UTC+02:00)',
  },
  {
    value: 'Europe/Moscow',
    label: 'Europe/Moscow (UTC+03:00)',
  },
  {
    value: 'Europe/Nicosia',
    label: 'Europe/Nicosia (UTC+03:00)',
  },
  {
    value: 'Europe/Oslo',
    label: 'Europe/Oslo (UTC+02:00)',
  },
  {
    value: 'Europe/Paris',
    label: 'Europe/Paris (UTC+02:00)',
  },
  {
    value: 'Europe/Podgorica',
    label: 'Europe/Podgorica (UTC+02:00)',
  },
  {
    value: 'Europe/Prague',
    label: 'Europe/Prague (UTC+02:00)',
  },
  {
    value: 'Europe/Riga',
    label: 'Europe/Riga (UTC+03:00)',
  },
  {
    value: 'Europe/Rome',
    label: 'Europe/Rome (UTC+02:00)',
  },
  {
    value: 'Europe/Samara',
    label: 'Europe/Samara (UTC+04:00)',
  },
  {
    value: 'Europe/San_Marino',
    label: 'Europe/San_Marino (UTC+02:00)',
  },
  {
    value: 'Europe/Sarajevo',
    label: 'Europe/Sarajevo (UTC+02:00)',
  },
  {
    value: 'Europe/Saratov',
    label: 'Europe/Saratov (UTC+04:00)',
  },
  {
    value: 'Europe/Simferopol',
    label: 'Europe/Simferopol (UTC+03:00)',
  },
  {
    value: 'Europe/Skopje',
    label: 'Europe/Skopje (UTC+02:00)',
  },
  {
    value: 'Europe/Sofia',
    label: 'Europe/Sofia (UTC+03:00)',
  },
  {
    value: 'Europe/Stockholm',
    label: 'Europe/Stockholm (UTC+02:00)',
  },
  {
    value: 'Europe/Tallinn',
    label: 'Europe/Tallinn (UTC+03:00)',
  },
  {
    value: 'Europe/Tirane',
    label: 'Europe/Tirane (UTC+02:00)',
  },
  {
    value: 'Europe/Tiraspol',
    label: 'Europe/Tiraspol (UTC+03:00)',
  },
  {
    value: 'Europe/Ulyanovsk',
    label: 'Europe/Ulyanovsk (UTC+04:00)',
  },
  {
    value: 'Europe/Uzhgorod',
    label: 'Europe/Uzhgorod (UTC+03:00)',
  },
  {
    value: 'Europe/Vaduz',
    label: 'Europe/Vaduz (UTC+02:00)',
  },
  {
    value: 'Europe/Vatican',
    label: 'Europe/Vatican (UTC+02:00)',
  },
  {
    value: 'Europe/Vienna',
    label: 'Europe/Vienna (UTC+02:00)',
  },
  {
    value: 'Europe/Vilnius',
    label: 'Europe/Vilnius (UTC+03:00)',
  },
  {
    value: 'Europe/Volgograd',
    label: 'Europe/Volgograd (UTC+03:00)',
  },
  {
    value: 'Europe/Warsaw',
    label: 'Europe/Warsaw (UTC+02:00)',
  },
  {
    value: 'Europe/Zagreb',
    label: 'Europe/Zagreb (UTC+02:00)',
  },
  {
    value: 'Europe/Zaporozhye',
    label: 'Europe/Zaporozhye (UTC+03:00)',
  },
  {
    value: 'Europe/Zurich',
    label: 'Europe/Zurich (UTC+02:00)',
  },
  {
    value: 'GB',
    label: 'GB (UTC+01:00)',
  },
  {
    value: 'GB-Eire',
    label: 'GB-Eire (UTC+01:00)',
  },
  {
    value: 'GMT',
    label: 'GMT (UTC+00:00)',
  },
  {
    value: 'GMT+0',
    label: 'GMT+0 (UTC+00:00)',
  },
  {
    value: 'GMT-0',
    label: 'GMT-0 (UTC+00:00)',
  },
  {
    value: 'GMT0',
    label: 'GMT0 (UTC+00:00)',
  },
  {
    value: 'Greenwich',
    label: 'Greenwich (UTC+00:00)',
  },
  {
    value: 'HST',
    label: 'HST (UTC-10:00)',
  },
  {
    value: 'Hongkong',
    label: 'Hongkong (UTC+08:00)',
  },
  {
    value: 'Iceland',
    label: 'Iceland (UTC+00:00)',
  },
  {
    value: 'Indian/Antananarivo',
    label: 'Indian/Antananarivo (UTC+03:00)',
  },
  {
    value: 'Indian/Chagos',
    label: 'Indian/Chagos (UTC+06:00)',
  },
  {
    value: 'Indian/Christmas',
    label: 'Indian/Christmas (UTC+07:00)',
  },
  {
    value: 'Indian/Cocos',
    label: 'Indian/Cocos (UTC+06:30)',
  },
  {
    value: 'Indian/Comoro',
    label: 'Indian/Comoro (UTC+03:00)',
  },
  {
    value: 'Indian/Kerguelen',
    label: 'Indian/Kerguelen (UTC+05:00)',
  },
  {
    value: 'Indian/Mahe',
    label: 'Indian/Mahe (UTC+04:00)',
  },
  {
    value: 'Indian/Maldives',
    label: 'Indian/Maldives (UTC+05:00)',
  },
  {
    value: 'Indian/Mauritius',
    label: 'Indian/Mauritius (UTC+04:00)',
  },
  {
    value: 'Indian/Mayotte',
    label: 'Indian/Mayotte (UTC+03:00)',
  },
  {
    value: 'Indian/Reunion',
    label: 'Indian/Reunion (UTC+04:00)',
  },
  {
    value: 'Iran',
    label: 'Iran (UTC+04:30)',
  },
  {
    value: 'Israel',
    label: 'Israel (UTC+03:00)',
  },
  {
    value: 'Jamaica',
    label: 'Jamaica (UTC-05:00)',
  },
  {
    value: 'Japan',
    label: 'Japan (UTC+09:00)',
  },
  {
    value: 'Kwajalein',
    label: 'Kwajalein (UTC+12:00)',
  },
  {
    value: 'Libya',
    label: 'Libya (UTC+02:00)',
  },
  {
    value: 'MET',
    label: 'MET (UTC+02:00)',
  },
  {
    value: 'MST',
    label: 'MST (UTC-07:00)',
  },
  {
    value: 'MST7MDT',
    label: 'MST7MDT (UTC-06:00)',
  },
  {
    value: 'Mexico/BajaNorte',
    label: 'Mexico/BajaNorte (UTC-07:00)',
  },
  {
    value: 'Mexico/BajaSur',
    label: 'Mexico/BajaSur (UTC-06:00)',
  },
  {
    value: 'Mexico/General',
    label: 'Mexico/General (UTC-05:00)',
  },
  {
    value: 'NZ',
    label: 'NZ (UTC+12:00)',
  },
  {
    value: 'NZ-CHAT',
    label: 'NZ-CHAT (UTC+12:45)',
  },
  {
    value: 'Navajo',
    label: 'Navajo (UTC-06:00)',
  },
  {
    value: 'PRC',
    label: 'PRC (UTC+08:00)',
  },
  {
    value: 'PST8PDT',
    label: 'PST8PDT (UTC-07:00)',
  },
  {
    value: 'Pacific/Apia',
    label: 'Pacific/Apia (UTC+13:00)',
  },
  {
    value: 'Pacific/Auckland',
    label: 'Pacific/Auckland (UTC+12:00)',
  },
  {
    value: 'Pacific/Bougainville',
    label: 'Pacific/Bougainville (UTC+11:00)',
  },
  {
    value: 'Pacific/Chatham',
    label: 'Pacific/Chatham (UTC+12:45)',
  },
  {
    value: 'Pacific/Chuuk',
    label: 'Pacific/Chuuk (UTC+10:00)',
  },
  {
    value: 'Pacific/Easter',
    label: 'Pacific/Easter (UTC-05:00)',
  },
  {
    value: 'Pacific/Efate',
    label: 'Pacific/Efate (UTC+11:00)',
  },
  {
    value: 'Pacific/Enderbury',
    label: 'Pacific/Enderbury (UTC+13:00)',
  },
  {
    value: 'Pacific/Fakaofo',
    label: 'Pacific/Fakaofo (UTC+13:00)',
  },
  {
    value: 'Pacific/Fiji',
    label: 'Pacific/Fiji (UTC+12:00)',
  },
  {
    value: 'Pacific/Funafuti',
    label: 'Pacific/Funafuti (UTC+12:00)',
  },
  {
    value: 'Pacific/Galapagos',
    label: 'Pacific/Galapagos (UTC-06:00)',
  },
  {
    value: 'Pacific/Gambier',
    label: 'Pacific/Gambier (UTC-09:00)',
  },
  {
    value: 'Pacific/Guadalcanal',
    label: 'Pacific/Guadalcanal (UTC+11:00)',
  },
  {
    value: 'Pacific/Guam',
    label: 'Pacific/Guam (UTC+10:00)',
  },
  {
    value: 'Pacific/Honolulu',
    label: 'Pacific/Honolulu (UTC-10:00)',
  },
  {
    value: 'Pacific/Johnston',
    label: 'Pacific/Johnston (UTC-10:00)',
  },
  {
    value: 'Pacific/Kiritimati',
    label: 'Pacific/Kiritimati (UTC+14:00)',
  },
  {
    value: 'Pacific/Kosrae',
    label: 'Pacific/Kosrae (UTC+11:00)',
  },
  {
    value: 'Pacific/Kwajalein',
    label: 'Pacific/Kwajalein (UTC+12:00)',
  },
  {
    value: 'Pacific/Majuro',
    label: 'Pacific/Majuro (UTC+12:00)',
  },
  {
    value: 'Pacific/Marquesas',
    label: 'Pacific/Marquesas (UTC-09:30)',
  },
  {
    value: 'Pacific/Midway',
    label: 'Pacific/Midway (UTC-11:00)',
  },
  {
    value: 'Pacific/Nauru',
    label: 'Pacific/Nauru (UTC+12:00)',
  },
  {
    value: 'Pacific/Niue',
    label: 'Pacific/Niue (UTC-11:00)',
  },
  {
    value: 'Pacific/Norfolk',
    label: 'Pacific/Norfolk (UTC+11:00)',
  },
  {
    value: 'Pacific/Noumea',
    label: 'Pacific/Noumea (UTC+11:00)',
  },
  {
    value: 'Pacific/Pago_Pago',
    label: 'Pacific/Pago_Pago (UTC-11:00)',
  },
  {
    value: 'Pacific/Palau',
    label: 'Pacific/Palau (UTC+09:00)',
  },
  {
    value: 'Pacific/Pitcairn',
    label: 'Pacific/Pitcairn (UTC-08:00)',
  },
  {
    value: 'Pacific/Pohnpei',
    label: 'Pacific/Pohnpei (UTC+11:00)',
  },
  {
    value: 'Pacific/Ponape',
    label: 'Pacific/Ponape (UTC+11:00)',
  },
  {
    value: 'Pacific/Port_Moresby',
    label: 'Pacific/Port_Moresby (UTC+10:00)',
  },
  {
    value: 'Pacific/Rarotonga',
    label: 'Pacific/Rarotonga (UTC-10:00)',
  },
  {
    value: 'Pacific/Saipan',
    label: 'Pacific/Saipan (UTC+10:00)',
  },
  {
    value: 'Pacific/Samoa',
    label: 'Pacific/Samoa (UTC-11:00)',
  },
  {
    value: 'Pacific/Tahiti',
    label: 'Pacific/Tahiti (UTC-10:00)',
  },
  {
    value: 'Pacific/Tarawa',
    label: 'Pacific/Tarawa (UTC+12:00)',
  },
  {
    value: 'Pacific/Tongatapu',
    label: 'Pacific/Tongatapu (UTC+13:00)',
  },
  {
    value: 'Pacific/Truk',
    label: 'Pacific/Truk (UTC+10:00)',
  },
  {
    value: 'Pacific/Wake',
    label: 'Pacific/Wake (UTC+12:00)',
  },
  {
    value: 'Pacific/Wallis',
    label: 'Pacific/Wallis (UTC+12:00)',
  },
  {
    value: 'Pacific/Yap',
    label: 'Pacific/Yap (UTC+10:00)',
  },
  {
    value: 'Poland',
    label: 'Poland (UTC+02:00)',
  },
  {
    value: 'Portugal',
    label: 'Portugal (UTC+01:00)',
  },
  {
    value: 'ROC',
    label: 'ROC (UTC+08:00)',
  },
  {
    value: 'ROK',
    label: 'ROK (UTC+09:00)',
  },
  {
    value: 'Singapore',
    label: 'Singapore (UTC+08:00)',
  },
  {
    value: 'Turkey',
    label: 'Turkey (UTC+03:00)',
  },
  {
    value: 'UCT',
    label: 'UCT (UTC+00:00)',
  },
  {
    value: 'US/Alaska',
    label: 'US/Alaska (UTC-08:00)',
  },
  {
    value: 'US/Aleutian',
    label: 'US/Aleutian (UTC-09:00)',
  },
  {
    value: 'US/Arizona',
    label: 'US/Arizona (UTC-07:00)',
  },
  {
    value: 'US/Central',
    label: 'US/Central (UTC-05:00)',
  },
  {
    value: 'US/East-Indiana',
    label: 'US/East-Indiana (UTC-04:00)',
  },
  {
    value: 'US/Eastern',
    label: 'US/Eastern (UTC-04:00)',
  },
  {
    value: 'US/Hawaii',
    label: 'US/Hawaii (UTC-10:00)',
  },
  {
    value: 'US/Indiana-Starke',
    label: 'US/Indiana-Starke (UTC-05:00)',
  },
  {
    value: 'US/Michigan',
    label: 'US/Michigan (UTC-04:00)',
  },
  {
    value: 'US/Mountain',
    label: 'US/Mountain (UTC-06:00)',
  },
  {
    value: 'US/Pacific',
    label: 'US/Pacific (UTC-07:00)',
  },
  {
    value: 'US/Pacific-New',
    label: 'US/Pacific-New (UTC-07:00)',
  },
  {
    value: 'US/Samoa',
    label: 'US/Samoa (UTC-11:00)',
  },
  {
    value: 'UTC',
    label: 'UTC (UTC+00:00)',
  },
  {
    value: 'Universal',
    label: 'Universal (UTC+00:00)',
  },
  {
    value: 'W-SU',
    label: 'W-SU (UTC+03:00)',
  },
  {
    value: 'WET',
    label: 'WET (UTC+01:00)',
  },
  {
    value: 'Zulu',
    label: 'Zulu (UTC+00:00)',
  },
];

export default timeZones;
