import React from 'react';
import { TableColumnProps } from '../types';

export interface TableCellProps {
  column: TableColumnProps;
  row: any;
}

const TableCell: React.FC<TableCellProps> = ({ column, row }) => (
  <td key={`cell-${column.accessor}`}>{column.Cell ? column.Cell({ original: row }) : row[column.accessor]}</td>
);

export { TableCell };
