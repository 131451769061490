import code from './code';
import color from './color';
import font from './font';
import icon from './icon';
import spacing from './spacing';

export default {
  ...code,
  ...color,
  ...font,
  ...icon,
  ...spacing,
};
