import * as React from 'react';

export interface SortIndicatorSvgProps {
  color?: string;
  size?: string;
  style?: React.CSSProperties;
}

const SortIndicatorSvg = ({ size, color, style }: SortIndicatorSvgProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    color={color}
    style={style}
  >
    <path d="M21.016.531A2 2 0 0 0 19 .563L2.929 10.288a2 2 0 0 0 0 3.422L19 23.436a2 2 0 0 0 3.035-1.711V2.274A2 2 0 0 0 21.016.531z" />
  </svg>
);

export default SortIndicatorSvg;
