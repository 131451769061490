import * as React from 'react';
import { SVGProps } from 'react';

export function CheckSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 8" width="1em" height="1em" {...props}>
      <path
        fill="currentColor"
        d="M7.791.096a.5.5 0 0 0-.697.115L2.415 6.733.854 5.17a.5.5 0 0 0-.708.707l1.979 1.978a.508.508 0 0 0 .76-.062l5.021-7a.5.5 0 0 0-.115-.697Z"
      />
    </svg>
  );
}
