import React, { Component, ReactNode } from 'react';
import { css, cx } from '@emotion/css';

import cvar from '../theme/cvar';
import { coal, slate } from '../colors';
import { BlockButton } from '../internal';

const tabStyle = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${cvar('spacing-12')} ${cvar('spacing-32')};
  background-color: ${cvar('color-tabmenu-background')};
  border-bottom: 1px solid ${cvar('color-border-default')};

  &:hover {
    background-color: ${cvar('color-background-light')};
  }

  & > svg {
    margin-right: ${cvar('spacing-16')};
  }

  & > div > p {
    margin: 0;
  }
`;

const tabActiveStyle = css`
  background-color: ${cvar('color-background')};
  padding-bottom: ${cvar('spacing-12')};
  border-bottom: ${cvar('spacing-2')} solid ${cvar('color-interactive')};
  pointer-events: none;
`;

const tabTitleStyle = css`
  color: ${cvar('color-text-selected')};
  margin: ${cvar('spacing-2')} 0;
`;

const tabTitleActiveStyle = css`
  color: ${cvar('color-tabcard-text-selected')};
`;

export interface TabProps {
  /**
   * The unique identifier of the tab
   */
  id: string;

  /**
   * The top level title of the tab
   */
  title: ReactNode;

  /**
   * Optional description shown below the title
   */
  description?: ReactNode;

  /**
   * The icon from the @cimpress-technology/react-streamline-icons library
   */
  icon?: typeof Component;

  /**
   * A callback function executed when a user clicks on a specific tab
   */
  onTabClick: (e: any, tabId: string) => void;

  /**
   * A flag denoting whether the tab is active or not
   */
  isActive: boolean;
}

const Tab = ({ id, title, description, icon: Icon, onTabClick, isActive }: TabProps) => (
  <BlockButton
    data-testid={id}
    className={cx(tabStyle, { [tabActiveStyle]: isActive })}
    onClick={event => onTabClick(event, id)}
  >
    {Icon ? <Icon data-testid={`icon-for-${id}`} weight="light" color={isActive ? coal : slate} size="2x" /> : null}
    <div>
      <h5 className={cx(tabTitleStyle, { [tabTitleActiveStyle]: isActive })}>{title}</h5>
      {description ? <p>{description}</p> : null}
    </div>
  </BlockButton>
);

export default Tab;
