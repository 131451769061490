import React from 'react';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { css, cx } from '@emotion/css';
import { Subtract } from 'utility-types';
import { merge } from 'ts-deepmerge';
import cvar from '../theme/cvar';

const withDraggableStyle = css`
  display: flex;
  border: 0.5px solid ${cvar('color-border-light')};
  border-radius: 3px;
  margin-bottom: 10px;
  background-color: ${cvar('color-background')};
  padding: 10px 10px 10px 0;
`;

const clonePlaceholderStyle = css`
  ${withDraggableStyle}
  ~ div {
    // This the transform property for adjacent draggables when the clone is added to the document
    // A custom style could be passed instead, using a mix of isDragging or isDropAnimating,
    // or isDraggingOver to indicate one item in the droppable is being dragged over
    // see https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/drop-animation.md
    transform: none !important;
  }
`;

const flexItemStyle = css`
  flex-grow: 1;
`;

const gripDragStyle = css`
  min-width: 19px;
  visibility: initial;
  display: flex;
  align-items: center;
`;

const gripDragDisabledStyle = css`
  visibility: collapse;
`;

export interface WithDraggableProps {
  index: number;
  draggableId: string;
  isDragDisabled?: boolean;
  showPlaceholderWhileDragging?: boolean;
  icon: React.ComponentType<{ size?: string }>;
  style?: React.CSSProperties;
}

export type InjectedDraggableProps = {
  snapshot: DraggableStateSnapshot;
  provided: DraggableProvided;
};

export const withDraggable =
  <P extends InjectedDraggableProps>(
    WrappedComponent: React.ComponentType<P>,
  ): React.FC<Subtract<P, InjectedDraggableProps> & WithDraggableProps> =>
  ({
    draggableId,
    index,
    isDragDisabled,
    showPlaceholderWhileDragging = false,
    style,
    icon: Icon,
    ...restProps
  }: Subtract<P, InjectedDraggableProps> & WithDraggableProps) =>
    (
      <Draggable draggableId={draggableId} index={index} isDragDisabled={isDragDisabled}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
          const wrappedProps: any = { ...restProps, snapshot, provided };

          // merge the passed style with the react-beautiful-dnd props avoid overwriting styling the library sets
          const withDraggableWrapperProps = merge.withOptions(
            { allowUndefinedOverrides: false },
            {},
            provided.draggableProps,
            provided.dragHandleProps ?? {},
            { style },
          );

          return (
            <>
              <div className={withDraggableStyle} ref={provided.innerRef} {...withDraggableWrapperProps}>
                <div className={cx(gripDragStyle, { [gripDragDisabledStyle]: isDragDisabled })}>
                  <Icon size="lg" />
                </div>
                <div className={flexItemStyle}>
                  <WrappedComponent {...wrappedProps} />
                </div>
              </div>
              {snapshot.isDragging && showPlaceholderWhileDragging && (
                <div className={clonePlaceholderStyle}>
                  <div className={cx(gripDragStyle, { [gripDragDisabledStyle]: isDragDisabled })}>
                    <Icon size="lg" />
                  </div>
                  <div className={flexItemStyle}>
                    <WrappedComponent {...wrappedProps} />
                  </div>
                </div>
              )}
            </>
          );
        }}
      </Draggable>
    );
