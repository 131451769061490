import React from 'react';
import { css } from '@emotion/css';
import { Spinner } from '../Spinner';
import { silver } from '../colors';

export interface CarouselLoadingProps {
  size: number;
}

const getSpinnerSizeString = (spinnerSize: number) =>
  spinnerSize >= 72 ? 'large' : spinnerSize >= 38 ? 'medium' : 'small';

const itemActiveCss = css`
  transform: translate3d(0, 0, 0);
  left: 0;
`;

const CarouselLoading = ({ size }: CarouselLoadingProps) => {
  const spinnerSize = size / 3;
  return (
    <div
      className={itemActiveCss}
      style={{
        backgroundColor: silver,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ height: spinnerSize }}>
        <Spinner size={getSpinnerSizeString(spinnerSize)} />
      </div>
    </div>
  );
};

export default CarouselLoading;
