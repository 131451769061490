import IconCheckCircleAlt from '@cimpress-technology/react-streamline-icons/lib/IconCheckCircleAlt';
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import IconIconRemoveCircle from '@cimpress-technology/react-streamline-icons/lib/IconIconRemoveCircle';
import { Tooltip } from '@cimpress/react-components';
import { InvalidConsideredOption, isValidOption, Option } from '../../types';

export const OptionStatus = ({ option }: { option: Option | InvalidConsideredOption }) => {
  if (isValidOption(option)) {
    if (option.isSelected) {
      return (
        <Tooltip
          style={{ display: 'flex' }}
          contents="This fulfillment option was selected based on the routing strategy."
        >
          <IconCheckCircleAlt weight="fill" color="#35AD96" size="2x" data-testid="check-circle-icon" />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          style={{ display: 'flex' }}
          contents="This fulfillment option is a valid option but was not been selected based on the routing strategy."
        >
          <IconIconRemoveCircle weight="fill" size="2x" color="#E58628" data-testid="remove-circle-icon" />
        </Tooltip>
      );
    }
  }

  return (
    <Tooltip
      style={{ display: 'flex' }}
      contents="This fulfillment option is a not valid option based on the routing strategy."
    >
      <IconAlertTriangle weight="fill" size="2x" color="#D24A35" data-testid="alert-triangle-icon" />
    </Tooltip>
  );
};
