import { useState, useCallback } from 'react';
import { ErrorContext, RoutingUIError, RoutingUiErrorName } from '../components/ErrorBoundary/RoutingUIError';

export const useAsyncError = () => {
  const [, setError] = useState();
  return useCallback(
    (error: RoutingUIError | Error, context?: ErrorContext) => {
      setError(() => {
        if (!context) {
          throw error;
        }

        // Add additional context to the error object if it's a RoutingUIError
        if (error instanceof RoutingUIError) {
          error.context = context;
          throw error;
        } else {
          // This approach lets us rethrow regular errors as RoutingUIErrors
          // with additional context data attached when available.
          const { name, message, ...rest } = error;

          // With generic Error objects in JS, you can't
          // spread their properties because their properties (like message)
          // are not enumerable.
          throw new RoutingUIError({
            name: name as RoutingUiErrorName,
            message,
            ...rest,
            context,
          });
        }
      });
    },
    [setError],
  );
};
