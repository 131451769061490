import React, { CSSProperties } from 'react';
import { css, cx } from '@emotion/css';
import cvar from '../theme/cvar';
import SuccessSvg from '../icons/SuccessSvg';
import { Circle } from '../internal/shapes';
import { BlockButton } from '../internal';

export interface SelectableCardProps {
  label?: string;
  image?: string;
  cardStyle?: CSSProperties;
  selected?: boolean;
  onCardSelected?: () => void;
  onCardDeselected?: () => void;
  size?: 'sm' | 'lg';
}

export default function SelectableCard(props: SelectableCardProps) {
  const {
    label,
    image,
    cardStyle = {},
    selected = false,
    onCardSelected,
    onCardDeselected,
    size = 'sm',
    ...rest
  } = props;

  const selectableCard = css`
    cursor: pointer;
    border: 1px solid ${cvar('color-border-default')};
    border-radius: 4px;
    position: relative;
    font: ${cvar('text-label-default')};
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${cvar('color-background')};

    .crc-selectablecards_check {
      visibility: ${selected ? 'visible' : 'hidden'};
    }
    &:hover {
      .crc-selectablecards_check {
        visibility: visible;
      }
    }
  `;

  const selectedCard = css`
    border: 1px solid ${cvar('color-button-primary')};
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
    font-weight: bold;
  `;

  const overlay = css`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${cvar('color-hover')};
    opacity: 0;
    transition: 0.25s;
    z-index: 4;
    &:hover {
      opacity: 0.5;
    }
  `;

  const selectedIcon = css`
    position: absolute;
    top: ${cvar('spacing-8')};
    left: ${cvar('spacing-8')};
    color: ${cvar('color-button-primary')};
    z-index: 3;
  `;

  const selectedIconBackground = css`
    position: absolute;
    top: ${cvar('spacing-8')};
    left: ${cvar('spacing-8')};
    z-index: 2;
  `;

  const imageStyle = css`
    width: 88px;
    height: 88px;
    object-fit: contain;
    margin: ${cvar('spacing-16')} ${cvar('spacing-16')} ${label ? cvar('spacing-12') : cvar('spacing-16')};
  `;

  const divider = css`
    height: 1px;
    width: calc(100% - ${cvar('spacing-16')} * 2);
    background-color: ${cvar('color-border-default')};
    margin-right: ${cvar('spacing-16')};
    margin-left: ${cvar('spacing-16')};
  `;

  const labelStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1;
    padding-top: ${image ? cvar('spacing-8') : size === 'sm' ? cvar('spacing-12') : cvar('spacing-16')};
    padding-bottom: ${image ? cvar('spacing-12') : size === 'sm' ? cvar('spacing-12') : cvar('spacing-16')};
    padding-left: ${cvar('spacing-16')};
    padding-right: ${cvar('spacing-16')};
    height: ${!image && size === 'lg' ? '88px' : 'auto'};
    width: ${!image && size === 'lg' ? '88px' : 'auto'};
  `;

  const onCardClicked = () => {
    if (selected) {
      onCardDeselected && onCardDeselected();
    } else {
      onCardSelected && onCardSelected();
    }
  };

  return (
    <BlockButton
      className={cx(selectableCard, { [selectedCard]: selected }, 'crc-selectablecards')}
      style={cardStyle}
      onClick={onCardClicked}
      {...rest}
    >
      <div className={overlay} />
      {(size === 'lg' || image) && (
        <div className="crc-selectablecards_check">
          <Circle color={cvar('color-background')} className={selectedIconBackground} />
          <SuccessSvg className={selectedIcon} />
        </div>
      )}
      {image && <img className={imageStyle} src={image} alt={label} />}
      {image && label && <div className={divider} />}
      {label && <div className={labelStyle}>{label}</div>}
    </BlockButton>
  );
}
