import * as colors from '../colors';

//   As of v. 2.x, react-select no longer provides internal components with unique classNames that can be used to apply custom styles.
//   Instead, the following style tags may be used to apply additional styles using css-in-js.
//   For further details, please consult https://react-select.com/styles
//
//   Style tags:
//   clearIndicator
//   container
//   control
//   dropdownIndicator
//   group
//   groupHeading
//   indicatorsContainer
//   indicatorSeparator
//   input
//   loadingIndicator
//   loadingMessage
//   menu
//   menuList
//   menuPortal
//   multiValue
//   multiValueLabel
//   multiValueRemove
//   noOptionsMessage
//   option
//   placeholder
//   singleValue
//   valueContainer

const styles = {
  clearIndicator: () => ({
    display: 'inline-block',
    fontSize: '20px',
    lineHeight: 1,
    animation: 'Select-animation-fadeIn 200ms',
    cursor: 'pointer',
    width: '20px',
    alignSelf: 'center',
    zIndex: 3,
    color: colors.teal.base,
    '&:hover': {
      color: colors.teal.darker,
    },
  }),
  control: (_base: any, state: any) => ({
    backgroundColor: state.isDisabled ? colors.silver : colors.white,
    border: '1px solid',
    borderColor:
      state.selectProps.status === 'success'
        ? colors.success.base
        : state.selectProps.status === 'warning'
        ? colors.warning.base
        : state.selectProps.status === 'error'
        ? colors.danger.base
        : colors.alloy,
    color: state.isDisabled ? colors.slate : colors.cobalt.base,
    cursor: 'default',
    display: 'flex',
    borderSpacing: 0,
    borderCollapse: 'separate',
    outline: 'none',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  }),
  dropdownIndicator: (_base: any, state: any) => {
    const disabledStyles = {
      cursor: 'default',
      pointerEvents: 'none',
      opacity: 0.35,
    };

    const defaultStyles = {
      cursor: 'pointer',
      position: 'relative',
      color: colors.teal.base,
      border: 'none',
      height: 'auto',
      marginRight: '17px',
      fontSize: '18px',
      lineHeight: '1',
      fontFamily: 'inherit',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    };

    return state.isDisabled ? { ...defaultStyles, ...disabledStyles } : defaultStyles;
  },
  indicatorSeparator: () => ({}),
  input: () => ({
    paddingRight: '16px',
    marginTop: 'auto',
    marginBottom: 'auto',
    '& div': {
      paddingRight: '16px',
      verticalAlign: 'middle',
      background: 'none transparent',
      boxShadow: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      margin: 0,
      outline: 'none',
      padding: '0px 4px',
      '& input': {
        transition: 'none',
      },
    },
    '& div:focus': {
      cursor: 'text',
    },
  }),
  menu: () => ({
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    backgroundColor: colors.white,
    border: `1px solid ${colors.alloy}`,
    boxSizing: 'border-box',
    marginTop: '-1px',
    maxHeight: '305px',
    overflowY: 'auto',
    position: 'absolute',
    top: '100%',
    width: '100%',
    zIndex: 6,
    webkitOverflowScrolling: 'touch',
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 1100 }),
  multiValue: (_base: any, state: any) => {
    const { isDisabled } = state;

    const backgroundColor = isDisabled ? colors.silver : colors.white;

    const color = isDisabled ? colors.slate : colors.shale;

    return {
      margin: '4px 0 4px 4px',
      backgroundColor,
      border: `1px solid ${colors.alloy}`,
      borderRadius: '8px',
      color,
      display: 'flex',
      fontSize: '10px',
      lineHeight: '12px',
      padding: '0px 8px',
      verticalAlign: 'top',
    };
  },
  multiValueLabel: () => ({
    display: 'inline-block',
    verticalAlign: 'middle',
    border: 'none',
    cursor: 'default',
  }),
  multiValueRemove: (_base: any, state: any) => {
    const { isDisabled } = state;

    const cursor = isDisabled ? 'not-allowed' : 'pointer';

    const borderRight = isDisabled ? `1px solid ${colors.alloy}` : null;

    return {
      cursor,
      borderRight,
      border: 'none',
      padding: '0px',
      paddingLeft: '8px',
      color: state.isFocused ? colors.ocean.darker : colors.ocean.base,
      fontSize: '10px',
      lineHeight: '0px',
      fontWeight: 'normal',
      '&:hover': {
        color: colors.ocean.darker,
      },
    };
  },
  noOptionsMessage: () => ({
    boxSizing: 'border-box',
    color: colors.slate,
    cursor: 'default',
    display: 'block',
    padding: '12px 10px',
  }),
  option: (_base: any, state: any) => {
    const { isDisabled, isFocused, isSelected } = state;

    const disabledBackgroundColor = isDisabled ? colors.silver : null;

    const disabledColor = isDisabled ? colors.alloy : null;

    const focusedBackgroundColor = isFocused ? '#f8fafc' : null;

    const selectedBackgroundColor = isSelected ? colors.horizon : null;

    const baseBackgroundColor = '#fff';

    const backgroundColor =
      disabledBackgroundColor || selectedBackgroundColor || focusedBackgroundColor || baseBackgroundColor;

    const baseColor = colors.teal.base;

    const focusedColor = isFocused ? colors.teal.darker : null;

    const color = disabledColor || focusedColor || baseColor;

    const cursor = isDisabled ? 'default' : 'pointer';

    return {
      boxSizing: 'border-box',
      backgroundColor,
      color,
      cursor,
      display: 'block',
      padding: '12px 10px 12px 16px',
      '&:last-child': {
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
      },
    };
  },
  placeholder: () => ({
    bottom: 0,
    color: colors.slate,
    left: 0,
    lineHeight: '48px',
    paddingLeft: '16px',
    paddingRight: '16px',
    position: 'absolute',
    right: 0,
    top: 0,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  singleValue: () => ({
    position: 'absolute',
    left: 0,
    padding: '6px 15px 0 15px',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  valueContainer: (base: any, state: any) => {
    const defaultBase = { ...base, alignItems: 'center', height: '38px' };
    if (state.isMulti) {
      return {
        ...base,
        marginTop: '18px',
      };
    }
    return { ...defaultBase, marginTop: '8px' };
  },
};

export default styles;
