import * as React from 'react';
import { slate } from '../colors';

const CloseSvg = (props: { [otherOptions: string]: unknown }) => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    color={slate}
    {...props}
  >
    <path d="M14.3 12l9.2-9.2c.3-.4.5-.8.5-1.2 0-.4-.2-.8-.5-1.1-.6-.6-1.7-.6-2.3 0L12 9.7 2.8.5C2.2-.1 1.1-.1.5.5.2.8 0 1.2 0 1.6s.2.8.5 1.1L9.7 12 .5 21.2c-.6.6-.6 1.7 0 2.3.6.6 1.7.6 2.3 0l9.2-9.2 9.2 9.2c.3.3.7.5 1.1.5.4 0 .8-.2 1.1-.5.6-.6.6-1.7 0-2.3L14.3 12z" />
  </svg>
);

export default CloseSvg;
