import { get } from 'lodash';
import { InvalidConsideredOption, Option, PMV1ProductConfigResponse, PMV2ProductConfigResponse } from '../types';
import { fetchWithAuth } from './fetchWithAuth';

export type GetProductConfigurationParams = {
  accessToken: string;
  option: Option | InvalidConsideredOption;
};

export const getProductConfiguration = async ({
  accessToken,
  option,
}: GetProductConfigurationParams): Promise<{
  productConfiguration: PMV1ProductConfigResponse | PMV2ProductConfigResponse | undefined;
}> => {
  if (!option || !get(option, ['_links', 'productConfiguration'])) {
    return { productConfiguration: undefined };
  }

  // The path should always be defined here, but typescript can't tell from the narrowing above
  const response = await fetchWithAuth({ path: option._links.productConfiguration!.href, accessToken });

  // We don't need to throw an error if this doesn't exist because it's a progressive enhancement feature
  // (i.e., great if we have it, but we just won't show the feature associated with it)
  if (!response.ok) {
    return { productConfiguration: undefined };
  }

  return {
    productConfiguration: (await response.json()) as PMV1ProductConfigResponse | PMV2ProductConfigResponse | undefined,
  };
};
