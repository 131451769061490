import React, { useState, useEffect, ReactNode } from 'react';
import { css, cx } from '@emotion/css';
import { BlockButton } from '../internal';
import { teal } from '../colors';

type ClassNamesArg = Parameters<typeof cx>[number];

export interface CarouselSlideProps {
  active?: boolean;
  index: number;
  animateOut?: boolean;
  animateIn?: boolean;
  direction?: string;
  onCarouselClick?: (index: number) => void;
  showZoomOverlay?: boolean;
  zoomOverlayContent?: ReactNode;
  children?: ReactNode;
}

const overlayCss = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(255, 255, 255, 0.8);
`;

const flexCenterCss = css`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
`;

const itemCss = css`
  position: absolute;
  top: 0;
  opacity: 0;
  left: 0;
  right: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000px;
`;

const overlayContainerCss = css`
  &:hover {
    .crc-carousel-slide_overlay {
      opacity: 1;
    }
  }
`;

const activeCss = css`
  opacity: 1;
`;

const transitionLeftIn = css`
  left: 100%;
  transform: translateX(-100%);
  transition: 0.6s ease-in-out transform;
`;

const transitionRightIn = css`
  left: -100%;
  transform: translateX(100%);
  transition: 0.6s ease-in-out transform;
`;

const transitionLeftOut = css`
  transform: translateX(-100%);
  transition: 0.6s ease-in-out transform;
`;

const transitionRightOut = css`
  transform: translateX(100%);
  transition: 0.6s ease-in-out transform;
`;

const clickableCss = css`
  cursor: pointer;
  .crc-carousel-slide_overlay {
    cursor: pointer;
  }
`;

const CarouselSlide = ({
  active,
  animateIn,
  animateOut,
  direction,
  index,
  onCarouselClick,
  showZoomOverlay,
  zoomOverlayContent,
  ...props
}: CarouselSlideProps) => {
  const [classes, setClasses] = useState<ClassNamesArg>({ [itemCss]: true });

  const shouldRenderZoomOverlay = showZoomOverlay && !!onCarouselClick;

  useEffect(() => {
    const initialClasses: ClassNamesArg = {
      [itemCss]: true,
      [clickableCss]: !!onCarouselClick && active && !animateIn,
      [activeCss]: active || animateIn || animateOut,
      [overlayContainerCss]: shouldRenderZoomOverlay,
    };

    if (direction && animateIn) {
      initialClasses[transitionLeftIn] = direction === 'next';
      initialClasses[transitionRightIn] = direction === 'prev';
    }

    if (direction && animateOut) {
      initialClasses[transitionLeftOut] = direction === 'next';
      initialClasses[transitionRightOut] = direction === 'prev';
    }

    setClasses(initialClasses);
  }, [onCarouselClick, active, animateIn, animateOut, shouldRenderZoomOverlay, direction]);

  const onClick = () => {
    onCarouselClick && onCarouselClick(index);
  };

  const zoomContent = zoomOverlayContent || <span>Zoom In</span>;

  return (
    <BlockButton onClick={onClick} className={cx('crc-carousel-slide', classes)}>
      {props.children}
      {shouldRenderZoomOverlay ? (
        <div className={cx(overlayCss, flexCenterCss, 'crc-carousel-slide_overlay')} style={{ color: teal.base }}>
          {zoomContent}
        </div>
      ) : null}
    </BlockButton>
  );
};

export default CarouselSlide;
