import React, { useState, useEffect, useRef } from 'react';
import { css, cx } from '@emotion/css';
import { Tooltip } from './Tooltip';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';

/**
 * Determines the number of digits after the decimal place. Returns 0 if there are none.
 * @param {number} num any real number
 * @returns {number} - an integer denoting the number of decimal digits
 */
export function countDecimals(num: number): number {
  if (Math.floor(num) === num) return 0;
  return num.toString().split('.')[1].length;
}

/**
 * Rounds the given number to the closest increment. The increment can be any real number.
 * @param {number} num any real number
 * @param {number} inc any real number
 * @returns {number} - num rounded to the closest increment.
 */
export function roundToIncrement(num: number, inc: number): number {
  return Math.round(Math.round(num / (inc / 10)) / 10) * inc; // https://gordonlesti.com/inaccurate-rounding-with-decimal-digits/
}

/**
 * Set the number a string, truncate to the given decimal point, and cut off any extraneous decimal zeroes.
 * @param {number} num any real number
 * @param {number} decimals any integer
 * @returns {string} - num truncated to the decimals, plus any extra zeroes removed.
 */
export function truncate(num: number, decimals: number): string {
  // toFixed() solves the problem of floats (such as 1.0000000000000000012) being returned from increment.
  // the replace() will then determine if the result is a decimal with trailing zeroes, and remove the zeroes/decimal if applicable.
  return num.toFixed(decimals).replace(/\.\d*0+$/, match => match.replace(/\.?0+$/, ''));
}

function toNumberOrDefault(s: string | number, defaultValue: number): number {
  const num = Number(s);
  return Number.isNaN(num) ? defaultValue : num;
}

let barWidth = 0;
let barLeft = 0;
let touchIdentifier = 0;

function getPosition(element: Element) {
  const box = element.getBoundingClientRect();
  return {
    left: box.left + window.scrollX,
    top: box.top + window.scrollY,
    width: box.width,
    height: box.height,
  };
}

function getMouseX(e: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent) {
  // Regular mouse events.
  /* eslint-disable no-self-compare */
  if ('pageX' in e && e.pageX === e.pageX) {
    return e.pageX;
  }

  // Touch events.
  if ('changedTouches' in e) {
    for (let i = 0; i < e.changedTouches.length; i += 1) {
      if (e.changedTouches[i].identifier === touchIdentifier) {
        return e.changedTouches[i].pageX;
      }
    }
  }

  return 0; // It should never get here.
}

const slider = css`
  display: flex;
  flex-direction: column;
  position: relative;
  user-select: none;
`;

const boxShadow = css`
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
`;

const disabledCursor = css`
  cursor: not-allowed;
`;
const sliderDisabled = css`
  opacity: 0.3;
`;

const sliderTitleHeight = cvar('spacing-24');
const sliderBarHeight = cvar('spacing-4');
const sliderHandleSize = cvar('spacing-16');
const sliderTooltipOffset = cvar('spacing-4');
const sliderBarBorderRadius = `calc(${sliderHandleSize} / 2)`;

const sliderTitle = css`
  height: ${sliderTitleHeight};
`;

const sliderBar = css`
  position: absolute;
  width: 100%;
  top: calc(${sliderTitleHeight} + ((${sliderHandleSize} - ${sliderBarHeight}) / 2));
  height: ${sliderBarHeight};
  background-color: ${cvar('color-slider-empty')};
  border-radius: ${sliderBarBorderRadius};
  cursor: pointer;
`;

const sliderProgressBar = css`
  position: relative;
  background-color: ${cvar('color-background-info')};
  height: 100%;
  border-radius: ${sliderBarBorderRadius};
`;

const sliderHandle = css`
  position: relative;
  width: ${sliderHandleSize};
  height: ${sliderHandleSize};
  border-radius: calc(${sliderHandleSize} / 2);
  border: 1px solid ${cvar('color-background-info')};
  background-color: ${cvar('color-background')};
  cursor: grab;
  transform: translateX(-50%);
  transition: box-shadow 0.3s;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0);
  .tether-tooltip-target {
    margin-top: ${cvar('spacing-4')};
    width: ${sliderHandleSize};
    height: ${sliderHandleSize};
  }
`;

const sliderValues = css`
  display: flex;
  justify-content: space-between;
`;

const sliderVirtualHandle = css`
  margin-top: -${sliderTooltipOffset};
  width: ${sliderHandleSize};
  height: calc(${sliderHandleSize} + ${sliderTooltipOffset});
`;

export interface SliderProps extends PublicComponentProps {
  /**
   * Any real number denoting the increment that the slider will select.
   */
  increment?: number;

  /**
   * Setting this to true disables the slider, and greys it out.
   */
  disabled?: boolean;

  /**
   * The label displayed above the slider.
   */
  label: string;

  /**
   * The minimum value that the slider can select (on the left).
   */
  min?: number;

  /**
   * The maximum value that the slider can select (on the right).
   */
  max?: number;

  /**
   * Once a value is selected, this callback function will receive the selected number as an argument.
   */
  onSelect: (roundedNumber: number) => void;

  /**
   * Setting this to true calls onSelect while dragging the slider, instead of only when it is dropped.
   */
  selectOnDrag?: boolean;

  /**
   * The current value of the slider.
   */
  value: number | string;
}

export const Slider = ({
  value,
  label,
  min = 0,
  max = 100,
  disabled = false,
  increment = 1,
  selectOnDrag = false,
  onSelect,
  className,
  ...rest
}: SliderProps) => {
  // State setup
  const [internalNumber, setInternalNumber] = useState(toNumberOrDefault(value, min));
  const [displayString, setDisplayString] = useState(value.toString());
  const [roundedNumber, setRoundedNumber] = useState(toNumberOrDefault(value, min));
  const [decimals, setDecimals] = useState(increment);
  const [dragging, setDragging] = useState(false);
  const [hover, setHover] = useState(false);

  // Ref to the slider bar
  const sliderBarRef = useRef<HTMLDivElement>(null);

  const left = `${100 * ((internalNumber - min) / (max - min))}%`;
  const shouldFocus = hover || dragging;

  useEffect(() => {
    const valueAsNumber = toNumberOrDefault(value, min);
    if (valueAsNumber !== roundedNumber) {
      setInternalNumber(valueAsNumber);
      setDisplayString(value.toString());
      setRoundedNumber(valueAsNumber);
    }
    setDecimals(countDecimals(increment));
  }, [value, increment]);

  useEffect(() => {
    if (!dragging) {
      onSelect(roundedNumber);
    }
  }, [dragging]);

  const onDragMove = (e: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent) => {
    const mouseX = getMouseX(e);
    let nextInternalNumber;

    if (mouseX <= barLeft) {
      nextInternalNumber = min;
    } else if (mouseX >= barLeft + barWidth) {
      nextInternalNumber = max;
    } else {
      const range = max - min;
      const percentage = (mouseX - barLeft) / barWidth;
      nextInternalNumber = min + percentage * range;
    }

    const nextRoundedNumber = roundToIncrement(nextInternalNumber, increment);
    const nextDisplayString = truncate(nextRoundedNumber, decimals);

    setInternalNumber(nextInternalNumber);
    setRoundedNumber(nextRoundedNumber);
    setDisplayString(nextDisplayString);

    if (selectOnDrag && nextRoundedNumber !== value) {
      onSelect(nextRoundedNumber);
    }
  };

  const onDragEnd = () => {
    setDragging(false);
    document.removeEventListener('mousemove', onDragMove);
    document.removeEventListener('touchmove', onDragMove);
    document.removeEventListener('mouseup', onDragEnd);
    document.removeEventListener('touchend', onDragEnd);
  };

  const onDragStart = (e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
    if (!disabled && !!sliderBarRef.current) {
      const barPosition = getPosition(sliderBarRef.current);

      barLeft = barPosition.left;
      barWidth = barPosition.width;

      if ('targetTouches' in e) {
        touchIdentifier = e.targetTouches[0].identifier;
      }

      setDragging(true);
      document.addEventListener('mousemove', onDragMove);
      document.addEventListener('touchmove', onDragMove);
      document.addEventListener('mouseup', onDragEnd);
      document.addEventListener('touchend', onDragEnd);
      onDragMove(e);
    }
  };

  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  // TODO: {https://gitlab.com/Cimpress-Technology/internal-open-source/component-library/react-components/-/issues/151} fix a11y issues with this component.
  /* eslint jsx-a11y/interactive-supports-focus: 0
            jsx-a11y/no-static-element-interactions: 0
  */

  return (
    <div
      className={cx('crc-slider', slider, { [`${sliderDisabled}`]: disabled }, className)}
      onMouseEnter={onMouseEnter}
      onFocus={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onBlur={onMouseLeave}
      {...rest}
    >
      <div className={cx(sliderTitle)}>{label}</div>
      <div
        ref={sliderBarRef}
        className={cx(sliderBar, { [`${disabledCursor}`]: disabled })}
        onTouchStart={e => onDragStart(e)}
        onMouseDown={onDragStart}
      >
        <div className={cx(sliderProgressBar)} style={{ width: left }} />
      </div>
      <div
        className={cx(sliderHandle, { [`${disabledCursor}`]: disabled, [`${boxShadow}`]: shouldFocus && !disabled })}
        style={{ left }}
      >
        <Tooltip direction="bottom" show={shouldFocus} variety="popover" contents={displayString}>
          <div className={cx(sliderVirtualHandle)} onMouseDown={onDragStart} onTouchStart={onDragStart} />
        </Tooltip>
      </div>
      <div className={cx(sliderValues)}>
        <div className="slider-min">{min}</div>
        <div className="slider-max">{max}</div>
      </div>
    </div>
  );
};
