import React, { PureComponent, createRef } from 'react';
import { css, cx } from '@emotion/css';
import ReactTable, { TableProps as ReactTableProps } from 'react-table';
import selectTable, { SelectTableAdditionalProps } from 'react-table/lib/hoc/selectTable';

import TableHeader from './TableHeader';
import TablePagination from './TablePagination';
import { TableSelect, TableSelectAll } from './TableSelect';
import { TableSpinner } from './TableSpinner';
import TableNoData from './TableNoData';
import cvar from '../theme/cvar';
import { FeatureFlagsContext } from '../FeatureFlags';

const CheckboxReactTable = selectTable(ReactTable);

export interface ReactTablev6Props extends Partial<ReactTableProps>, Partial<SelectTableAdditionalProps> {
  /**
   * Toggles the condensed styling for the table.
   */
  condensed?: boolean;

  /**
   * Toggles the column of checkboxes that make rows selectable.
   */
  selectable?: boolean;

  /**
   * Text to display for the dropdown controlling page size. Default: 'Per Page'
   */
  perPageLabelText?: string;

  /**
   * Text to display in the component that allows jumping to specific text. Default: 'Go To'
   */
  goToLabelText?: string;

  /**
   *  The unique accessor property in order to render the select field for a particular row.
   */
  keyField?: string;
}

const defaultPageSizes = [10, 20, 50, 100];

type State = { resolvedData: any; entireHeaderHeight: number; selectAll: boolean; selection: string[] };

export class ReactTablev6 extends PureComponent<ReactTablev6Props> {
  static contextType = FeatureFlagsContext;

  declare context: React.ContextType<typeof FeatureFlagsContext>;

  tableRef: any = null;

  containerRef = createRef<HTMLDivElement>();

  state: State = {
    resolvedData: this.props.data, // eslint-disable-line react/no-unused-state
    entireHeaderHeight: 0,
    selectAll: false,
    selection: [],
  };

  componentDidMount() {
    this.setMeasurements();
  }

  componentDidUpdate(prevProps: ReactTablev6Props) {
    if (prevProps.condensed !== this.props.condensed || prevProps.filterable !== this.props.filterable) {
      this.setMeasurements();
    }
  }

  // get the height of the header to use as the offset to the LoadingComponent and NoDataComponent
  setMeasurements = () => {
    const rt = this.containerRef?.current?.getElementsByClassName('ReactTable') || [];
    const headers = Array.from(rt[0].getElementsByClassName('rt-thead'));

    this.setState({ entireHeaderHeight: headers.reduce((agg, h) => agg + (h as any).offsetHeight, 0) });
  };

  getVisibleData = () => {
    if (!this.tableRef) {
      return this.props.data || [];
    }

    const resolvedState = this.tableRef.getWrappedInstance().getResolvedState();
    const pageStart = resolvedState.page * resolvedState.pageSize;
    const pageEnd = (resolvedState.page + 1) * resolvedState.pageSize;
    return resolvedState.resolvedData.slice(pageStart, pageEnd);
  };

  getPaginationProps = () => {
    const { condensed, perPageLabelText, goToLabelText } = this.props;
    return { condensed, perPageLabelText, goToLabelText };
  };

  getTableRef = (tableRef: any) => {
    this.tableRef = tableRef;
  };

  getTheadThProps = (state: any, _row: any, column: any) => {
    const sortable = [column.sortable, state.sortable, false].find(x => x != null);
    const sorted = state.sorted.find((x: any) => x.id === column.id);
    return { sortable, sorted };
  };

  getSharedProps = ({
    loading,
    condensed,
    filterable,
  }: {
    loading: boolean;
    condensed: boolean;
    filterable: boolean;
  }) => ({
    loading,
    entireHeaderHeight: this.state.entireHeaderHeight,
    condensed,
    filterable,
  });

  toggleSelection = (key: string) => {
    const selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) selection.splice(keyIndex, 1);
    else selection.push(key);
    this.setState(() => ({ selection }));
  };

  toggleAll = () => {
    const { keyField } = this.props;
    const selectAll = !this.state.selectAll;
    const selection: string[] = [];

    if (selectAll) {
      const currentRecords: any[] = this.tableRef.getWrappedInstance().getResolvedState().sortedData;
      currentRecords.forEach(item => {
        selection.push(`select-${item[keyField || '_id']}`);
      });
    }
    this.setState(() => ({ selectAll, selection }));
  };

  isSelected = (key: string) => this.state.selection.includes(`select-${key}`);

  render() {
    const { className, condensed, selectable } = this.props;

    const props = {
      ref: this.getTableRef,
      className: cx('crc-react-table-v6', className, { 'table-condensed': condensed }),
      // Callbacks for providing custom props to individual components
      getPaginationProps: this.getPaginationProps,
      getTheadThProps: this.getTheadThProps,
      getNoDataProps: this.getSharedProps,
      getLoadingProps: this.getSharedProps,
      ThComponent: TableHeader,
      // Defaults for pagination
      defaultPageSize: 10,
      pageSizeOptions: defaultPageSizes,
      LoadingComponent: TableSpinner,
      NoDataComponent: TableNoData,
      PaginationComponent: TablePagination,
      // Selection state and callbacks
      selectWidth: 73, // Magic number that is 2 times left padding plus checkbox width.
      SelectAllInputComponent: TableSelectAll,
      SelectInputComponent: TableSelect,
      style: { position: 'relative' },
      // Override all of the above with user-provided values if present.
      ...this.props,
    };

    const reactTable = css`
      .rt-table {
        border: 1px solid ${cvar('color-border-light')};
        background-color: ${cvar('color-background')};
        font: ${condensed ? cvar('text-xsmall') : 'inherit'};

        & + .pagination-bottom {
          margin-top: ${cvar('spacing-24')};
          margin-bottom: ${this.context.v17_noOuterSpacing ? 0 : cvar('spacing-16')};
        }

        .rt-thead {
          background-color: ${cvar('color-border-light')};
          color: ${cvar('color-text-label')};
          font-weight: ${condensed ? '600' : '400'};
        }
        .rt-thead.-header .rt-tr > * {
          padding: ${condensed
            ? `${cvar('spacing-8')} ${cvar('spacing-8')} ${cvar('spacing-4')}}`
            : `${cvar('spacing-16')} ${cvar('spacing-16')} ${cvar('spacing-8')}`};
        }
        .rt-thead.-filters .rt-tr > * {
          padding: ${condensed
            ? `${cvar('spacing-4')} ${cvar('spacing-8')} ${cvar('spacing-8')}}`
            : `${cvar('spacing-8')} ${cvar('spacing-16')} ${cvar('spacing-16')}`};
        }
        .rt-resizable-header {
          position: relative;
          &:last-child > .rt-resizer {
            display: none;
          }
          .rt-resizable-header-content {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 10px;
            font-weight: 600;
            line-height: 12px;
            color: ${cvar('color-text-table-header')};
          }
          .rt-resizer {
            cursor: col-resize;
            height: ${this.props.filterable ? '200%' : '100%'};
            width: ${condensed ? cvar('spacing-8') : cvar('spacing-16')};
            position: absolute;
            right: ${condensed ? '-4px' : '-8px'};
            top: 0;
            z-index: 1;
          }
        }
        .rt-tr {
          display: flex;
          border-top: 1px solid ${cvar('color-border-light')};
          .rt-td {
            padding: ${condensed ? cvar('spacing-8') : cvar('spacing-16')};
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: ${cvar('color-text-table-header')};
          }
        }
      }
    `;

    return (
      <div ref={this.containerRef}>
        {selectable ? (
          <CheckboxReactTable
            {...props}
            className={reactTable}
            selectAll={this.state.selectAll}
            toggleSelection={this.toggleSelection}
            toggleAll={this.toggleAll}
            isSelected={this.isSelected}
          />
        ) : (
          <ReactTable {...props} className={reactTable} />
        )}
      </div>
    );
  }
}
