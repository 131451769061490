import { get } from 'lodash';
import { useGetRoutingRequest } from '../../hooks/useRequestManager';
import { ItemRoutingDecisionViewerProps } from './ItemRoutingDecisionViewer';
import DecisionViewer from '../DecisionViewer';
import { SpinnerWrapper } from '../SpinnerWrapper';
import { useAsyncError } from '../../hooks/useAsyncError';
import { RoutingUIError } from '../ErrorBoundary/RoutingUIError';
import { RoutingRequestResponse } from '../../types';
import { getRoutingRequestUrlFromItem } from '../../helpers/item';

const getRoutingDecisionFromRequest = ({
  itemId,
  routingDecisionLinks,
}: {
  itemId: string;
  routingDecisionLinks?: RoutingRequestResponse['_embedded']['routingResult'];
}) => {
  if (!routingDecisionLinks || !routingDecisionLinks.length) {
    return undefined;
  }

  /**
   * Sometimes, change requests are created for orders. This causes a new item ID to be generated for
   * each item in the order, which leads to mismatches between the item IDs in the routing request and
   * the item IDs in the order. If we can't find a routing decision link where the item ID matches one
   * in the routing request, then we return the first routing decision link.
   * More detail in this Slack thread: https://cimpress.slack.com/archives/CTJ1ATA9M/p1716996699320859
   */
  const matchingLink = routingDecisionLinks.find((link) => link._links.item.name === itemId);
  return matchingLink ? matchingLink._links.routingDecision.href : routingDecisionLinks[0]._links.routingDecision.href;
};

export const ItemRoutingDecisionViewerBody = ({
  environment,
  item,
  accessToken,
  showDecisionLinks,
}: ItemRoutingDecisionViewerProps) => {
  const throwAsyncError = useAsyncError();
  const routingRequestUrl = getRoutingRequestUrlFromItem(item);
  const decisionLinkFromItem = get(item, ['_links', 'routingDecision', 'href']) as string | undefined;

  const {
    data: routingRequestResponse,
    isLoading: isLoadingRoutingRequest,
    error: routingRequestError,
  } = useGetRoutingRequest({
    accessToken,
    routingRequestUrl,
    enabled: !!routingRequestUrl,
  });

  if (!item) {
    throwAsyncError(
      new RoutingUIError({
        name: 'ROUTING_REQUEST_ERROR',
        message: 'An item must be provided in order to load a routing decision.',
      }),
    );
    return null;
  }

  /**
   * Sometimes, such as with MOTR, the ordered item isn't the routed item. We don't patch the routing request
   * onto the routed item, which means that the hook above will cause an error and we won't be
   * able to load the component if we rely only on the item's routing request to get the routing decision.
   *
   * Routed items do have the routing decision, though. We try to get the routing decision
   * directly from the item in this scenario. If we can get the routing request directly from the
   * item, then we can skip all the steps below and return the DecisionViewer component with the routing decision
   * from the item.
   */
  if (decisionLinkFromItem) {
    return (
      <DecisionViewer
        environment={environment}
        decisionLink={decisionLinkFromItem}
        showDecisionLinks={showDecisionLinks}
        accessToken={accessToken}
        data-testid="decision-viewer"
      />
    );
  }

  if (isLoadingRoutingRequest) {
    return <SpinnerWrapper />;
  }

  // Error fetching the routing request either when directly
  // trying to get the request from the item or through the routing decision
  // fallback approach
  if (routingRequestError) {
    throwAsyncError(routingRequestError, {
      item,
      showDecisionLinks: false, // Won't have a decision yet, so no link(s) to show
    });
    return null;
  }

  const { routingRequest } = routingRequestResponse || {};

  // We could run into this case if the item is a routed item because it won't have a routing request link
  // on it. This could also indicate a malformed routing request.
  if (!routingRequest) {
    throwAsyncError(
      new RoutingUIError({
        name: 'ROUTING_REQUEST_ERROR',
        message: 'There was an error retrieving the routing request. The routing request response was undefined.',
        link: routingRequestUrl ? { href: routingRequestUrl, name: 'Routing request link' } : undefined,
        context: {
          item,
          showDecisionLinks: false, // Won't have a decision yet, so no link(s) to show
        },
      }),
    );
    return null;
  }

  const routingDecisionLinks = get(routingRequest, ['_embedded', 'routingResult']);

  const routingDecision = getRoutingDecisionFromRequest({ itemId: item.itemId, routingDecisionLinks });

  // Routing request is missing decision link (shouldn't happen, but could)
  if (!routingDecision) {
    throwAsyncError(
      new RoutingUIError({
        name: 'INFORMATION',
        message: 'A routing decision could not be found for this item.',
        ...(routingRequestUrl && { link: { href: routingRequestUrl, name: 'Routing request link' } }),
        context: {
          item,
          showDecisionLinks: false, // Decision link is missing, so no link(s) to show
        },
      }),
    );
    return null;
  }

  return (
    <DecisionViewer
      environment={environment}
      decisionLink={routingDecision}
      showDecisionLinks={showDecisionLinks}
      accessToken={accessToken}
      data-testid="decision-viewer"
    />
  );
};
