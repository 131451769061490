const addPeriodToEndOfSentence = (sentence: string) => {
  if (sentence.charAt(sentence.length - 1) !== '.') {
    sentence += '.';
  }

  return sentence;
};

export const formatCauseWithAction = ({ cause, action }: { cause: string; action?: string }) => {
  let output = cause;

  output = addPeriodToEndOfSentence(output);

  if (action) {
    output += ` ${action}`;
    output = addPeriodToEndOfSentence(output);
  }

  return output;
};
