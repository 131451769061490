import * as React from 'react';
import { css, cx, injectGlobal } from '@emotion/css';
import memoizeOne from 'memoize-one';

export const spacing = [0, 1, 2, 4, 8, 16, 24, 32, 40, 48, 56, 64, 80, 96];

function createSpacingUtilities(breakpointName?: string) {
  const prefix = `${breakpointName ? `${breakpointName}\\:` : ''}`;

  return spacing
    .map(
      size => `
        .${prefix}m-${size} {
          margin: ${size}px;
        }

        .${prefix}mt-${size} {
          margin-top: ${size}px;
        }

        .${prefix}mb-${size} {
          margin-bottom: ${size}px;
        }

        .${prefix}mr-${size} {
          margin-right: ${size}px;
        }

        .${prefix}ml-${size} {
          margin-left: ${size}px;
        }

        .${prefix}mx-${size} {
          margin-left: ${size}px;
          margin-right: ${size}px;
        }

        .${prefix}my-${size} {
          margin-top: ${size}px;
          margin-bottom: ${size}px;
        }

        .${prefix}ms-${size} {
          margin-inline-start: ${size}px;
        }

        .${prefix}me-${size} {
          margin-inline-end: ${size}px;
        }

        .${prefix}m-auto {
          margin: auto;
        }

        .${prefix}mt-auto {
          margin-top: auto;
        }

        .${prefix}mb-auto {
          margin-bottom: auto;
        }

        .${prefix}mr-auto {
          margin-right: auto;
        }

        .${prefix}p-${size} {
          padding: ${size}px;
        }

        .${prefix}pt-${size} {
          padding-top: ${size}px;
        }

        .${prefix}pb-${size} {
          padding-bottom: ${size}px;
        }

        .${prefix}pr-${size} {
          padding-right: ${size}px;
        }

        .${prefix}pl-${size} {
          padding-left: ${size}px;
        }

        .${prefix}px-${size} {
          padding-left: ${size}px;
          padding-right: ${size}px;
        }

        .${prefix}py-${size} {
          padding-top: ${size}px;
          padding-bottom: ${size}px;
        }

        .${prefix}ps-${size} {
          padding-inline-start: ${size}px;
        }

        .${prefix}pe-${size} {
          padding-inline-end: ${size}px;
        }

        .${prefix}p-auto {
          padding: auto;
        }

        .${prefix}pt-auto {
          padding-top: auto;
        }

        .${prefix}pb-auto {
          padding-bottom: auto;
        }

        .${prefix}pr-auto {
          padding-right: auto;
        }

        .${prefix}pl-auto {
          padding-left: auto;
        }
      `,
    )
    .join('\n');
}

// Memoize for lazy evaluation
const createSpacingStyles = memoizeOne(
  () => css`
    ${createSpacingUtilities()};

    @media screen and (min-width: 640px) {
      ${createSpacingUtilities('sm')};
    }

    @media screen and (min-width: 768px) {
      ${createSpacingUtilities('md')};
    }

    @media screen and (min-width: 1024px) {
      ${createSpacingUtilities('lg')};
    }

    @media screen and (min-width: 1280px) {
      ${createSpacingUtilities('xl')};
    }
  `,
);

export function unstable_injectGlobalSpacingStyles() {
  injectGlobal(createSpacingStyles());
}

export function unstable_ScopedSpacingStyles({
  className,
  ...props
}: {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) {
  return <div className={cx(className, createSpacingStyles())} {...props} />;
}
