import React, { useEffect, useState, ReactNode } from 'react';
import { cx, css } from '@emotion/css';
import cvar from './theme/cvar';
import defaultTheme from './theme/theme';
import CloseSvg from './icons/CloseSvg';
import WarningSvg from './icons/WarningSvg';
import ErrorSvg from './icons/ErrorSvg';
import InfoSvg from './icons/InfoSvg';
import SuccessSvg from './icons/SuccessSvg';
import { PublicComponentProps } from './types';
import { useFeatureFlags } from './FeatureFlags';

type Status = 'danger' | 'info' | 'success' | 'warning';

export interface AlertStatelessProps extends PublicComponentProps {
  /**
   * Controls whether or not the close button on the right is shown.
   */
  dismissible?: boolean;

  /**
   * Message for the alert.
   */
  message: ReactNode;

  /**
   * A function to be called when the alert is closed.
   */
  onDismiss?: (e: any) => void;

  /**
   * Defines the alert style.
   */
  status?: Status;

  /**
   * Title for the alert. If no title is provided, the alert will not render a title section.
   */
  title?: ReactNode;
}

export interface AlertProps extends PublicComponentProps, AlertStatelessProps {
  /**
   * Controls whether or not the alert is dismissed.
   *
   * @deprecated Use conditional rendering and `onDismiss` instead.
   */
  dismissed?: boolean;
}

const alertColorMap: Record<Status, keyof typeof defaultTheme> = {
  success: 'color-background-success',
  info: 'color-background-info',
  warning: 'color-background-warning',
  danger: 'color-background-error',
};

const alertIconMap: Record<Status, ReactNode> = {
  success: <SuccessSvg />,
  info: <InfoSvg />,
  warning: <WarningSvg />,
  danger: <ErrorSvg />,
};

const alertContentCss = css`
  padding: ${cvar('spacing-16')};
  align-self: center;
  flex: 1;
`;

const alertCloseCss = css`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
`;

const alertCloseWrapperCss = css`
  padding: ${cvar('spacing-16')} ${cvar('spacing-16')} ${cvar('spacing-16')} 0;
  text-align: center;
`;

const alertIconCss = (status: Status) => css`
  padding: ${cvar('spacing-16')} ${cvar('spacing-8')};
  width: 40px;
  background-color: ${cvar(alertColorMap[status])};
  text-align: center;
`;

const alertCss = (status: Status) => css`
  position: relative;
  display: flex;
  border: 1px solid ${cvar(alertColorMap[status])};
  border-radius: 2px;
  margin-bottom: ${cvar('spacing-16')};
  background: #fff;
`;

const alertNoOuterMarginCss = css`
  margin-bottom: 0;
`;

/** @internal */
export const AlertStateless = ({
  status,
  message,
  title,
  dismissible = true,
  onDismiss,
  className,
  ...rest
}: AlertStatelessProps) => {
  const { v17_noOuterSpacing } = useFeatureFlags();

  const dismissibleButton = dismissible ? (
    <div className={alertCloseWrapperCss}>
      <button type="button" className={alertCloseCss} onClick={onDismiss} aria-label="Close alert">
        <CloseSvg />
      </button>
    </div>
  ) : (
    ''
  );

  return (
    <div
      className={cx('crc-alert', alertCss(status || 'danger'), v17_noOuterSpacing && alertNoOuterMarginCss, className)}
      {...rest}
    >
      <div className={alertIconCss(status || 'danger')}>{alertIconMap[status || 'danger']}</div>
      <div className={alertContentCss}>
        {title ? (
          <p>
            <strong>{title}</strong>
          </p>
        ) : null}
        {message}
      </div>
      {dismissibleButton}
    </div>
  );
};

export const Alert = (props: AlertProps) => {
  const { dismissible = true, dismissed = false, onDismiss } = props;
  const [localDismissed, setLocalDismissed] = useState<boolean>(!!dismissed);

  useEffect(() => {
    setLocalDismissed(!!dismissed);
  }, [dismissed]);

  const onCloseClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setLocalDismissed(true);
    onDismiss && onDismiss(e);
  };

  if (dismissible && localDismissed) {
    return null;
  }

  return <AlertStateless {...props} dismissible={dismissible} onDismiss={onCloseClicked} />;
};
