import React, { useState } from 'react';
import SmoothCollapse from 'react-smooth-collapse';
import { Button } from '../Button';
import { CodeBlock } from '../CodeBlock';
import { Copy } from '../Copy';

export interface CodeExampleProps {
  /**
   * The code to be displayed
   */
  code: string;

  /**
   * Whether the codeblock should be expanded on initial render
   */
  initialOpen?: boolean;
}

export function CodeExample({ code, initialOpen = true }: CodeExampleProps) {
  const [open, setOpen] = useState(initialOpen);

  return (
    <div className="row">
      <div className="col-md-12" style={{ paddingTop: '10px' }}>
        <SmoothCollapse expanded={open}>
          <CodeBlock code={code} />
        </SmoothCollapse>
        <div className="clearfix">
          <Button variant="link" onClick={() => setOpen(!open)} className="pull-right">
            {open ? 'Hide code' : 'Show code'}
          </Button>
          {open ? <Copy style={{ marginRight: '9px' }} variant="button" className="pull-right" value={code} /> : null}
        </div>
      </div>
    </div>
  );
}
