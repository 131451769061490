import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import capitalize from 'lodash.capitalize';
import { ACCOUNT_CLAIM, ACCOUNTS_MANAGEMENT_DASHBOARD_URL, fetchAccountDetails } from './services/account';

export default class ProfilePop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      viewportWidth: 0,
      fetchingAccountInfo: false,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.toggle = this.toggle.bind(this);
    this.fetchingAccountDetails = this.fetchingAccountDetails.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
    const { profile: { [ACCOUNT_CLAIM]: account } = {}, accessToken } = this.props;
    this.fetchingAccountDetails({ account, accessToken });
  }

  componentDidUpdate(prevProps) {
    const { profile: { [ACCOUNT_CLAIM]: prevAccount } = {} } = prevProps;
    const { profile: { [ACCOUNT_CLAIM]: account } = {}, accessToken } = this.props;
    if (prevAccount !== account) this.fetchingAccountDetails({ account, accessToken });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions() {
    this.setState({ viewportWidth: window.outerWidth });
  }

  handleClose() {
    this.state.open && this.setState({ open: false });
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }

  fetchingAccountDetails({ account, accessToken }) {
    if (account && accessToken) {
      this.setState({ fetchingAccountInfo: true });
      fetchAccountDetails({ account, accessToken }).then(({ name: accountName }) => {
        this.setState({ accountName, fetchingAccountInfo: false });
      });
    }
  }

  render() {
    let { isLoggedIn, profile, onLogInClicked, onLogOutClicked, authToken, environment } = this.props;
    let { [ACCOUNT_CLAIM]: account, email, name, picture, domain } = profile || {};
    const { accountName, fetchingAccountInfo } = this.state;
    name = name || 'Unknown';

    if (!domain) {
      const emailDomain = email ? capitalize(email.replace(/.*@/, '').split('.')[0]) : '';
      domain = account && fetchingAccountInfo ? '' : accountName || emailDomain;
    }

    if (!picture) {
      picture = `https://i2.wp.com/cdn.auth0.com/avatars/${name[0]}.png?ssl=1`;
    }

    if (!isLoggedIn) {
      return (
        <button onClick={onLogInClicked} className="btn btn-outline-secondary btn-sm">
          Log In
        </button>
      );
    }

    let profileContents = (
      <div className="text-center">
        <div className="popover-content">
          <img className="avatar" src={picture} />
          <div style={{ marginBottom: '5px' }} className="username">
            {name}
          </div>
          <div style={{ marginBottom: '2px' }} className="email">
            {email}
          </div>
          {account &&
            accountName && (
              <div style={{ marginBottom: '2px' }}>
                <a target="_blank" rel="noopener noreferrer" href={`${ACCOUNTS_MANAGEMENT_DASHBOARD_URL}/${account}`}>
                  {accountName}
                </a>
              </div>
            )}
        </div>
        <div className="popover-content">
          <button onClick={onLogOutClicked} className="btn btn-secondary btn-block">
            Log Out
          </button>
        </div>
      </div>
    );
    return (
      <div className="mcp-navbar-userbox">
        <Tooltip
          variant="popover"
          className="user-popover"
          show={this.state.open}
          onClickOutside={this.handleClose}
          direction="bottom"
          constraints={[
            {
              to: 'scrollParent',
              attachment: 'together',
              pin: ['right', 'top'],
            },
          ]}
          contents={profileContents}>
          <Fragment>
            <a className="clickable" onClick={this.toggle}>
              {this.state.viewportWidth > 500 ? (
                <div>
                  <div className="username">{name}</div>
                </div>
              ) : null}
              <img className="avatar" src={picture} />
            </a>
          </Fragment>
        </Tooltip>
      </div>
    );
  }
}

ProfilePop.propTypes = {
  profile: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    picture: PropTypes.string,
    domain: PropTypes.string,
    sub: PropTypes.string,
    [ACCOUNT_CLAIM]: PropTypes.string,
  }),
  onLogOutClicked: PropTypes.func.isRequired,
  onLogInClicked: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
};
