import { useQueries, UseQueryResult } from 'react-query';
import { get, set } from 'lodash';
import { getMcpProductByMcpSku, McpProduct } from '../clients/productIntroduction';
import { getFulfillmentConfigName, isPMV2Product } from '../helpers/handleOptions';
import {
  Environment,
  SuccessfulDecision,
  FailedDecision,
  InvalidConsideredOption,
  isSuccessfulDecision,
  Option,
  isValidOption,
} from '../types';
import { determineProductAttributes } from '../helpers/productAttributes';
import { getProductConfiguration } from '../clients/productConfiguration';
import { getFulfillmentConfigurationLinkForOption } from '../clients/fulfillmentConfiguration';

/**
 * Calls services to get additional information about routing options
 * and then updates the options with values for display in the UI
 */
export const useDecorateOptions = ({
  environment,
  accessToken,
  decision,
  options,
}: {
  environment: Environment;
  accessToken: string;
  decision: SuccessfulDecision | FailedDecision | undefined;
  options: Option[] | InvalidConsideredOption[] | undefined;
}) => {
  return useQueries(
    options
      ? options.map((option: Option | InvalidConsideredOption) => {
          return {
            queryKey: ['decorationOptions', option.optionId],
            queryFn: async () => {
              let productIntroData: McpProduct | undefined = undefined;

              try {
                productIntroData = await getMcpProductByMcpSku({
                  accessToken,
                  mcpSku: option.referenceId,
                });
              } catch (error) {
                // Swallow the error because we still want to display
                // the information we do have if this request fails
              }

              const [fulfillerNamePromise, productConfigurationPromise, fulfillmentConfigurationLinkPromise] =
                await Promise.allSettled([
                  getFulfillmentConfigName({
                    environment,
                    accessToken,
                    option: isValidOption(option) // will have a fulfillerID, but invalid options may or may not
                      ? option
                      : { ...option, fulfillerId: option.fulfillerId || productIntroData?.fulfillerId },
                  }),
                  getProductConfiguration({ accessToken, option }),
                  getFulfillmentConfigurationLinkForOption({
                    versionUrl: option._links?.fulfillmentConfiguration?.href,
                    accessToken,
                  }),
                ]);

              // Swallow the error if we can't get the product attributes since
              // we should still display the rest of the UI
              if (productIntroData) {
                const productAttributes = determineProductAttributes(productIntroData);
                if (productAttributes) {
                  option.productAttribute = productAttributes;
                }
              }

              if (fulfillerNamePromise?.status === 'fulfilled') {
                const { value: fulfillerName } = fulfillerNamePromise;
                if (fulfillerName) {
                  option.fulfillerName = fulfillerName;
                } else {
                  option.fulfillerName = `${option.fulfillerId} (Fulfiller ID)`;
                }
              } else {
                // If the request errors for some reason, fallback to the fulfiller ID
                option.fulfillerName = `${option.fulfillerId} (Fulfiller ID)`;
              }

              if (productConfigurationPromise.status === 'fulfilled') {
                const { value: productConfigurationResponse } = productConfigurationPromise;
                const { productConfiguration } = productConfigurationResponse;

                if (productConfiguration) {
                  const { mcpSku } = productConfiguration;

                  if (isPMV2Product(productConfiguration)) {
                    set(option, ['_uiLinks', 'productManager'], {
                      href: `https://productmanager-v2.products.cimpress.io/product-details-v2/${mcpSku}/versions/${productConfiguration.productVersion}`,
                      version: `V${productConfiguration.productVersion}`,
                    });
                  } else {
                    // is a PM v1 product
                    set(option, ['_uiLinks', 'productManager'], {
                      href: `https://productmanager.products.cimpress.io/rules/product/edit?mcpSku=${mcpSku}`,
                      version: 'V1',
                    });
                  }
                }
              }

              if (fulfillmentConfigurationLinkPromise.status === 'fulfilled') {
                const { value: fulfillmentConfigurationLinkResponse } = fulfillmentConfigurationLinkPromise;
                const { fulfillmentConfigurationUrl, fulfillmentConfigurationVersion } =
                  fulfillmentConfigurationLinkResponse;

                if (fulfillmentConfigurationUrl) {
                  set(option, ['_uiLinks', 'fulfillmentConfigurationLink'], {
                    href: fulfillmentConfigurationUrl,
                    version: fulfillmentConfigurationVersion,
                  });
                }
              }

              const costsForOption = get(option, ['_embedded', 'deliveryFeasibility', 'price', 'combined', 'total']) as
                | string
                | undefined;

              if (costsForOption) {
                option.totalCostUSD = Number(costsForOption);
              }

              if (decision && isSuccessfulDecision(decision)) {
                if (isValidOption(option) && decision.selectedOption.optionId === option.optionId) {
                  option.isSelected = true;
                }
              }

              return option;
            },
          };
        })
      : [],
  ) as UseQueryResult<Option | InvalidConsideredOption, Error>[];
};
