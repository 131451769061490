import { get } from 'lodash';
import { getFulfillerIdentityByFulfillerId } from '../clients/fulfillerIdentity';
import {
  Environment,
  Option,
  InvalidConsideredOption,
  FulfillmentConfigurationsResponse,
  PMV1ProductConfigResponse,
  PMV2ProductConfigResponse,
} from '../types';

type GetFulfillmentConfigName = {
  environment: Environment;
  accessToken: string;
  option: Option | InvalidConsideredOption;
};

export const getFulfillmentConfigName = async ({
  environment,
  accessToken,
  option,
}: GetFulfillmentConfigName): Promise<string | undefined> => {
  // The baseFulfillmentConfiguration comes from the considered options on the routing decision
  const baseFulfillmentConfigurationUrl = get(option, ['_links', 'baseFulfillmentConfiguration', 'href']) as
    | string
    | undefined;

  if (baseFulfillmentConfigurationUrl) {
    const fulfillmentConfigVersionResponse = await fetch(baseFulfillmentConfigurationUrl);

    if (fulfillmentConfigVersionResponse.ok) {
      const fulfillmentConfigVersionBody =
        (await fulfillmentConfigVersionResponse.json()) as FulfillmentConfigurationsResponse;
      return get(fulfillmentConfigVersionBody, ['name'], option.fulfillerId);
    } else if (!option.fulfillerId) {
      return undefined;
    } else {
      // Try to get the name from the fulfiller identity service instead
      // This path may be hit for users who don't have permission to read the fulfillment config
      const fulfillmentConfigVersionBody = await getFulfillerIdentityByFulfillerId({
        environment,
        accessToken,
        fulfillerId: option.fulfillerId,
      });
      return get(fulfillmentConfigVersionBody, ['fulfillerIdentity', 'name']) as string | undefined;
    }
  }

  if (!option.fulfillerId) {
    return undefined;
  }

  // Invalid considered options don't have a base fulfillment configuration link, so we try to get the name
  // from the fulfiller identity service instead.
  const fulfillmentConfigVersionBody = await getFulfillerIdentityByFulfillerId({
    environment,
    accessToken,
    fulfillerId: option.fulfillerId,
  });

  return get(fulfillmentConfigVersionBody, ['fulfillerIdentity', 'name']) as string | undefined;
};

export const isPMV2Product = (
  productConfigJson: PMV1ProductConfigResponse | PMV2ProductConfigResponse,
): productConfigJson is PMV2ProductConfigResponse => {
  /**
   * Only PM v2 products have a `productVersion` in the product configuration JSON, so we
   * use that to determine the JSON type
   */
  return (productConfigJson as PMV2ProductConfigResponse).productVersion !== undefined;
};
