import React, { ReactNode } from 'react';
import { css, cx } from '@emotion/css';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';

export interface CardProps extends PublicComponentProps {
  /**
   * The body of the card. Can be anything.
   */
  children: ReactNode;

  /**
   * The header of the card. Typically a title or an svg such as a CardHeader from Shapes. If not provided, no header will render.
   */
  header?: ReactNode;

  /**
   * Boolean that simplifies the look of the header. Defaults to false.
   */
  isMinimal?: boolean;

  /**
   * The variant of card you are creating.
   */
  variant?: 'default' | 'layout' | 'minimal';
}

const cardBase = css`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${cvar('color-background')};
  border: 1px solid ${cvar('color-border-default')};
  border-radius: ${cvar('spacing-4')};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
`;

const cardHeader = css`
  padding: ${cvar('spacing-16')} ${cvar('spacing-16')};
  margin-bottom: 0;
  background-color: ${cvar('color-background-light')};
  border-bottom: none;
  background-image: none;
  color: ${cvar('color-text-default')};

  &:first-child {
    border-radius: 3px 3px 0 0;
  }

  > h5 {
    font-size: 15px;
    font-weight: 500;
  }
`;

const cardMinimal = css`
  .crc-card-header {
    background-color: ${cvar('color-background')};
    border-bottom: 1px solid ${cvar('color-border-light')};
  }
`;

const cardLayout = css`
  background-color: ${cvar('color-background')};
  border: none;
  border-radius: 2px;
  box-shadow: none;
  .crc-card-header {
    background-color: ${cvar('color-background')};
  }
`;

const cardBlock = css`
  flex: 1 1 auto;
  padding: ${cvar('spacing-16')};

  p {
    margin-bottom: 20px;
  }
`;

const cardTitle = css`
  margin: 0;
`;

export const Card = ({
  children,
  header = undefined,
  isMinimal = false,
  className = '',
  variant,
  ...rest
}: CardProps) => {
  // isMinimal should take priority here for backwards compatibility

  const cardClassName = cx('crc-card', cardBase, className, {
    [cardMinimal]: isMinimal || variant === 'minimal',
    [cardLayout]: !isMinimal && variant === 'layout',
  });

  return (
    <div className={cardClassName} {...rest}>
      {header && (
        <div className={cx(cardHeader, 'crc-card-header')}>
          <h5 className={cardTitle}>{header}</h5>
        </div>
      )}
      <div className={cardBlock}>{children}</div>
    </div>
  );
};
