import React, { MouseEventHandler, ReactNode } from 'react';
import { css, cx } from '@emotion/css';

import * as colors from '../colors';
import cvar from '../theme/cvar';

const ascDirection = 'asc';
const descDirection = 'desc';

const Triangle: React.FC<{ up?: boolean; active: boolean; style?: {} }> = ({ up, active, style = {} }) => (
  <div
    style={{
      width: '0px',
      height: '0px',
      borderLeft: '4px solid transparent',
      borderRight: '4px solid transparent',
      borderBottom: up ? `4px solid ${active ? colors.alloy : colors.shale}` : 'none',
      borderTop: up ? 'none' : `4px solid ${active ? colors.alloy : colors.shale}`,
      ...style,
    }}
  />
);

type TableHeaderProps = {
  children?: ReactNode;
  className?: string;
  sortable?: boolean;
  sorted?: { desc?: any };
  toggleSort: MouseEventHandler<HTMLElement>;
};

const TableHeader: React.FC<TableHeaderProps> = ({ children, className, sortable, sorted, toggleSort, ...rest }) => {
  const direction = sorted?.desc ? 'desc' : 'asc';

  const rtTh = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    cursor: ${sortable ? 'pointer' : 'default'};
    &:not(:last-child) {
      border-right: 1px solid ${cvar('color-background')};
    }
  `;

  return (
    <div
      className={cx(rtTh, className)}
      role="columnheader"
      tabIndex={-1}
      {...rest}
      onClick={e => toggleSort && toggleSort(e)}
      onKeyDown={e => e.stopPropagation()}
    >
      {children}
      {sortable && (
        <div>
          <Triangle active={direction === ascDirection} up />
          <Triangle active={direction === descDirection} style={{ marginTop: '2px' }} />
        </div>
      )}
    </div>
  );
};

export default TableHeader;
