import React, { ReactNode } from 'react';
import { cx, css } from '@emotion/css';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';
import { useFeatureFlags } from './FeatureFlags';

export interface NavTabProps extends PublicComponentProps {
  /**
   * Provide content for the NavTab as children.
   */
  children?: ReactNode;

  /**
   * If tabs should be stacked vertically
   */
  vertical?: boolean;
}

const navStyle = css`
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  &::before,
  &::after {
    display: table;
    content: ' ';
  }
  &::after {
    clear: both;
  }
  > li {
    position: relative;
    display: block;
  }
`;

const navTabStyle = css`
  border-bottom: none;
  margin-bottom: 30px;
  > li {
    float: left;
    margin-bottom: -1px;
  }
  > li.active > a,
  > li.active > button {
    color: ${cvar('color-button-primary')};
    background-color: transparent;
  }
  > li.active > a,
  > li.active > a:focus,
  > li.active > a:hover,
  > li.active > button,
  > li.active > button:focus,
  > li.active > button:hover {
    border-width: 0;
  }
  > li > a {
    text-decoration: none;
  }
  > li > button {
    outline: 0;
    position: relative;
    line-height: 18px;
    color: inherit;
  }
  > li > a,
  > li > button {
    display: inline-block;
    border: none;
    color: ${cvar('color-text-default')};
    font-weight: 400;
    background: 0 0;
    background-color: transparent;
  }
  > li > a,
  > li > button {
    padding: 7px 5px;
    margin: 0 10px;
  }
  > li.disabled > button {
    color: ${cvar('color-text-disabled')};
    cursor: not-allowed;
  }
  > li.active > a::after,
  > li.active > button::after,
  > li:hover > a::after,
  > li:hover > button::after {
    transform: scale(1);
  }
  > li > a::after,
  > li > button::after {
    content: '';
    background: ${cvar('color-button-primary')};
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -1px;
    transition: all 250ms ease 0s;
    transform: scale(0);
  }
  > li.disabled > a::after,
  > li.disabled > button::after {
    display: none;
  }
`;

const verticalStackedStyle = css`
  > li {
    float: none;
  }
  .nav-pills > li > a,
  .nav-pills > li > button,
  > li > a,
  > li > button {
    padding: ${cvar('spacing-4')} ${cvar('spacing-16')};
    margin: ${cvar('spacing-8')} 0;
  }
  .nav-pills > li > a::after,
  .nav-pills > li > button::after,
  > li > a::after,
  > li > button::after {
    height: 100%;
    width: 2px;
    bottom: 0;
  }
`;

const noOuterMarginCss = css`
  margin-bottom: 0;
`;

export const NavTab = ({ children, className, vertical, ...rest }: NavTabProps) => {
  const { v17_noOuterSpacing } = useFeatureFlags();
  const fullClassName = cx('crc-navtab', navStyle, navTabStyle, v17_noOuterSpacing && noOuterMarginCss, className, {
    [verticalStackedStyle]: vertical,
  });

  return (
    <ul className={fullClassName} {...rest}>
      {children}
    </ul>
  );
};
