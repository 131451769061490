/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { formatCauseWithAction } from '../../helpers/causes';
import { Cause } from '../../types';

/**
 * TODO: Try to reduce the number of alert messages we show when working on
 * https://cimpress-support.atlassian.net/browse/DIS-3450
 */
export const FormattedAlertMessages = ({ messages }: { messages: Cause[] }) => {
  if (messages.length === 1) {
    const message = messages[0];
    return <p>{formatCauseWithAction({ cause: message.detail, action: message.action })}</p>;
  }

  const listStyles = css`
    margin-left: 3.2rem;
    list-style: disc;
  `;

  return (
    <ul css={listStyles}>
      {messages.map((message, index) => {
        return (
          <li key={`${message.title}-${index}`}>
            {formatCauseWithAction({ cause: message.detail, action: message.action })}
          </li>
        );
      })}
    </ul>
  );
};
