import React, { ReactElement, useMemo } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import js from 'react-syntax-highlighter/dist/cjs/languages/hljs/javascript';
import { css, cx } from '@emotion/css';
import cvar from './theme/cvar';
import { PublicComponentProps } from './types';
import { useFeatureFlags } from './FeatureFlags';

SyntaxHighlighter.registerLanguage('javascript', js);

export type CodeBlockTheme = 'light' | 'dark';

export interface CodeBlockProps extends PublicComponentProps {
  /**
   * The code string to highlight and format
   */
  code: string;

  /**
   * The theme either light or dark, defaults to light
   */
  theme?: CodeBlockTheme;
}

const codeBlockLight = css`
  .hljs {
    display: block;
    overflow-x: auto;
    background: ${cvar('light-color-code-background')};
    color: ${cvar('light-color-code-base')};
    padding: 0.5em;
  }

  .hljs-comment,
  .hljs-quote {
    color: ${cvar('color-code-comment')};
  }

  .hljs-variable,
  .hljs-template-variable,
  .hljs-attribute,
  .hljs-tag,
  .hljs-name,
  .hljs-regexp,
  .hljs-link,
  .hljs-name,
  .hljs-selector-id,
  .hljs-selector-class {
    color: ${cvar('color-code-variable')};
  }

  .hljs-number,
  .hljs-meta,
  .hljs-built_in,
  .hljs-builtin-name,
  .hljs-literal,
  .hljs-type,
  .hljs-params {
    color: ${cvar('color-code-literal')};
  }

  .hljs-string,
  .hljs-symbol,
  .hljs-bullet {
    color: ${cvar('light-color-code-string')};
  }

  .hljs-title,
  .hljs-section {
    color: ${cvar('color-code-title')};
  }

  .hljs-keyword,
  .hljs-selector-tag {
    color: ${cvar('color-code-keyword')};
  }

  .hljs-emphasis {
    font-style: italic;
  }

  .hljs-strong {
    font-weight: bold;
  }
`;

const codeBlockDark = css`
  ${codeBlockLight}
  .hljs {
    background: ${cvar('dark-color-code-background')};
    color: ${cvar('dark-color-code-base')};
  }
  .hljs-string,
  .hljs-symbol,
  .hljs-bullet {
    color: ${cvar('dark-color-code-string')};
  }
`;

const highlightBlockLight = css`
  background: ${cvar('light-color-code-background')};
  border-color: ${cvar('light-color-code-border')};
`;

const highlightBlockDark = css`
  background: ${cvar('dark-color-code-background')};
  border-color: ${cvar('dark-color-code-border')};
`;

const noOuterMarginCss = css`
  margin-bottom: 0;
`;

const PreTag =
  (theme: CodeBlockTheme, noOuterSpacing: boolean) =>
  ({ children }: { children: ReactElement[] }) =>
    (
      <pre
        className={cx(noOuterSpacing && noOuterMarginCss, {
          [`${highlightBlockLight}`]: theme === 'light',
          [`${highlightBlockDark}`]: theme === 'dark',
          [`${codeBlockLight}`]: theme === 'light',
          [`${codeBlockDark}`]: theme === 'dark',
        })}
      >
        {children}
      </pre>
    );

const CodeTag = ({ children }: { children: ReactElement[] }) => <code className="hljs javascript">{children}</code>;

export const CodeBlock = ({ code, theme = 'light', ...props }: CodeBlockProps) => {
  // trim trailing new lines
  const trimmedCode = useMemo(() => code.replace(/\n+$/, ''), [code]);
  const { v17_noOuterSpacing } = useFeatureFlags();

  return (
    <SyntaxHighlighter
      className={cx('crc-code-block', props.className)}
      language="javascript"
      useInlineStyles={false}
      PreTag={PreTag(theme, v17_noOuterSpacing)}
      CodeTag={CodeTag}
      {...props}
    >
      {trimmedCode}
    </SyntaxHighlighter>
  );
};
